import { IErrorResponse, ISuccessResponse } from "helpers/customFetch";

/**
 * Get success response body. This function is useful when using SWR, and we
 * need the fetcher function to throw when the response is *not* successful.
 * @throws If response is not successful.
 */
export const getSuccessResponseBody = <T>(
  response: ISuccessResponse<T> | IErrorResponse<unknown>
): ISuccessResponse<T>["body"] => {
  if (!response.ok) {
    throw response;
  }

  return response.body;
};
