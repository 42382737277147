import classNames from "classnames";
import Image, { ImageProps } from "next/image";
import React, {
  FC,
  ReactElement,
  ReactEventHandler,
  useCallback,
  useEffect,
  useState,
} from "react";
import { BrokenImageIcon } from "components/Icons/BrokenImageIcon";
import { PodcastIcon } from "components/Icons/PodcastIcon";
import { IMGIXLoader } from "helpers/IMGIXLoader";
import styles from "./podcastImage.module.scss";

export interface IProps extends Omit<ImageProps, "src" | "alt"> {
  src: ImageProps["src"] | undefined;
  alt: string;
  fallbackTitle?: string | undefined;
}

export const PodcastImage: FC<IProps> = ({
  alt,
  src,
  onError,
  fallbackTitle,
  ...rest
}): ReactElement => {
  const [error, setError] = useState(false);

  // Reset error if src changes.
  useEffect(() => {
    setError(false);
  }, [src]);

  // Memoize callback. Next image will otherwise reload the image if we send in
  // new instance on rerender.
  const onErrorLocal: ReactEventHandler<HTMLImageElement> = useCallback(
    (event) => {
      setError(true);
      if (onError) {
        onError(event);
      }
    },
    [onError]
  );

  if (!src || error) {
    const fallbackTitleSizes =
      fallbackTitle && typeof rest.width === "number"
        ? {
            s: rest.width <= 50,
            m: rest.width > 50 && rest.width <= 100,
            l: rest.width > 100,
          }
        : undefined;
    return (
      <div
        data-testid={rest["data-testid"]}
        className={classNames(styles.fallback, rest.className)}
        style={rest.style}
      >
        {/* eslint-disable-next-line @next/next/no-img-element */}
        <img
          alt=""
          title={rest.title}
          src="/assets/fallback_background_image.svg"
          loading={rest.loading || "lazy"}
          decoding={rest.decoding || "async"}
          width={rest.width}
          height={rest.height}
          className={styles.fallbackBackgroundImage}
        />
        <div className={styles.fallbackContent}>
          {!src ? (
            <PodcastIcon size={24} className={styles.icon} fill="#fff" />
          ) : (
            <BrokenImageIcon size={24} className={styles.icon} fill="#fff" />
          )}
          {fallbackTitle && (
            <div
              className={classNames(
                styles.fallbackTitle,
                fallbackTitleSizes
                  ? {
                      [styles.fallbackTitleSmall]: fallbackTitleSizes.s,
                      [styles.fallbackTitleMedium]: fallbackTitleSizes.m,
                      [styles.fallbackTitleLarge]: fallbackTitleSizes.l,
                    }
                  : styles.fallbackTitleLarge
              )}
            >
              {fallbackTitle}
            </div>
          )}
        </div>
      </div>
    );
  }

  return (
    <Image
      loader={IMGIXLoader}
      {...rest}
      alt={alt}
      src={src}
      onError={onErrorLocal}
    />
  );
};
