import React, { createContext, useContext, useMemo, useState } from "react";
import { TSendbird } from "api/studio";

interface IChat {
  active: TSendbird | null;
  openChat: (provider: TSendbird, image: string) => void;
  closeChat: () => void;
  image: string | null;
}

const ChatContext = createContext<IChat | undefined>(undefined);

export function ChatProvider({ children }) {
  const [active, setActive] = useState<TSendbird | null>(null);
  const [image, setImage] = useState<string | null>(null);

  const state = useMemo(
    () => ({
      active,
      openChat: (chatProvider, image) => {
        setImage(image);
        setActive(chatProvider);
      },
      image,
      closeChat: () => setActive(null),
    }),
    [active, image]
  );

  return <ChatContext.Provider value={state}>{children}</ChatContext.Provider>;
}

export function useChat(): IChat {
  const context = useContext<IChat | undefined>(ChatContext);
  if (!context) {
    throw new Error("Can't find Chat context provider.");
  }
  return context;
}
