import { MutableRefObject, useEffect, useRef } from "react";
import { TPlayerEpisode } from "components/Player/context/PlayerContextProvider";
import { EpisodeUndefinedError } from "components/Player/helpers/EpisodeUndefinedError";
import { IProps } from "components/Player/hooks/useAudio";

/**
 * Connects callback directly to the audio element events. In this way the
 * callback gets called even though the are triggered from the outside, i.e.
 * the home screen etc.
 */
export const useAudioCallbacks = (
  audio: HTMLAudioElement | undefined,
  episodeRef: MutableRefObject<TPlayerEpisode | undefined>,
  onPause: IProps["onPause"],
  onPlay: IProps["onPlay"],
  onEnded: IProps["onEnded"]
): void => {
  // Store callback in refs to avoid adding/removing events to audio element
  // when callbacks change.
  const onPauseRef = useRef(onPause);
  const onPlayRef = useRef(onPlay);
  const onEndedRef = useRef(onEnded);

  useEffect(() => {
    onPauseRef.current = onPause;
    onPlayRef.current = onPlay;
    onEndedRef.current = onEnded;
  });

  useEffect(() => {
    if (!audio) {
      return;
    }

    const pauseHandler = () => {
      const episode = episodeRef.current;
      if (!episode) {
        throw new EpisodeUndefinedError();
      }
      onPauseRef.current?.(episode, audio);
    };

    const playHandler = () => {
      const episode = episodeRef.current;
      if (!episode) {
        throw new EpisodeUndefinedError();
      }
      onPlayRef.current?.(episode, audio);
    };

    const endedHandler = () => {
      const episode = episodeRef.current;
      if (!episode) {
        throw new EpisodeUndefinedError();
      }
      onEndedRef.current?.(episode, audio);
    };

    audio.addEventListener("play", playHandler);
    audio.addEventListener("pause", pauseHandler);
    audio.addEventListener("ended", endedHandler);

    return () => {
      audio.removeEventListener("play", playHandler);
      audio.removeEventListener("pause", pauseHandler);
      audio.removeEventListener("ended", endedHandler);
    };
  }, [audio, episodeRef]);
};
