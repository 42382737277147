import jwtDecode from "jwt-decode";
import { IAccessToken } from "api/podplay/user";

export function getIsAccessTokenExpired(
  accessToken: string,
  timeNow: number,
  timeBuffer: number
): boolean {
  try {
    const decodedToken: IAccessToken = jwtDecode(accessToken) as IAccessToken;

    return decodedToken.exp
      ? decodedToken.exp - timeNow - timeBuffer <= 0
      : false;
  } catch (error) {
    return true;
  }
}
