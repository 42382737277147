import React from "react";
import { IIconProps, getSVGProps } from "./helpers/getSVGProps";

export const PlaylistAddFirstIcon = (props: IIconProps) => (
  <svg {...getSVGProps(props)} style={{ transform: "rotate(180deg)" }}>
    <g>
      <rect fill="none" height="24" width="24" />
    </g>
    <g>
      <path d="M14,10H3v2h11V10z M14,6H3v2h11V6z M18,14v-4h-2v4h-4v2h4v4h2v-4h4v-2H18z M3,16h7v-2H3V16z" />
    </g>
  </svg>
);
