import jwtDecode from "jwt-decode";
import { TUserInfo, IAccessToken } from "api/podplay/user";

export const getUserInfoFromAccessToken = (accessToken: string): TUserInfo => {
  const decodedAccessToken: IAccessToken = jwtDecode(
    accessToken
  ) as IAccessToken;
  return {
    birthdate: decodedAccessToken.claims.birthdate,
    email: decodedAccessToken.claims.email,
    gender: decodedAccessToken.claims.gender,
    language_iso: decodedAccessToken.claims.language,
    profile_name: decodedAccessToken.claims.profile_name,
    user_id: decodedAccessToken.sub,
  };
};
