import { MutableRefObject, useEffect } from "react";
import {
  TPlayerEpisode,
  isPlayerLiveEpisode,
} from "components/Player/context/PlayerContextProvider";
import { EpisodeUndefinedError } from "components/Player/helpers/EpisodeUndefinedError";

/**
 * Stop live episode completely when audio pauses. This aborts the downloading,
 * clears the MediaSession that does not work well with live streams in Chrome
 * and forces the audio to be restarted at live position instead of paused
 * position when user restarts.
 */
export const useStopLive = (
  audio: HTMLAudioElement | undefined,
  episodeRef: MutableRefObject<TPlayerEpisode | undefined>
): void => {
  useEffect(() => {
    if (!audio) {
      return;
    }

    const pauseHandler = () => {
      const episode = episodeRef.current;

      if (!episode) {
        throw new EpisodeUndefinedError();
      }

      // Stop audio complete if live.
      if (isPlayerLiveEpisode(episode)) {
        audio.removeAttribute("src");
        audio.load();
      }
    };

    audio.addEventListener("pause", pauseHandler);

    return () => {
      audio.removeEventListener("pause", pauseHandler);
    };
  }, [audio, episodeRef]);
};
