import {
  IPlayerContext,
  isPlayerLiveEpisode,
} from "components/Player/context/PlayerContextProvider";
import {
  getPersistedEpisode,
  getPersistedQueue,
} from "components/Player/helpers/persist";
import { useEffectOnce } from "hooks/useEffectOnce";

/**
 * When the user revisits the site, it mounts/loads the last played episode
 * into the player.
 */
export const useResumeLastPlayed = (
  blockMount: boolean,
  load: IPlayerContext["load"],
  setQueue: IPlayerContext["setQueue"]
): void => {
  useEffectOnce(() => {
    // Block is set to true when player has an episode.
    if (blockMount) {
      return;
    }

    (async () => {
      // Persisted data.
      const persistedEpisode = getPersistedEpisode();
      const persistedQueue = getPersistedQueue();

      // Nothing persisted.
      if (persistedEpisode === undefined) {
        return;
      }

      const { episode, currentTime } = persistedEpisode;

      if (isPlayerLiveEpisode(episode)) {
        // LiveEpisode episode.
        setQueue([]);
        await load({
          ...episode,
          autoPlay: false,
          // NOTICE: JSON can't handle "Infinity". LiveEpisode episodes should have
          // "Infinity" as approximatedDuration.
          approximatedDuration: Infinity,
        });
      } else {
        // Normal episode.
        setQueue(persistedQueue || []);
        await load({
          ...episode,
          autoPlay: false,
          startTime: currentTime || 0,
        });
      }
    })();
  });
};
