import { useEffect, useState } from "react";
import { usePlayer } from "components/Player/hooks/usePlayer";

interface IAudioProgress {
  currentTime: number;
  duration: number;
  percent: number;
}
const getValues = (
  audio: HTMLAudioElement | undefined,
  currentTimeFallback: number,
  durationFallback: number
): IAudioProgress => {
  // Use fallback values if audio not ready.
  if (
    !audio ||
    !audio.duration ||
    isNaN(audio.duration) ||
    // Check "readyState" - Property "currentTime" can be 0 a short time after
    // start even though we want to start further in. The correct "currentTime"
    // is available when audio "have current data".
    audio.readyState < audio.HAVE_CURRENT_DATA
  ) {
    // LiveEpisode audio.
    if (durationFallback === Infinity) {
      return {
        currentTime: currentTimeFallback,
        duration: currentTimeFallback,
        percent: 100,
      };
    }
    // Normal audio.
    return {
      currentTime: currentTimeFallback,
      duration: durationFallback,
      percent: durationFallback
        ? (currentTimeFallback / durationFallback) * 100
        : 0,
    };
  }

  // LiveEpisode audio.
  if (audio.duration === Infinity) {
    return {
      currentTime: audio.currentTime,
      duration: audio.currentTime,
      percent: 100,
    };
  }

  // Normal audio.
  return {
    currentTime: audio.currentTime,
    duration: audio.duration,
    percent: (audio.currentTime / audio.duration) * 100,
  };
};

/**
 * Hook to get "currentTime" and "duration" from the active audio element.
 * Values update in real time. Uses fallback values if audio not ready.
 */
export const useAudioProgress = (): IAudioProgress => {
  const { _audio, desiredStartTime, episode } = usePlayer();
  const durationFallback = episode?.approximatedDuration || 0;
  const [values, setValues] = useState(() =>
    getValues(_audio, desiredStartTime, durationFallback)
  );

  useEffect(() => {
    if (!_audio) {
      return;
    }

    const handler = () => {
      setValues(getValues(_audio, desiredStartTime, durationFallback));
    };

    _audio.addEventListener("timeupdate", handler);
    _audio.addEventListener("durationchange", handler);

    handler();

    return () => {
      _audio.removeEventListener("timeupdate", handler);
      _audio.removeEventListener("durationchange", handler);
    };
  }, [_audio, desiredStartTime, durationFallback]);

  return values;
};
