import { useEffect, useReducer } from "react";

/**
 * This hook will update when the audio element updates.
 */
export const useForceUpdate = (audio: HTMLAudioElement | undefined): number => {
  // TL:DR; This hook will update when the audio element updates.
  //
  // Normally, you shouldn’t mutate local state in React. However, as an escape hatch,
  // you can use an incrementing counter to force a rerender even if the state has not changed.
  // https://reactjs.org/docs/hooks-faq.html#is-there-something-like-forceupdate
  //
  // Try to avoid this pattern if possible.
  //
  // We are not avoiding it in this hook since we are exposing properties on the audio
  // element directly. We could avoid it by using react state as an intermediate storage
  // for properties like currentTime, duration, readyState and error.
  //
  // E.g.
  // const [currentTime, setCurrentTime] = useState();
  // audio.addEventListener("timeupdate", () => setCurrentTime(audio.currentTime));
  //
  // ..but that would lead to a net loss. More code, more variables, same amount of
  // updates.

  const [updateCount, forceUpdate] = useReducer((x: number) => {
    return x + 1;
  }, 0);

  // ---------------------------------------------------------------------------
  // Event handling.
  // ---------------------------------------------------------------------------
  useEffect(() => {
    if (!audio) return;

    // This effect should update when ever the audio element updates.
    const events: (keyof HTMLMediaElementEventMap)[] = [
      "canplay",
      "canplaythrough",
      "emptied",
      "ended",
      "loadeddata",
      "loadedmetadata",
      "pause",
      "play",
      "playing",
      "ratechange",
      "seeked",
      "seeking",
      "stalled",
      "suspend",
      "timeupdate",
      "waiting",
      "durationchange",
    ];

    events.forEach((event) => audio.addEventListener(event, forceUpdate));

    return () => {
      events.forEach((event) => audio.removeEventListener(event, forceUpdate));
    };
  }, [audio]);

  return updateCount;
};
