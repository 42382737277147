import { useUser } from "contexts/UserContextProvider/UserContextProvider";
import React, {
  createContext,
  Dispatch,
  PropsWithChildren,
  ReactElement,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { z } from "zod";
import { ZEpisode } from "api/podplay/episode";
import { ZPodcast } from "api/podplay/podcast";
import { ZSendbird } from "api/studio";
import { getIsUserLoggingOut } from "components/Player/helpers/getIsUserLoggingOut";
import {
  getPersistedAutoplay,
  setPersistedAutoplay,
} from "components/Player/helpers/persist";
import { useAddBodyClass } from "components/Player/hooks/useAddBodyClass";
import { IUseAudioData, useAudio } from "components/Player/hooks/useAudio";
import { useAutoCloseExpandOnMobile } from "components/Player/hooks/useAutoCloseExpandOnMobile";
import { useBeforeLogout } from "components/Player/hooks/useBeforeLogout";
import { useColor } from "components/Player/hooks/useColor";
import { useDebounceIsBuffering } from "components/Player/hooks/useDebounceIsBuffering";
import { useListeningStatistics } from "components/Player/hooks/useListeningStatistics";
import { useMobileScrollLock } from "components/Player/hooks/useMobileScrollLock";
import { useNextTrackRef } from "components/Player/hooks/useNextTrackRef";
import { usePersistLastPlayed } from "components/Player/hooks/usePersistLastPlayed";
import { IUseQueue, useQueue } from "components/Player/hooks/useQueue";
import { useResumeLastPlayed } from "components/Player/hooks/useResumeLastPlayed";
import { useStoreEpisodeProgress } from "components/Player/hooks/useStoreEpisodeProgress";
import {
  trackPlaybackSkipBackward,
  trackPlaybackSkipForward,
  trackPlaybackStart,
  trackPlaybackStop,
} from "helpers/analytics";
import { episodeStarted } from "helpers/ecommerceAnalytics";
import { useEffectOnce } from "hooks/useEffectOnce";

export const ZPlayerEpisodeType = z.enum(["NORMAL", "LIVE"]);
// export type TPlayerEpisodeType = z.infer<typeof ZPlayerEpisodeType>;

export const ZPlayerNormalEpisode = z.object({
  approximatedDuration: z.union([z.number(), z.null()]),
  autoPlay: z.boolean(),
  description: z.string(),
  id: z.number(),
  podcast: ZPodcast,
  published: z.number(),
  startTime: z.number(),
  title: z.string(),
  type: z.literal(ZPlayerEpisodeType.enum.NORMAL),
  url: z.string(),
});
export type TPlayerNormalEpisode = z.infer<typeof ZPlayerNormalEpisode>;

export const ZPlayerLiveEpisode = ZPlayerNormalEpisode.omit({
  published: true,
}).merge(
  z.object({
    id: z.string(),
    type: z.literal(ZPlayerEpisodeType.enum.LIVE),
    startTime: z.literal(0),
    chatEnabled: z.boolean(),
    chatProvider: z.union([ZSendbird, z.undefined()]),
  })
);
export type TPlayerLiveEpisode = z.infer<typeof ZPlayerLiveEpisode>;

export const ZPlayerQueueItem = z.object({
  episode: ZEpisode,
  podcast: ZPodcast,
  moveCount: z.number(),
});
export type TPlayerQueueItem = z.infer<typeof ZPlayerQueueItem>;

export const ZPlayerQueue = ZPlayerQueueItem.array();
export type TPlayerQueue = z.infer<typeof ZPlayerQueue>;

export type TPlayerEpisode = TPlayerNormalEpisode | TPlayerLiveEpisode;

// NOTICE: This type was originally placed in the "persist" file. Zod has a
// circular dependency(?) issue https://github.com/colinhacks/zod/issues/1193
// and moving type here solved the problem for now.
export const ZPersistedEpisode = z.object({
  episode: z.union([ZPlayerNormalEpisode, ZPlayerLiveEpisode]),
  currentTime: z.number().optional(),
});

export type TPersistedEpisode = z.infer<typeof ZPersistedEpisode>;

export const isPlayerNormalEpisode = (
  episode: TPlayerEpisode
): episode is TPlayerNormalEpisode => {
  return episode.type === ZPlayerEpisodeType.enum.NORMAL;
};

export const isPlayerLiveEpisode = (
  episode: TPlayerEpisode
): episode is TPlayerLiveEpisode => {
  return episode.type === ZPlayerEpisodeType.enum.LIVE;
};

export interface IPlayerContext {
  // State.
  readonly episode: TPlayerEpisode | undefined;
  readonly paused: boolean;
  readonly error: unknown;
  readonly isBuffering: boolean;
  readonly playbackRate: number;
  readonly isExpanded: boolean;
  readonly autoPlayNextTrack: boolean;
  readonly desiredStartTime: number;
  readonly duration: number;
  readonly isAtEnd: boolean;
  readonly color: string;
  // NOTICE: Audio element exposed here to have a way to access high frequently
  // updated values like currentTime without updating the context.
  // Use only if you are sure it's ok.
  readonly _audio: HTMLAudioElement | undefined;
  // Functions.
  readonly beforeLogout: () => Promise<void>;
  readonly load: (
    episode: TPlayerEpisode,
    savePreviousEpisodeProgressOnUnload?: boolean
  ) => Promise<void>;
  readonly pause: () => void;
  readonly play: () => Promise<void>;
  readonly setPlaybackRate: Dispatch<SetStateAction<number>>;
  readonly restartFromCurrentTime: () => Promise<void>;
  readonly skip: (seconds: number) => void;
  readonly seekTo: (value: number) => void;
  readonly setIsExpanded: (value: boolean) => void;
  readonly setAutoPlayNextTrack: (value: boolean) => void;
  readonly unload: () => void;
  // Queue.
  readonly queue: IUseQueue["queue"];
  readonly setQueue: IUseQueue["setQueue"];
  readonly removeFromQueue: IUseQueue["removeFromQueue"];
  readonly moveToFrontOfQueue: IUseQueue["moveToFrontOfQueue"];
  readonly moveToBackOfQueue: IUseQueue["moveToBackOfQueue"];
  readonly addFirstInQueue: IUseQueue["addFirstInQueue"];
  readonly addLastInQueue: IUseQueue["addLastInQueue"];
  readonly queueCount: IUseQueue["queueCount"];
  readonly queueEpisodeProgress: IUseQueue["queueEpisodeProgress"];
}

export const PlayerContext = createContext<IPlayerContext | undefined>(
  undefined
);
PlayerContext.displayName = "PlayerContext";

export const PlayerContextProvider = (
  props: PropsWithChildren
): ReactElement => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [playbackRate, setPlaybackRate] = useState(1);

  useMobileScrollLock(isExpanded);

  const storeEpisodeProgress = useStoreEpisodeProgress();

  const {
    onLoad: onLoadListeningStatistics,
    onPause: onPauseListeningStatistics,
    onPlay: onPlayListeningStatistics,
    onBeforeSeek: onBeforeSeekListeningStatistics,
    onAfterSeek: onAfterSeekListeningStatistics,
    onUnload: onUnloadListeningStatistics,
  } = useListeningStatistics();

  const onLoad = useCallback(
    async (episode: TPlayerEpisode, audio: HTMLAudioElement) => {
      await onLoadListeningStatistics(episode, audio);
    },
    [onLoadListeningStatistics]
  );

  const onStart = useCallback((episode: TPlayerEpisode) => {
    episodeStarted(episode);
  }, []);

  const onPause = useCallback(
    async (episode: TPlayerEpisode, audio: HTMLAudioElement) => {
      trackPlaybackStop(episode, audio.currentTime);
      await Promise.all([
        onPauseListeningStatistics(),
        storeEpisodeProgress(episode, audio.currentTime, audio.duration),
      ]);
    },
    [onPauseListeningStatistics, storeEpisodeProgress]
  );

  const onPlay = useCallback(
    (episode: TPlayerEpisode, audio: HTMLAudioElement) => {
      onPlayListeningStatistics();
      trackPlaybackStart(episode, audio.playbackRate);
    },
    [onPlayListeningStatistics]
  );

  const onBeforeSeek = useCallback(async () => {
    await onBeforeSeekListeningStatistics();
  }, [onBeforeSeekListeningStatistics]);

  const onAfterSeek = useCallback(async () => {
    await onAfterSeekListeningStatistics();
  }, [onAfterSeekListeningStatistics]);

  const onSkipBackward = useCallback(
    (episode: TPlayerEpisode, targetTimeSec: number) => {
      trackPlaybackSkipBackward(episode, targetTimeSec);
    },
    []
  );

  const onSkipForward = useCallback(
    (episode: TPlayerEpisode, targetTimeSec: number) => {
      trackPlaybackSkipForward(episode, targetTimeSec);
    },
    []
  );

  const { user } = useUser();

  const onUnload = useCallback(
    async (
      episode: TPlayerEpisode,
      audio: HTMLAudioElement,
      saveProgress = true
    ) => {
      // This callback **could** be triggered when user refresh site in a logout
      // process - Prevent saving progress in that case.
      const isUserLoggingOut = getIsUserLoggingOut(!!user);
      if (!audio.paused && !isUserLoggingOut) {
        trackPlaybackStop(episode, audio.currentTime);
        if (saveProgress) {
          await Promise.all([
            onUnloadListeningStatistics(),
            storeEpisodeProgress(episode, audio.currentTime, audio.duration),
          ]);
        } else {
          await onUnloadListeningStatistics();
        }
      }
    },
    [onUnloadListeningStatistics, user, storeEpisodeProgress]
  );

  // Queue.
  const {
    moveToFrontOfQueue,
    moveToBackOfQueue,
    queueCount,
    queue,
    removeFromQueue,
    addFirstInQueue,
    addLastInQueue,
    setQueue,
    queueEpisodeProgress,
  } = useQueue();

  // Autoplay next track.
  const [autoPlayNextTrack, setAutoPlayNextTrack] = useState(true);
  useEffectOnce(() => {
    const autoplay = getPersistedAutoplay();
    if (autoplay !== undefined) {
      setAutoPlayNextTrack(autoplay);
    }
  });
  useEffect(() => {
    setPersistedAutoplay(autoPlayNextTrack);
  }, [autoPlayNextTrack]);

  // NOTICE: Use ref to make it possible to use "load" in "useNextTrackRef"
  // before it's defined (there is a circular dependency here).
  const loadRef = useRef<IUseAudioData["load"]>();
  const nextTrackRef = useNextTrackRef(
    loadRef,
    queue,
    queueEpisodeProgress,
    removeFromQueue,
    autoPlayNextTrack
  );

  const onEnded = useCallback(() => {
    nextTrackRef.current();
  }, [nextTrackRef]);

  const audio = useAudio({
    onEnded,
    nextTrackRef,
    onAfterSeek,
    onBeforeSeek,
    onLoad,
    onPause,
    onPlay,
    onSkipBackward,
    onSkipForward,
    onStart,
    onUnload,
    playbackRate,
  });

  // Update loadRef now when we have the load function.
  useEffect(() => {
    loadRef.current = audio.load;
  });

  const beforeLogout = useBeforeLogout(
    audio.currentTime,
    audio.duration,
    audio.paused,
    audio.episode,
    storeEpisodeProgress
  );

  useAddBodyClass(isExpanded, !!audio.episode);

  const onCollapse = useCallback(() => {
    setIsExpanded(false);
  }, []);
  useAutoCloseExpandOnMobile(isExpanded, onCollapse);

  const debouncedIsBuffering = useDebounceIsBuffering(audio.isBuffering);

  // Resume / persist last played episode (and queue).
  useResumeLastPlayed(!!audio.episode, audio.load, setQueue);
  usePersistLastPlayed(audio.episode, audio.currentTime, queue);

  // Color.
  const color = useColor(audio.episode ? audio.episode.podcast.image : null);

  // Context value.
  const contextValue = useMemo<IPlayerContext>(
    () => ({
      // State.
      episode: audio.episode,
      paused: audio.paused,
      error: audio.error,
      isBuffering: debouncedIsBuffering,
      playbackRate,
      isExpanded,
      autoPlayNextTrack,
      desiredStartTime: audio.desiredStartTime,
      duration: audio.duration,
      isAtEnd: audio.isAtEnd,
      color,
      _audio: audio._audio,
      // Functions.
      load: audio.load,
      pause: audio.pause,
      play: audio.play,
      restartFromCurrentTime: audio.restartFromCurrentTime,
      skip: audio.skip,
      seekTo: audio.seekTo,
      unload: audio.unload,
      beforeLogout,
      setPlaybackRate,
      setIsExpanded,
      setAutoPlayNextTrack,
      // Queue.
      queue,
      setQueue,
      removeFromQueue,
      moveToFrontOfQueue,
      moveToBackOfQueue,
      addFirstInQueue,
      addLastInQueue,
      queueCount,
      queueEpisodeProgress,
    }),
    [
      addFirstInQueue,
      addLastInQueue,
      audio._audio,
      audio.desiredStartTime,
      audio.duration,
      audio.episode,
      audio.error,
      audio.isAtEnd,
      audio.load,
      audio.pause,
      audio.paused,
      audio.play,
      audio.restartFromCurrentTime,
      audio.seekTo,
      audio.skip,
      audio.unload,
      autoPlayNextTrack,
      beforeLogout,
      color,
      setPlaybackRate,
      debouncedIsBuffering,
      isExpanded,
      moveToBackOfQueue,
      moveToFrontOfQueue,
      playbackRate,
      queue,
      queueCount,
      queueEpisodeProgress,
      removeFromQueue,
      setQueue,
    ]
  );

  return <PlayerContext.Provider value={contextValue} {...props} />;
};
