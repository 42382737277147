import {
  getBrowserStorageItem,
  setBrowserStorageItem,
  StorageItem,
} from "helpers/browserStorage";
import { hasFacebookPixel } from "helpers/hasFacebookPixel";
import { hasGoogleAnalytics } from "helpers/hasGoogleAnalytics";
import { identifyAsUser } from "helpers/hotjar";
import { isUserAgentBot } from "helpers/isUserAgentBot";
import { useEffectOnce } from "hooks/useEffectOnce";

// Source Point Vendor IDs.
const SP_FACEBOOK_VENDOR_ID = "5fac56cd1ba05165880458ad";
const SP_GA_VENDOR_ID = "5e542b3a4cd8884eb41b5a72";
const SP_HOTJAR_VENDOR_ID = "5ee7add94c24944fdb5c5ac6";

export const useSourcePoint = (): void => {
  //Triggers once on first load
  useEffectOnce(() => {
    //Don't run if User Agent Bot or executeMessaging is undefined or Cypress is defined.
    if (
      isUserAgentBot(navigator.userAgent) ||
      !_sp_?.executeMessaging ||
      /Cypress=true/.test(document.cookie) ||
      // @ts-ignore
      window.Cypress
    ) {
      return;
    }
    _sp_.executeMessaging();
    return () => {
      _sp_.destroyMessages();
    };
  });
};

export const useTfcApi = (): void => {
  useEffectOnce(() => {
    if (
      isUserAgentBot(navigator.userAgent) ||
      typeof __tcfapi === "undefined" ||
      /Cypress=true/.test(document.cookie) ||
      // @ts-ignore
      window.Cypress
    ) {
      return;
    }
    const callback = (tcData, success) => {
      if (success) {
        setBrowserStorageItem(
          StorageItem.CONSENTS_SOURCE_POINT,
          tcData.tcString
        );
        if (tcData.eventStatus === "tcloaded") {
          // remove the ourself to not get called more than once
          __tcfapi("removeEventListener", 2, () => null, tcData.listenerId);
        }

        // Get all custom Source Point vendor consents.
        __tcfapi("getCustomVendorConsents", 2, function (response) {
          // Facebook Pixel is disabled by default, but toggled here
          // according to grant status.

          if (hasFacebookPixel()) {
            if (
              response.grants[SP_FACEBOOK_VENDOR_ID]?.vendorGrant === true &&
              window
            ) {
              global.fbq("consent", "grant");
            } else {
              window?.fbq?.("consent", "revoke");
            }
          }

          // Google Analytics ad_storage and analytics_storage is disabled by default,
          // but toggled here according to grant status.
          if (hasGoogleAnalytics()) {
            if (response.grants[SP_GA_VENDOR_ID]?.vendorGrant === true) {
              global.gtag("consent", "update", {
                ad_storage: "granted",
                analytics_storage: "granted",
              });
            } else {
              global.gtag("consent", "update", {
                ad_storage: "denied",
                analytics_storage: "denied",
              });
            }
          }

          // Enable Hotjar if granted in Source Point.
          // Note enable is a custom event that we have defined on hotjar.com
          if (response.grants[SP_HOTJAR_VENDOR_ID]?.vendorGrant === true) {
            // Enable Hotjar if Hotjar is active.
            if (window.hj) {
              window.hj("event", "enable");
            }
            if (getBrowserStorageItem(StorageItem.AUTH_ACCESS)) {
              identifyAsUser();
            }
          }
        });
      }
    };
    __tcfapi("addEventListener", 2, callback);
  });
};
