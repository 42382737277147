import { NextRouter, useRouter } from "next/router";
import { Locale } from "interfaces/interfaces";

interface ITypedUseRouter extends NextRouter {
  locale: Locale;
  defaultLocale: Locale;
  locales: Locale[];
}

export const useTypedRouter = useRouter as () => ITypedUseRouter;
