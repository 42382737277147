import { SVGProps } from "react";

export const defaultIconProps: SVGProps<SVGSVGElement> = {
  viewBox: "0 0 24 24",
  "aria-hidden": true,
  focusable: false,
  role: "img",
};

export interface IIconProps
  extends Omit<SVGProps<SVGSVGElement>, "width" | "height"> {
  size: number | string;
}

/**
 * Converts icon props to SVG props.
 */
export const getSVGProps = ({
  size,
  ...rest
}: IIconProps): SVGProps<SVGSVGElement> => {
  return {
    ...defaultIconProps,
    width: size,
    height: size,
    ...rest,
  };
};
