enum StorageType {
  LocalStorage,
  SessionStorage,
}

export enum StorageItem {
  AUTH_REFRESH = "podplay.auth.refresh",
  AUTH_ACCESS = "podplay.auth.access",
  CONSENTS_SOURCE_POINT = "podplay.consents_sp",
  LOCAL_STORAGE_TEST = "podplay.local_storage_test",
  PLAYER_EPISODE = "podplay.player.episode",
  PLAYER_QUEUE = "podplay.player.queue",
  PLAYER_AUTOPLAY = "podplay.player.autoplay",
  FEED_EPISODE_LIST_TOGGLE = "podplay.feed.episode_list_toggle",
  FEED_LAST_PUBLISHED = "podplay.feed.last_published",

  EPISODE_PROGRESS = "podplay.episode_progress",
  SEARCH = "podplay.search",
  UID = "podplay.uid",
  // NOTICE: "OLD_REDUX_PERSIST" could be removed in the future. Keep it for a
  // while to make sure old persisted data is cleared from local storage when
  // logging out.
  OLD_REDUX_PERSIST = "persist:root",
  USER_INFO = "podplay.user_info",
}

let type: StorageType = StorageType.LocalStorage;

/**
 * Function to handle errors when accessing storage api.
 */
const onError = (err: unknown): void => {
  if (process.env.NODE_ENV === "development") {
    // eslint-disable-next-line no-console
    console.log(err);
  }

  // Rethrow error if server side - Local storage should not be accessed server
  // and error should halt application execution.
  if (typeof window === "undefined") {
    throw err;
  }

  // NOTICE: Local storage can throw errors under various circumstances.
  // Don't report to Sentry because it creates too much noise.
};

/**
 * Function to check if local storage is available. Should *not* recheck once
 * fallback mode (session storage) is entered.
 */
const localStorageAvailable = (type: StorageType): boolean => {
  if (type === StorageType.SessionStorage) {
    return false;
  }
  try {
    window.localStorage.setItem(StorageItem.LOCAL_STORAGE_TEST, "1");
    window.localStorage.removeItem(StorageItem.LOCAL_STORAGE_TEST);
    return true;
  } catch (err) {
    onError(err);
    return false;
  }
};

/**
 * Get storage variant based on type.
 */
const getStorage = (type: StorageType): Storage => {
  return type === StorageType.LocalStorage
    ? window.localStorage
    : window.sessionStorage;
};

export const setBrowserStorageItem = (
  key: StorageItem,
  value: string
): void => {
  // Change to "session storage" if "local storage" not available.
  if (!localStorageAvailable(type)) {
    type = StorageType.SessionStorage;
  }

  try {
    getStorage(type).setItem(key, value);
  } catch (err) {
    onError(err);
  }
};

export const getBrowserStorageItem = (key: StorageItem): string | null => {
  try {
    return getStorage(type).getItem(key);
  } catch (err) {
    onError(err);
    return null;
  }
};

export const removeBrowserStorageItem = (key: StorageItem): void => {
  try {
    getStorage(type).removeItem(key);
  } catch (e) {
    return;
  }
};

export const clearBrowserStorage = (): void => {
  try {
    getStorage(type).clear();
  } catch (e) {
    return;
  }
};
