import { getBrowserStorageItem, StorageItem } from "helpers/browserStorage";

/**
 * This function check if the user is currently logging out. Context is not
 * updated in the logout process but local storage will reflect the real state.
 */
export const getIsUserLoggingOut = (
  isLoggedInInContext: boolean | undefined
): boolean => {
  const isStillLoggedIn = !!getBrowserStorageItem(StorageItem.AUTH_REFRESH);
  return !!isLoggedInInContext && !isStillLoggedIn;
};
