import { useContext } from "react";
import {
  IModalContext,
  ModalContext,
} from "components/Modals/context/ModalContextProvider";

export const useModal = (): IModalContext => {
  const context = useContext(ModalContext);
  if (!context) {
    throw new Error("Can't find Modal context provider.");
  }
  return context;
};
