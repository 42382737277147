import { useCallback, useMemo, useState } from "react";
import { TEpisodeProgress } from "api/podplay/history";
import {
  TPlayerQueue,
  TPlayerQueueItem,
} from "components/Player/context/PlayerContextProvider";
import {
  createQueueItem,
  ICreateQueueListItem,
} from "components/Player/helpers/createQueue";
import { useEpisodeProgress } from "hooks/useEpisodeProgress";

export interface IUseQueue {
  queue: TPlayerQueue;
  queueEpisodeProgress: Record<number, TEpisodeProgress | undefined>;
  setQueue: (queue: TPlayerQueue) => void;
  removeFromQueue: (episodeId: number) => void;
  moveToFrontOfQueue: (episodeId: number) => void;
  moveToBackOfQueue: (episodeId: number) => void;
  addFirstInQueue: (createQueueListItem: ICreateQueueListItem) => void;
  addLastInQueue: (createQueueListItem: ICreateQueueListItem) => void;
  queueCount: number;
}

const changePosition = (
  queue: TPlayerQueue,
  fromIndex: number,
  toIndex: number
): TPlayerQueue => {
  const updatedQueue = [...queue];
  const item = updatedQueue.splice(fromIndex, 1)[0];
  const movedItem: TPlayerQueueItem = {
    ...item,
    // Increment move count - This is needed to make the exit/enter
    // animation logic work in the Queue component.
    moveCount: item.moveCount + 1,
  };
  updatedQueue.splice(toIndex, 0, movedItem);

  return updatedQueue;
};

/**
 * This function encapsulate the PlayerContextProvider queue state and functionality.
 */
export const useQueue = (): IUseQueue => {
  const [queue, setQueue] = useState<TPlayerQueue>([]);
  const [queueCount, setQueueCount] = useState(0);
  const episodes = useMemo(() => queue.map((item) => item.episode), [queue]);
  const { episodeProgress: queueEpisodeProgress } =
    useEpisodeProgress(episodes);

  // Create.
  const createQueue = useCallback((queue: TPlayerQueue) => {
    setQueue(queue);
    setQueueCount((current) => current + 1);
  }, []);

  // Remove
  const removeFromQueue = useCallback(
    (episodeId: number): void => {
      setQueue(queue.filter((item) => item.episode.id !== episodeId));
    },
    [queue]
  );

  // Move to front.
  const moveToFrontOfQueue = useCallback(
    (episodeId: number): void => {
      const episodeIndex = queue.findIndex(
        (item) => item.episode.id === episodeId
      );
      if (episodeIndex === -1) {
        return;
      }
      setQueue(changePosition(queue, episodeIndex, 0));
    },
    [queue]
  );

  // Move to back.
  const moveToBackOfQueue = useCallback(
    (episodeId: number): void => {
      const episodeIndex = queue.findIndex(
        (item) => item.episode.id === episodeId
      );
      if (episodeIndex === -1) {
        return;
      }
      setQueue(changePosition(queue, episodeIndex, queue.length - 1));
    },
    [queue]
  );

  const addFirstInQueue = useCallback(
    (createQueueListItem: ICreateQueueListItem): void => {
      const episodeIndex = queue.findIndex(
        (item) => item.episode.id === createQueueListItem.episode.id
      );

      if (episodeIndex === -1) {
        const queueEpisode = createQueueItem(createQueueListItem);
        setQueue([queueEpisode, ...queue]);
      } else {
        // Just move if episode already exist in queue.
        setQueue(changePosition(queue, episodeIndex, 0));
      }
    },
    [queue]
  );

  const addLastInQueue = useCallback(
    (createQueueListItem: ICreateQueueListItem): void => {
      const episodeIndex = queue.findIndex(
        (item) => item.episode.id === createQueueListItem.episode.id
      );

      if (episodeIndex === -1) {
        const queueEpisode = createQueueItem(createQueueListItem);
        setQueue([...queue, queueEpisode]);
      } else {
        // Just move if episode already exist in queue.
        setQueue(changePosition(queue, episodeIndex, queue.length - 1));
      }
    },
    [queue]
  );

  return {
    queue,
    queueEpisodeProgress,
    setQueue: createQueue,
    removeFromQueue,
    moveToFrontOfQueue,
    moveToBackOfQueue,
    addFirstInQueue,
    addLastInQueue,
    queueCount,
  };
};
