import { useUser } from "contexts/UserContextProvider/UserContextProvider";
import { getUnixTime } from "date-fns";
import { useCallback, useEffect, useRef } from "react";
import {
  TPlayerEpisode,
  isPlayerNormalEpisode,
} from "components/Player/context/PlayerContextProvider";
import {
  getSessionId,
  saveListeningStatistics,
} from "components/Player/helpers/saveListeningStatistics";
import { parseLocale } from "helpers/parseLocale";
import { usePrevious } from "hooks/usePrevious";
import { useTypedRouter } from "hooks/useTypedRouter";

export interface IUseListeningStatistics {
  onLoad: (episode: TPlayerEpisode, audio: HTMLAudioElement) => Promise<void>;
  onPlay: () => void;
  onPause: () => Promise<void>;
  onBeforeSeek: () => Promise<void>;
  onAfterSeek: () => Promise<void>;
  onUnload: () => Promise<void>;
}

interface QueueItem {
  startPositionSec: number;
  endPositionSec: number;
  durationSec: number;
  timestampUnix: number;
}

enum SessionIdState {
  PENDING = "PENDING",
}

/**
 * This hook returns callbacks that should be called to call the listening
 * statistics api. I.e. recommendations.
 */
export const useListeningStatistics = (): IUseListeningStatistics => {
  const { user } = useUser();
  const isLoggedIn = user === undefined ? user : !!user;
  const { locale } = useTypedRouter();
  const { language } = parseLocale(locale);

  // Refs.
  const intervalIdRef = useRef<number>();
  const startPositionSecRef = useRef<number>();
  const episodeIdRef = useRef<number>();
  const audioRef = useRef<HTMLAudioElement>();
  const sessionIdsRef = useRef<
    Record<number, string | undefined | SessionIdState>
  >({});
  const saveQueueRef = useRef<Record<number, QueueItem[] | undefined>>({});

  // ---------------------------------------------------------------------------
  // Reset episode related refs.
  // ---------------------------------------------------------------------------
  const resetEpisodeRelatedRefs = useCallback(() => {
    // Reset episode/audio specific refs.
    // NOTICE: Other refs should not be reset.
    startPositionSecRef.current = undefined;
    episodeIdRef.current = undefined;
    audioRef.current = undefined;
  }, []);

  // ---------------------------------------------------------------------------
  // Flush queue entry.
  // ---------------------------------------------------------------------------
  const flushQueueEntry = useCallback(
    async (episodeId: number) => {
      const items = saveQueueRef.current[episodeId];

      // No queue - Return early.
      if (!items) {
        return;
      }

      const sessionId = sessionIdsRef.current[episodeId];

      // No sessionId - Return early.
      if (!sessionId || sessionId === SessionIdState.PENDING) {
        return;
      }

      // Remove items from queue.
      saveQueueRef.current[episodeId] = undefined;

      // Save all items.
      await Promise.all(
        items.map((item) =>
          saveListeningStatistics({
            durationSec: item.durationSec,
            endPositionSec: item.endPositionSec,
            isLoggedIn: !!isLoggedIn,
            language,
            sessionId,
            startPositionSec: item.startPositionSec,
            timestampUnix: item.timestampUnix,
          })
        )
      );
    },
    [isLoggedIn, language]
  );

  // ---------------------------------------------------------------------------
  // Add to save queue.
  // ---------------------------------------------------------------------------
  const addCurrentToSaveQueue = useCallback(async () => {
    if (!episodeIdRef.current || !audioRef.current) {
      return;
    }

    const episodeId = episodeIdRef.current;
    const audio = audioRef.current;
    const startPositionSec = startPositionSecRef.current;

    if (typeof episodeId === "undefined") {
      throw new Error("Episode id can't be undefined");
    }

    if (typeof startPositionSec === "undefined") {
      throw new Error("Start position can't be undefined");
    }

    const segmentDurationSec = audio.currentTime - startPositionSec;

    // Don't add to queue if too small segment or if duration is NaN (could
    // happen before the duration is loaded by the audio element).
    if (segmentDurationSec < 1 || isNaN(audio.duration)) {
      if (process.env.NODE_ENV === "development") {
        // eslint-disable-next-line no-console
        console.log("Too small segment or duration is NaN:", {
          segmentDurationSec,
          duration: audio.duration,
        });
      }
      // NOTICE: Update start time event though we bail out of saving.
      startPositionSecRef.current = audio.currentTime;
      return;
    }

    const queue = saveQueueRef.current[episodeId] || [];

    saveQueueRef.current[episodeId] = queue;

    queue.push({
      startPositionSec: startPositionSec,
      endPositionSec: audio.currentTime,
      durationSec: audio.duration,
      timestampUnix: getUnixTime(Date.now()),
    });

    // NOTICE: Set startPosition to currentTime - It marks the end of this
    // segment but the start of next segment.
    // NOTICE: Set start position to 0 if we are at the very end of the audio.
    // When triggering play again the current time will jump automatically to 0.
    startPositionSecRef.current =
      audio.currentTime === audio.duration ? 0 : audio.currentTime;

    // Flush queue entry if we got session id. The "flushQueueEntry" will be
    // called again when session id is loaded and there is queue.
    const sessionId = sessionIdsRef.current[episodeId];
    if (sessionId && sessionId !== SessionIdState.PENDING) {
      await flushQueueEntry(episodeId);
    }
  }, [flushQueueEntry]);

  // ---------------------------------------------------------------------------
  // When we know user login state or when user logs in.
  // ---------------------------------------------------------------------------
  const previousIsLoggedIn = usePrevious(isLoggedIn);
  useEffect(() => {
    if (
      // Going from logged in undefined to true/false.
      (previousIsLoggedIn === undefined && isLoggedIn !== undefined) ||
      // Going from logged in false to true.
      (previousIsLoggedIn === false && isLoggedIn === true)
    ) {
      // Clear previous fetched session ids.
      sessionIdsRef.current = {};
      // Fetch/refetch session id if we have an episode.
      const episodeId = episodeIdRef.current;
      if (episodeId !== undefined) {
        (async () => {
          // Set pending state.
          sessionIdsRef.current[episodeId] = SessionIdState.PENDING;
          // Fetch session id.
          const sessionId = await getSessionId(episodeId, isLoggedIn, language);
          sessionIdsRef.current[episodeId] = sessionId;
          // Call flush queue now when we got a session id. It could have been
          // items added to the queue during the session id fetch period.
          await flushQueueEntry(episodeId);
        })();
      }
    }
  }, [flushQueueEntry, isLoggedIn, language, previousIsLoggedIn]);

  // ---------------------------------------------------------------------------
  // On load.
  // ---------------------------------------------------------------------------
  const onLoad = useCallback(
    async (episode: TPlayerEpisode, audio: HTMLAudioElement): Promise<void> => {
      // Clear old interval.
      clearInterval(intervalIdRef.current);

      // Reset old refs.
      resetEpisodeRelatedRefs();

      // Only register for normal episodes.
      if (!isPlayerNormalEpisode(episode)) {
        return;
      }

      // Set new refs.
      episodeIdRef.current = episode.id;
      audioRef.current = audio;
      startPositionSecRef.current = episode.startTime;

      // Get "sessionId" if not fetched yet.
      // NOTICE: Don't fetch if "isLoggedIn" is undefined. Fetch are going to be
      // triggered when going from undefined to true/false in an affect.
      if (
        isLoggedIn !== undefined &&
        sessionIdsRef.current[episode.id] === undefined
      ) {
        // Set pending state.
        sessionIdsRef.current[episode.id] = SessionIdState.PENDING;
        // Fetch session id.
        const sessionId = await getSessionId(episode.id, isLoggedIn, language);
        sessionIdsRef.current[episode.id] = sessionId;
        // Call flush queue now when we got a session id. It could have been
        // items added to the queue during the session id fetch period.
        await flushQueueEntry(episode.id);
      }
    },
    [flushQueueEntry, isLoggedIn, language, resetEpisodeRelatedRefs]
  );

  // ---------------------------------------------------------------------------
  // On play.
  // ---------------------------------------------------------------------------
  const onPlay = useCallback((): void => {
    window.clearInterval(intervalIdRef.current);
    intervalIdRef.current = window.setInterval(
      addCurrentToSaveQueue,
      // Add to save queue every second minute.
      1000 * 120
    );
  }, [addCurrentToSaveQueue]);

  // ---------------------------------------------------------------------------
  // On pause.
  // ---------------------------------------------------------------------------
  const onPause = useCallback(async () => {
    window.clearInterval(intervalIdRef.current);
    await addCurrentToSaveQueue();
  }, [addCurrentToSaveQueue]);

  // ---------------------------------------------------------------------------
  // On before seek.
  // ---------------------------------------------------------------------------
  const onBeforeSeek = useCallback(async () => {
    const audio = audioRef.current;
    if (!audio || audio.paused) {
      return;
    }

    await addCurrentToSaveQueue();
  }, [addCurrentToSaveQueue]);

  // ---------------------------------------------------------------------------
  // On after seek.
  // ---------------------------------------------------------------------------
  const onAfterSeek = useCallback(async () => {
    const audio = audioRef.current;
    if (!audio) {
      return;
    }
    startPositionSecRef.current = audio.currentTime;
  }, []);

  // ---------------------------------------------------------------------------
  // On unload.
  // ---------------------------------------------------------------------------
  const onUnload = useCallback(async () => {
    window.clearInterval(intervalIdRef.current);
    // NOTICE: Paused audio should have already saved listening statistics.
    if (audioRef.current?.paused) {
      return;
    }
    await addCurrentToSaveQueue();
  }, [addCurrentToSaveQueue]);

  // ---------------------------------------------------------------------------
  // Return.
  // ---------------------------------------------------------------------------
  return { onLoad, onPlay, onPause, onBeforeSeek, onAfterSeek, onUnload };
};
