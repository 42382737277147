import { useDebounce } from "hooks/useDebounce";

/**
 * Immediately set buffering to false when false but use debounced value if
 * buffering. This will prevent buffering graphics to show up too frequently.
 * Specially noticeable when starting a new episode.
 * @param isBuffering - The real "is buffering" value.
 */
export const useDebounceIsBuffering = (isBuffering: boolean): boolean => {
  const debouncedIsBuffering = useDebounce(isBuffering, 1000);

  if (!isBuffering) {
    return false;
  }

  return debouncedIsBuffering;
};
