// These constants are used as for building virtual page paths in
// Google Analytics. E.g. WELCOME will trigger a page view
// on /sv/modals/exclusive/welcome.
export enum ModalType {
  LOGIN_OR_SIGNUP = "#login-or-signup",
  LOGIN = "#login",
  SIGNUP = "#signup",
  CONSENTS = "#consents",
  AUTH_ERROR = "#auth-error",
  FORGOT_PASSWORD = "#forgot-password",
  FORGOT_PASSWORD_SUCCESS = "#forgot-password-success",
  RESET_PASSWORD = "#reset-password",
  PROFILE_NAME = "#profile-name",
  FOLLOW_ONBOARDING = "#follow-onboarding",
}

export const isModalType = (hash: string): hash is ModalType => {
  return Object.values<string>(ModalType).includes(hash);
};
