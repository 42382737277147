import * as Fathom from "fathom-client";
import { useRouter } from "next/router";
import { useEffect } from "react";
import { setUser as setAnalyticsUser } from "helpers/analytics";
import { facebookPageview } from "helpers/facebookPixel";
import { getDecodedAccessToken } from "helpers/getDecodedAccessToken";

let fathomLoaded = false;

export const useAnalytics = (): void => {
  const router = useRouter();

  // Set Google Analytics user id.
  useEffect(() => {
    const decodedAccessToken = getDecodedAccessToken();
    if (decodedAccessToken?.sub) {
      setAnalyticsUser(decodedAccessToken.sub);
    }
  }, []);

  // Load fathom.
  useEffect(() => {
    if (!process.env.NEXT_PUBLIC_FATHOM_ID) {
      return;
    }

    if (fathomLoaded) {
      return;
    }
    fathomLoaded = true;
    // The Fathom script is not located on our servers.
    // The subdomain is linked with a CNAME to Fathoms CDN.
    // We do this to avoid adblockers.
    Fathom.load(`${process.env.NEXT_PUBLIC_FATHOM_ID}`, {
      url: "https://harmonious-station-to-station.podplay.com/script.js",
      includedDomains: ["podplay.com", "www.podplay.com"],
    });
  }, []);

  // Track pageview on router change.
  useEffect(() => {
    const handleRouteChange = () => {
      // Facebook.
      facebookPageview();
      // Fathom.
      Fathom.trackPageview();
    };
    router.events.on("routeChangeComplete", handleRouteChange);
    return () => {
      router.events.off("routeChangeComplete", handleRouteChange);
    };
  }, [router.events]);
};
