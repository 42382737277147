import { useEpisodeProgressContext } from "contexts/EpisodeProgressContextProvider";
import { useUser } from "contexts/UserContextProvider/UserContextProvider";
import { useEffect, useState } from "react";
import useSWR from "swr";
import {
  getEpisodeProgressByEpisodeIdsUrl,
  TEpisodeProgress,
  TGetEpisodeProgressByEpisodeIdsResponseBody,
} from "api/podplay/history";
import { podplayAuthFetch } from "helpers/authFetch";
import { chunkArray } from "helpers/chunkArray";
import { deepCompare } from "helpers/deepCompare";
import { parseLocale } from "helpers/parseLocale";
import { isNotUndefined, isSuccessResponse } from "helpers/typeGuards";
import { useTypedRouter } from "hooks/useTypedRouter";
import { IEpisode, Language } from "interfaces/interfaces";

const PROGRESS_CHUNK_SIZE = 50;

/**
 * Fetches episode progress from API.
 */
export const fetchEpisodeProgress = async (
  episodes: IEpisode[],
  language: Language
): Promise<{ fetchedIds: number[]; episodeProgress: TEpisodeProgress[] }> => {
  // Episode progress.
  // NOTICE: Progress request url can be too long for the browser to handle.
  // Split up in chunks.
  const chunks = chunkArray(episodes, PROGRESS_CHUNK_SIZE);
  const responses = await Promise.all(
    chunks.map((episodes) =>
      podplayAuthFetch<TGetEpisodeProgressByEpisodeIdsResponseBody>(
        language,
        getEpisodeProgressByEpisodeIdsUrl(language, episodes)
      )
    )
  );

  // NOTICE: This function will never reject. It's too hard as decide what to
  // do when we are doing multiple request.
  return {
    fetchedIds: episodes.map((episode) => episode.id),
    episodeProgress: responses
      .filter(isSuccessResponse)
      .flatMap((response) => response.body.results),
  };
};

/**
 * Create subset from episode progress record with all episode progress.
 */
export const createDataSubset = (
  episodes: IEpisode[] | undefined,
  episodeProgress: Record<number, TEpisodeProgress | undefined>
): Record<number, TEpisodeProgress | undefined> => {
  return (episodes || [])
    .map((episode) => episodeProgress[episode.id])
    .filter(isNotUndefined)
    .reduce((acc, curr) => {
      acc[curr.episode_id] = curr;
      return acc;
    }, {} as Record<number, TEpisodeProgress | undefined>);
};

interface IUseEpisodeProgress {
  episodeProgress: Record<number, TEpisodeProgress | undefined>;
}

export const useEpisodeProgress = (
  episodes: IEpisode[] | undefined
): IUseEpisodeProgress => {
  const { locale } = useTypedRouter();
  const { language } = parseLocale(locale);
  const { user } = useUser();
  const [fetchedIds, setFetchedIds] = useState<Set<number>>(() => new Set([]));

  // Remove ids already fetched by this hook. NOTICE: Don't use globally
  // fetched ids in this calculation because that will probably create new
  // lists too often and cause too much cancellation.
  const filteredEpisodes =
    episodes?.filter((episode) => !fetchedIds.has(episode.id)) || [];

  const { data } = useSWR(
    user && filteredEpisodes.length
      ? [
          filteredEpisodes.map((episode) => episode.id),
          language,
          "episode-progress",
        ]
      : null,
    () => fetchEpisodeProgress(filteredEpisodes, language),
    {
      // Don't revalidate - We are storing values in context. We should treat
      // those values as the most updated because it's the user that are the
      // source of them.
      revalidateIfStale: false,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    }
  );

  const { episodeProgress, updateEpisodeProgress } =
    useEpisodeProgressContext();

  // Update episode progress in context if we get new fetch data.
  useEffect(() => {
    if (!data) {
      return;
    }
    setFetchedIds((value) => new Set([...value, ...data.fetchedIds]));
    updateEpisodeProgress(data.episodeProgress);
  }, [data, updateEpisodeProgress]);

  // Create subset to be able to compare with old. This will prevent creating
  // new instance of dataSubset everytime data in context is updated.
  const [dataSubset, setDataSubset] = useState(() => {
    return createDataSubset(episodes, episodeProgress);
  });

  useEffect(() => {
    const newDataSubset = createDataSubset(episodes, episodeProgress);
    if (!deepCompare(dataSubset, newDataSubset)) {
      setDataSubset(newDataSubset);
    }
  }, [dataSubset, episodeProgress, episodes]);

  // Return.
  return { episodeProgress: dataSubset };
};
