import { I18nKey } from "locales/constants/I18nKey";
import React, {
  createContext,
  PropsWithChildren,
  ReactElement,
  useMemo,
} from "react";
import { getI18n } from "components/I18nContextProvider/helpers/getI18n";

export type II18n = (
  key: I18nKey,
  replace?: Record<string, string | undefined>
) => string;

export interface II18nContext {
  i18n: II18n;
}

export const I18nContext = createContext<II18nContext | undefined>(undefined);

export type I18nDictionary = Record<I18nKey, string | undefined>;

interface IProps {
  dictionary: I18nDictionary;
}

export const I18nContextProvider = ({
  dictionary,
  children,
}: PropsWithChildren<IProps>): ReactElement => {
  const contextValue: II18nContext = useMemo(
    () => ({
      i18n: (
        key: I18nKey,
        replace?: Record<string, string | undefined>
      ): string => getI18n(dictionary, key, replace),
    }),
    [dictionary]
  );

  return (
    <I18nContext.Provider value={contextValue}>{children}</I18nContext.Provider>
  );
};
