import router from "next/router";
import { lstatFetch } from "api/lstat/lstatFetch";
import { refreshAccessToken } from "api/podplay/user";
import { ModalType } from "components/Modals/modalTypes";
import {
  getBrowserStorageItem,
  setBrowserStorageItem,
  StorageItem,
} from "helpers/browserStorage";
import { IErrorResponse, ISuccessResponse } from "helpers/customFetch";
import { getIsAccessTokenExpired } from "helpers/getIsAccessTokenExpired";
import { podplayFetch } from "helpers/podplayFetch";
import { sonosFetch } from "helpers/sonosFetch";
import {
  HTTPStatusCode,
  IPodplayApiErrorBody,
  Language,
  PodplayApiErrorCode,
} from "interfaces/interfaces";

export async function authFetch<T>(
  fetchFunction: (
    url: string,
    localRequestInit: RequestInit
  ) => Promise<ISuccessResponse<T> | IErrorResponse<IPodplayApiErrorBody>>,
  language: Language,
  url: string,
  requestInit?: RequestInit
) {
  let accessToken = getBrowserStorageItem(StorageItem.AUTH_ACCESS);
  const refreshToken = getBrowserStorageItem(StorageItem.AUTH_REFRESH);

  // Update access token.
  if (
    refreshToken &&
    (!accessToken ||
      getIsAccessTokenExpired(accessToken, new Date().getTime() / 1000, 300))
  ) {
    const response = await refreshAccessToken(language, refreshToken, {
      signal: requestInit?.signal,
    });

    // Error.
    if (!response.ok) {
      if (response.status && response.status >= 400 && response.status < 500) {
        await router.replace(ModalType.AUTH_ERROR, undefined, {
          shallow: true,
        });
      }
      return response;
    }

    // Success - Store and update new access token.
    setBrowserStorageItem(StorageItem.AUTH_ACCESS, response.body.access_token);
    accessToken = response.body.access_token;
  }

  // Add access token.
  const localRequestInit = requestInit || {};

  if (accessToken) {
    localRequestInit.headers = {
      ...localRequestInit.headers,
      Authorization: `Bearer ${accessToken}`,
    };
  }

  const response = await fetchFunction(url, localRequestInit);

  if (!response.ok) {
    if (
      response.status === HTTPStatusCode.FORBIDDEN &&
      response?.body?.code === PodplayApiErrorCode.USER_CLAIMS_ARE_INVALID
    ) {
      await router.replace(ModalType.CONSENTS, undefined, { shallow: true });
    } else if (
      response.status &&
      [HTTPStatusCode.UNAUTHORIZED].includes(response.status)
    ) {
      await router.replace(ModalType.AUTH_ERROR, undefined, { shallow: true });
    }
  }

  return response;
}

export async function podplayAuthFetch<T>(
  language: Language,
  url: string,
  requestInit?: RequestInit
) {
  return await authFetch<T>(podplayFetch, language, url, requestInit);
}

export async function lstatAuthFetch<T>(
  language: Language,
  url: string,
  requestInit?: RequestInit
) {
  return await authFetch<T>(lstatFetch, language, url, requestInit);
}

export async function sonosAuthFetch<T>(
  language: Language,
  url: string,
  requestInit?: RequestInit
) {
  return await authFetch<T>(sonosFetch, language, url, requestInit);
}
