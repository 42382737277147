import { IPodplayUser } from "contexts/UserContextProvider/UserContextProvider";
import { fromUnixTime } from "date-fns";
import differenceInYears from "date-fns/differenceInYears";
import { parse, stringify } from "query-string";
import { getAgeGroup } from "./getAgeGroup";
import { serializeGender } from "./serializeGender";

export const decorateAudioUrl = (
  url: string,
  uid: string,
  millisecondsNow: number,
  user: IPodplayUser | null,
  sourcePointConsents: string | null
): string => {
  const urlParts = new URL(url);
  const timestamp = Math.floor(millisecondsNow / 1000);

  const parsedParams = parse(urlParts.search);

  if (user) {
    // Gender.
    if (user.gender) {
      parsedParams["aw_0_1st.bauer_gender"] = serializeGender(user.gender);
    }

    // Age.
    if (typeof user.birthyear === "number") {
      const age = differenceInYears(
        millisecondsNow,
        fromUnixTime(user.birthyear)
      );
      const ageGroup = getAgeGroup(age);
      parsedParams["aw_0_1st.bauer_age"] = age.toString(10);
      parsedParams["aw_0_1st.bauer_agegroup"] = ageGroup;
    }
  }

  parsedParams["aw_0_1st.playerid"] = "PP_WEB";
  parsedParams["aw_0_1st.skey"] = timestamp.toString(10);
  parsedParams["aw_0_req.gdpr"] = "true";
  parsedParams["aw_0_req.userConsentV2"] = sourcePointConsents
    ? sourcePointConsents
    : "false";
  parsedParams.listenerId = uid;
  // We don't have "download" as an option in the web project, therefor all
  // playback is "streaming".
  parsedParams.aw_0_aisdelivery = "streaming";

  urlParts.search = stringify(parsedParams, { sort: false });

  return urlParts.toString();
};
