import { useEffect, useState } from "react";
import useSWR from "swr";
import { fetchImagePalette } from "helpers/fetchImagePalette";
import { getDominantImgixColor } from "helpers/getDominantImgixColor";
import { DEFAULT_COLOR as IMAGE_DEFAULT_COLOR } from "helpers/getDominantImgixColor";

const getImageColor = async (image: string) => {
  const palette = await fetchImagePalette(image);
  return getDominantImgixColor(palette);
};
export const DEFAULT_COLOR = "#666666";

/**
 * Returns color based on fetched value or *image default* color if no image.
 * If image is null the *player default* color will be returned.
 */
export const useColor = (image: string | null): string => {
  const { data: imageColor } = useSWR(
    // Don't fetch if falsy image (undefined or empty string).
    image ? [image, "imageColor"] : null,
    ([image]) => getImageColor(image)
  );

  const [color, setColor] = useState(imageColor || IMAGE_DEFAULT_COLOR);

  useEffect(() => {
    // Change to image default color if no image.
    if (!image) {
      setColor(IMAGE_DEFAULT_COLOR);
      return;
    }
    // Only change color when we got a new image color value. I.e. keep old
    // color until new is fetched.
    if (imageColor) {
      setColor(imageColor);
    }
  }, [image, imageColor]);

  return image !== null ? color : DEFAULT_COLOR;
};
