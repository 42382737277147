import { I18nKey } from "locales/constants/I18nKey";
import Link from "next/link";
import React, { ReactElement } from "react";
import { isPlayerLiveEpisode } from "components/Player/context/PlayerContextProvider";
import { usePlayer } from "components/Player/hooks/usePlayer";
import { PodcastImage } from "components/PodcastImage/PodcastImage";
import { buildPodcastUrl } from "helpers/url";
import { useI18n } from "hooks/useI18n";
import { useTypedRouter } from "hooks/useTypedRouter";
import styles from "./activeLiveEpisode.module.scss";

export const ActiveLiveEpisode = (): ReactElement | null => {
  const { episode } = usePlayer();
  const { locale } = useTypedRouter();
  const { i18n } = useI18n();

  if (!episode || !isPlayerLiveEpisode(episode)) {
    return null;
  }

  return (
    <section className={styles.container}>
      <div className={styles.heading}>{i18n(I18nKey.PLAYER_NOW_PLAYING)}</div>
      <div className={styles.itemsWrapper}>
        <Link
          legacyBehavior
          href={buildPodcastUrl(
            episode.podcast.id,
            episode.podcast.title,
            locale
          )}
        >
          <a>
            <PodcastImage
              className={styles.image}
              src={episode.podcast.image}
              alt={episode.podcast.title}
              width={59}
              height={59}
              key={episode.podcast.image}
            />
          </a>
        </Link>
        <div className={styles.textWrapper}>
          <div className={styles.onAir}>{i18n(I18nKey.PLAYER_LIVE_BADGE)}</div>
          <div className={styles.title}>{episode.title}</div>
        </div>
      </div>
    </section>
  );
};
