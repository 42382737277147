import * as Sentry from "@sentry/nextjs";
import { Schema, ZodError } from "zod";
import { IErrorResponse, ISuccessResponse } from "helpers/customFetch";
import { IPodplayApiErrorBody } from "interfaces/interfaces";

export const validateResponse = <R, S>(
  response: ISuccessResponse<R> | IErrorResponse<IPodplayApiErrorBody>,
  schema: Schema<S>
): ISuccessResponse<R> | IErrorResponse<IPodplayApiErrorBody> => {
  // Don't validate unsuccessfully response.
  if (!response.ok) {
    return response;
  }

  try {
    schema.parse(response.body);
  } catch (err) {
    if (process.env.NODE_ENV === "development") {
      // eslint-disable-next-line no-console
      console.log("Zod error:", { response, err });
    }

    // Tell Sentry that the response didn't match the schema.
    Sentry.withScope((scope) => {
      scope.setExtras({
        responseBody: response.body,
        responseUrl: response.url,
        // Add errors to extras - Sentry will truncate the error message leaving
        // out important details.
        zodErrors: err instanceof ZodError ? err.errors : undefined,
      });
      Sentry.captureException(err);
    });
  }

  return response;
};
