import { useEffect } from "react";

/**
 * Adds classes to the HTML body element. Classes are used in CSS to set various styles.
 * @param isExpanded True when the user has expanded the mini player into the maxi player.
 * @param isActive True when the player has episode data and not showing the skeleton.
 */
export const useAddBodyClass = (
  isExpanded: boolean,
  isActive: boolean
): void => {
  useEffect(() => {
    if (!isExpanded) {
      return;
    }

    document.body.classList.add("player-expanded");

    return () => {
      document.body.classList.remove("player-expanded");
    };
  }, [isExpanded]);

  useEffect(() => {
    if (!isActive) {
      return;
    }

    document.body.classList.add("player-active");

    return () => {
      document.body.classList.remove("player-active");
    };
  }, [isActive]);
};
