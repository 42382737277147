import { ImageLoaderProps } from "next/image";
import { addQueryParameters } from "helpers/addQueryParameters";

export const IMGIXLoader = ({ src, width, quality }: ImageLoaderProps) => {
  // NOTICE: Next.js "ImageLoaderProps" doesn't accept "height" as property.
  // Assume that all images should be aspect ratio 1:1 and set "h" to same as
  // "width" and "fit" to "crop". We need that to assure that some rare *non*
  // 1:1 podcast images doesn't break the site layout.
  const size = width.toString(10);
  const params: Record<string, string> = {
    auto: "format,compress",
    w: size,
    h: size,
    fit: "crop",
    q: quality ? quality.toString(10) : "75",
  };
  return addQueryParameters(
    `${process.env.NEXT_PUBLIC_IMGIX_BASE_URL}/${src}`,
    params
  );
};
