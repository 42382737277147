import { stringify } from "query-string";
import { z } from "zod";
import { Order } from "api/podplay/index";
import { podplayFetch } from "helpers/podplayFetch";
import { Language } from "interfaces/interfaces";

export const ZPEpisodeType = z.enum(["full", "bonus", "trailer"]);
export type TEpisodeType = z.infer<typeof ZPEpisodeType>;

export const ZEpisode = z.object({
  description: z.string(),
  duration: z.union([z.number(), z.null()]),
  encoded: z.string(),
  episode: z.union([z.number(), z.null()]),
  exclusive: z.boolean(),
  id: z.number(),
  mime_type: z.union([z.string(), z.null()]),
  podcast_id: z.number(),
  published: z.number(),
  season: z.union([z.number(), z.null()]),
  slug: z.string(),
  title: z.string(),
  type: ZPEpisodeType,
  url: z.string(),
});
export type TEpisode = z.infer<typeof ZEpisode>;

// -----------------------------------------------------------------------------
// GET EPISODE
// -----------------------------------------------------------------------------

export interface IGetEpisodeResponseBody {
  episode: TEpisode;
}

export const getEpisodeUrl = (
  language: Language,
  id: string | number
): string => `/${language}/episode/${id}`;

export const getEpisode = async (
  language: Language,
  id: string | number,
  requestInit?: RequestInit
) => {
  return podplayFetch<IGetEpisodeResponseBody>(
    getEpisodeUrl(language, id),
    requestInit
  );
};

// -----------------------------------------------------------------------------
// GET MULTIPLE EPISODES BY ID
// -----------------------------------------------------------------------------

export interface IGetMultipleEpisodesByIdResponseBody {
  results: TEpisode[];
}

export function getMultipleEpisodesByIdUrl(
  language: Language,
  options: { id: number[] | string[] }
): string {
  const encodedSearchParams = stringify(options);
  const search = encodedSearchParams ? `?${encodedSearchParams}` : "";
  return `/${language}/episode-by-id${search}`;
}

// -----------------------------------------------------------------------------
// GET EPISODES FOR PODCAST
// -----------------------------------------------------------------------------

export interface IGetEpisodesForPodcastResponseBody {
  results: TEpisode[];
  limit: number;
  offset: number;
  total: number;
}

export const getEpisodesForPodcastUrl = (
  language: Language,
  podcastId: string | number,
  limit = 20,
  offset = 0,
  order: Order = Order.DESC
): string => {
  const encodedSearchParams = stringify({
    order,
    limit,
    offset,
  });
  const search = encodedSearchParams ? `?${encodedSearchParams}` : "";
  return `/${language}/podcast/${podcastId}/episode${search}`;
};

export const getEpisodesForPodcast = async (
  language: Language,
  podcastId: string | number,
  limit = 20,
  offset = 0,
  order: Order = Order.DESC
) => {
  return podplayFetch<IGetEpisodesForPodcastResponseBody>(
    getEpisodesForPodcastUrl(language, podcastId, limit, offset, order)
  );
};

// -----------------------------------------------------------------------------
// GET EPISODES BY PODCAST IDS
// -----------------------------------------------------------------------------

export interface IGetEpisodesByPodcastIdsResponseBody {
  limit: number;
  total: number;
  results: TEpisode[];
}

export function getEpisodesByPodcastIdsUrl(
  language: Language,
  options: { p: number[]; limit: number }
): string {
  const encodedSearchParams = stringify(options);
  const search = encodedSearchParams ? `?${encodedSearchParams}` : "";
  return `/${language}/episodes${search}`;
}
