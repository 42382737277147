import classNames from "classnames";
import { I18nKey } from "locales/constants/I18nKey";
import React, {
  ChangeEvent,
  KeyboardEvent,
  ReactElement,
  SyntheticEvent,
  useCallback,
  useEffect,
  useState,
} from "react";
import { useAudioProgress } from "components/Player/hooks/useAudioProgress";
import { useLoadNextTrackFromQueue } from "components/Player/hooks/useLoadNextTrackFromQueue";
import { usePlayer } from "components/Player/hooks/usePlayer";
import { formatDurationAsTimeDatePeriod } from "helpers/time";
import { useI18n } from "hooks/useI18n";
import { BufferProgressBar } from "./components/BufferProgressBar/BufferProgressBar";
import { secondsToMMSS } from "./helpers/secondsToMMSS";
import styles from "./scrubber.module.scss";

export interface IProps {
  className?: string;
}

// NOTICE: Scrubber have internal state. Make sure to use "key" based on episode
// when using the component. That resets the state when episode changes. It also
// aborts potential dragging of the scrubber when episode changes automatically.

export const Scrubber = ({ className }: IProps): ReactElement => {
  const { seekTo, skip, queue } = usePlayer();
  const { currentTime, duration } = useAudioProgress();
  // The input range value should not be updated from the outside
  // when the user is interacting with the input.
  const [isUserInteracting, setIsUserInteracting] = useState(false);
  const [internalValue, setInternalValue] = useState(currentTime);
  const [valueBeforeSeek, setValueBeforeSeek] = useState(NaN);

  useEffect(() => {
    // Wait for new value after seek is triggered to avoid going back to
    // previous position before seek (async) is finished.
    if (!isUserInteracting && currentTime !== valueBeforeSeek) {
      setInternalValue(currentTime);
      setValueBeforeSeek(NaN);
    }
  }, [currentTime, isUserInteracting, valueBeforeSeek]);

  const handleOnInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const inputValue = Number(event.currentTarget.value);
    if (isUserInteracting) {
      setInternalValue(inputValue);
    } else {
      seekTo(inputValue);
      setValueBeforeSeek(currentTime);
    }
  };

  const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    // NOTICE: Don't seek if repeating key down. It will cause problem for the
    // SharpStream server without proper debouncing.

    if (event.key === "ArrowUp" || event.key === "ArrowRight") {
      event.preventDefault();
      if (!event.repeat) {
        skip(30);
      }
    } else if (event.key === "ArrowDown" || event.key === "ArrowLeft") {
      event.preventDefault();
      if (!event.repeat) {
        skip(-15);
      }
    }
  };

  // Note this will only fire when the input has
  // the css attribute touch-action: none;
  const handleOnPointerUp = (event: SyntheticEvent<HTMLInputElement>) => {
    const inputValue = Number(event.currentTarget.value);
    seekTo(inputValue);
    setValueBeforeSeek(currentTime);
    setIsUserInteracting(false);
  };

  const handleOnPointerDown = () => {
    setIsUserInteracting(true);
  };

  // Time button functions.
  const onTimePlayedClick = useCallback(() => {
    seekTo(0);
  }, [seekTo]);

  const loadNextTrackFromQueue = useLoadNextTrackFromQueue();

  const onTimeLeftClick = useCallback(async () => {
    if (queue.length) {
      await loadNextTrackFromQueue(true);
    } else {
      // Just seek to end if no queue.
      seekTo(duration);
    }
  }, [duration, loadNextTrackFromQueue, queue.length, seekTo]);

  const timeLeft = duration - internalValue;
  const percent = internalValue ? `${(internalValue / duration) * 100}%` : "0%";

  const { i18n } = useI18n();

  return (
    <div className={classNames(styles.container, className)}>
      <button
        type="button"
        className={styles.timeButton}
        onClick={onTimePlayedClick}
      >
        <span className="sr-only">
          {i18n(I18nKey.PLAYER_SCRUBBER_TIME_PLAYED)}{" "}
        </span>
        <time
          data-testid="player-maxi-timePlayed"
          className={styles.time}
          dateTime={formatDurationAsTimeDatePeriod(internalValue)}
        >
          {secondsToMMSS(internalValue)}
        </time>
      </button>
      <div className={styles.wrapper}>
        <div className={styles.progressTrack}>
          <BufferProgressBar />
          <div className={styles.thumb} style={{ left: percent }} />
          <div className={styles.progressBar} style={{ width: percent }} />
        </div>
        <label className="sr-only" htmlFor="player-scrubber">
          {i18n(I18nKey.PLAYER_SCRUBBER)}
        </label>

        <input
          id="player-scrubber"
          data-testid="player-maxi-scrubber"
          className={styles.range}
          type="range"
          min={0}
          max={duration}
          step={1}
          value={internalValue}
          onChange={handleOnInputChange}
          onPointerUp={handleOnPointerUp}
          onPointerDown={handleOnPointerDown}
          onKeyDown={handleKeyDown}
        />
      </div>
      <button
        type="button"
        className={styles.timeButton}
        onClick={onTimeLeftClick}
      >
        <span className="sr-only">
          {i18n(I18nKey.PLAYER_SCRUBBER_TIME_LEFT)}{" "}
        </span>
        <time
          data-testid="player-maxi-timeLeft"
          className={styles.time}
          dateTime={formatDurationAsTimeDatePeriod(timeLeft)}
        >
          {`-${secondsToMMSS(timeLeft)}`}
        </time>
      </button>
    </div>
  );
};
