import classNames from "classnames";
import { I18nKey } from "locales/constants/I18nKey";
import React, { ReactElement } from "react";
import { Chat } from "components/Chat/Chat";
import { useChat } from "components/Chat/context/chatContext";
import { usePlayer } from "components/Player/hooks/usePlayer";
import { useI18n } from "hooks/useI18n";
import { Maxi } from "./components/Maxi/Maxi";
import { Mini } from "./components/Mini/Mini";
import styles from "./player.module.scss";

export interface IProps {
  className?: string;
}

export const Player = ({ className }: IProps): ReactElement => {
  const { isExpanded } = usePlayer();
  const { i18n } = useI18n();
  const { active, image } = useChat();

  return (
    <section
      data-testid="player"
      className={classNames(className, styles.container)}
      aria-label={i18n(I18nKey.PLAYER_ARIA_LABEL)}
    >
      {active && !isExpanded && (
        <Chat channelUrl={active.payload.channel_url} image={image} />
      )}
      <section
        data-testid="player-maxi-wrapper"
        className={classNames(styles.maxiWrapper, {
          [styles.maxiWrapperExpanded]: isExpanded,
        })}
      >
        <Maxi />
      </section>
      <section
        data-testid="player-mini-wrapper"
        className={classNames(styles.miniWrapper, {
          [styles.miniWrapperExpanded]: isExpanded,
        })}
      >
        <Mini />
      </section>
    </section>
  );
};
