export function removeEmptyProperties(obj: object) {
  for (const prop in obj) {
    if (obj[prop] && typeof obj[prop] === "object") {
      removeEmptyProperties(obj[prop]);
    } else if (
      obj[prop] === undefined ||
      obj[prop] === null ||
      obj[prop] === ""
    ) {
      delete obj[prop];
    }
  }
  return obj;
}
