import classNames from "classnames";
import { I18nKey } from "locales/constants/I18nKey";
import React, { ReactElement } from "react";
import { ChatButton } from "components/ChatButton/ChatButton";
import { AllInclusiveIcon } from "components/Icons/AllInclusiveIcon";
import { ChatIcon } from "components/Icons/ChatIcon";
import { ShareIcon } from "components/Icons/ShareIcon";
import { isPlayerLiveEpisode } from "components/Player/context/PlayerContextProvider";
import { usePlayer } from "components/Player/hooks/usePlayer";
import { useI18n } from "hooks/useI18n";
import styles from "./bottomMenu.module.scss";

export const BottomLiveMenu = (): ReactElement | null => {
  const { episode, setAutoPlayNextTrack, autoPlayNextTrack } = usePlayer();
  const { i18n } = useI18n();

  if (!episode || !isPlayerLiveEpisode(episode)) {
    return null;
  }

  return (
    <div className={styles.container}>
      <button type="button" className={styles.button} disabled={true}>
        <ShareIcon size={16} />
        <span className={styles.text}>{i18n(I18nKey.PLAYER_BOTTOM_SHARE)}</span>
      </button>
      <ChatButton
        className={styles.button}
        disabled={!episode.chatEnabled}
        chatProvider={episode.chatProvider}
        podcast={episode.podcast}
      >
        <ChatIcon size={16} />
        <span className={styles.text}>{i18n(I18nKey.PLAYER_BOTTOM_CHAT)}</span>
      </ChatButton>
      <button
        type="button"
        className={classNames(styles.button, {
          [styles.buttonActive]: autoPlayNextTrack,
        })}
        onClick={() => setAutoPlayNextTrack(!autoPlayNextTrack)}
      >
        <AllInclusiveIcon size={16} />
        <span className={styles.text}>
          {i18n(I18nKey.PLAYER_BOTTOM_AUTOPLAY)}
        </span>
      </button>
    </div>
  );
};
