import { useEffect, useState } from "react";
import {
  TPlayerEpisode,
  TPlayerQueueItem,
} from "components/Player/context/PlayerContextProvider";
import {
  setPersistedEpisode,
  setPersistedQueue,
} from "components/Player/helpers/persist";

export const usePersistLastPlayed = (
  episode: TPlayerEpisode | undefined,
  episodeCurrentTime: number | undefined,
  queue: TPlayerQueueItem[]
): void => {
  // Block persist until we get an episode - Otherwise we can start to write to
  // storage with empty values before mounting hook has a chance to read the
  // first persisted value.
  const [blockPersist, setBlockPersist] = useState(!episode);
  useEffect(() => {
    if (blockPersist && episode) {
      setBlockPersist(false);
    }
  }, [blockPersist, episode]);

  // Persist episode.
  useEffect(() => {
    if (blockPersist) {
      return;
    }
    setPersistedEpisode(episode, episodeCurrentTime);
  }, [episode, episodeCurrentTime, blockPersist]);

  // Persist queue.
  useEffect(() => {
    if (blockPersist) {
      return;
    }
    setPersistedQueue(queue);
  }, [queue, blockPersist]);
};
