import React, {
  createContext,
  MutableRefObject,
  ReactNode,
  useCallback,
  useMemo,
  useState,
} from "react";
import { useHandleRouterChange } from "components/Modals/hooks/useHandleRouterChange";
import { ModalType } from "components/Modals/modalTypes";
import { buildHomepageUrl } from "helpers/url";
import { Locale } from "interfaces/interfaces";

export enum ModalOrigin {
  EPISODE = "EPISODE",
  PAGE = "PAGE",
  FOLLOW = "FOLLOW",
  LIVE = "LIVE",
  CHAT = "CHAT",
  FEED = "FEED",
}

export const getModalOriginFromUrl = (
  url: string,
  locale: Locale
): ModalOrigin | undefined => {
  switch (url) {
    case buildHomepageUrl(locale):
      return ModalOrigin.FEED;
    default:
      return undefined;
  }
};

export interface ICloseModalOptions {
  consumeCallback?: boolean;
}

interface IModalState {
  // NOTICE: Callback is "MutableRefObject" because many modals opens when the
  // user needs do something to access a feature. For example: the user needs
  // to log in to be able to follow a podcast. By forcing a ref object we are
  // sure that the callback function is called with the updated state
  // (e.g. logged in state) and not with the state that was present when the
  // modal was opened.
  callback?: MutableRefObject<() => Promise<unknown> | void> | undefined;
  origin?: ModalOrigin;
}

export interface IModalContext {
  openModal: (type: ModalType, state?: IModalState) => void;
  closeModal: (options?: ICloseModalOptions) => Promise<void>;
  modalState: IModalState | null;
  modalType: ModalType | null;
}

export const ModalContext = createContext<IModalContext | undefined>(undefined);

export const ModalContextProvider = (props: { children: ReactNode }) => {
  const [type, setType] = useState<ModalType | null>(null);
  const [state, setState] = useState<IModalState | null>(null);

  const open = useCallback<IModalContext["openModal"]>((type, state) => {
    setType(type);
    // NOTICE: Don't set state to null if no callback. We could want to
    // persist original state when navigating between modals.
    if (state) {
      setState(state);
    }
  }, []);

  const close = useCallback<IModalContext["closeModal"]>(
    async (props) => {
      setType(null);
      setState(null);
      if (props?.consumeCallback && state?.callback) {
        await state.callback.current();
      }
    },
    [state]
  );

  useHandleRouterChange(type, open, close);

  const contextValue = useMemo<IModalContext>(
    () => ({
      openModal: open,
      closeModal: close,
      modalState: state,
      modalType: type,
    }),
    [open, close, state, type]
  );

  return (
    <ModalContext.Provider value={contextValue}>
      {props.children}
    </ModalContext.Provider>
  );
};
