import classNames from "classnames";
import { I18nKey } from "locales/constants/I18nKey";
import React, { ReactElement } from "react";
import { ChevronIcon } from "components/Icons/ChevronIcon";
import { ActiveEpisode } from "components/Player/components/Maxi/components/ActiveEpisode/ActiveEpisode";
import { ActiveLiveEpisode } from "components/Player/components/Maxi/components/ActiveEpisode/ActiveLiveEpisode";
import { ErrorMessage } from "components/Player/components/common/ErrorMessage/ErrorMessage";
import { isPlayerNormalEpisode } from "components/Player/context/PlayerContextProvider";
import { usePlayer } from "components/Player/hooks/usePlayer";
import { useI18n } from "hooks/useI18n";
import { BottomLiveMenu } from "./components/BottomMenu/BottomLiveMenu";
import { BottomMenu } from "./components/BottomMenu/BottomMenu";
import { PlayerControls } from "./components/PlayerControls/PlayerControls";
import { Queue } from "./components/Queue/Queue";
import styles from "./maxi.module.scss";

export interface IProps {
  className?: string;
}

export const Maxi = ({ className }: IProps): ReactElement | null => {
  const { episode, setIsExpanded, queueCount, error, color } = usePlayer();
  const { i18n } = useI18n();

  if (!episode) {
    // NOTICE: Maxi should not be open if no episode. Logic above should show
    // the mini version with skeleton.
    return null;
  }

  // NOTICE: Using "key" on "Queue" component to reset scroll when new queue.
  const queueKey = `queue-${queueCount}`;
  return (
    <div
      className={styles.container}
      data-testid="player-maxi"
      style={{ background: color }}
    >
      <div className={styles.colorFade} />
      <div className={styles.border} />

      {isPlayerNormalEpisode(episode) ? (
        <ActiveEpisode />
      ) : (
        <ActiveLiveEpisode />
      )}

      <Queue key={queueKey} className={styles.queue} />
      <button
        type="button"
        data-testid="player-maxi-closeButton"
        className={styles.closeButton}
        onClick={() => setIsExpanded(false)}
        aria-label={i18n(I18nKey.PLAYER_COLLAPSE)}
      >
        <ChevronIcon size={24} />
      </button>
      {error ? (
        <ErrorMessage className={styles.errorMessage} showClose={false} />
      ) : (
        <section className={classNames(className, styles.playerWrapper)}>
          <PlayerControls />
          <hr className={styles.divider} />
          {isPlayerNormalEpisode(episode) ? <BottomMenu /> : <BottomLiveMenu />}
        </section>
      )}
    </div>
  );
};
