import { useState } from "react";
import { CloseIcon } from "components/Icons/CloseIcon";
import { UptimeStatusReport } from "pages/api/uptimeStatus";
import styles from "./status.module.scss";

export function UptimeStatusBar(props: { report: UptimeStatusReport }) {
  const [visible, setVisible] = useState(true);

  if (!visible) {
    return null;
  }

  // System statuses are in english.
  // No need to translate the labels.

  return (
    <div className={styles.status}>
      {props.report.status === "degraded" ? (
        <span className={styles.degraded}>Service Degraded: </span>
      ) : (
        <span className={styles.downtime}>Service Down: </span>
      )}
      <a
        className={styles.link}
        href={props.report.url}
        rel="noreferrer"
        target={"_blank"}
      >
        {props.report.title}
      </a>
      <button
        aria-label="Close"
        className={styles.closeButton}
        onClick={() => setVisible(false)}
      >
        <CloseIcon size={18} />
      </button>
    </div>
  );
}
