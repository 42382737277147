import dynamic from "next/dynamic";
import React, { ReactElement } from "react";

const MarketSelectorComponentDynamic = dynamic(
  () =>
    import(
      "components/marketSelectors/MarketSelector/MarketSelectorComponent"
    ).then((mod) => mod.MarketSelectorComponent) as never,
  { ssr: false }
);

export const MarketSelector = ({
  blockMarketSelector,
}): ReactElement | null => {
  if (blockMarketSelector) return null;
  return <MarketSelectorComponentDynamic />;
};
