import { Analytics } from "@vercel/analytics/react";
import { EpisodeProgressContextProvider } from "contexts/EpisodeProgressContextProvider";
import { SearchContextProvider } from "contexts/SearchContextProvider";
import { UserContextProvider } from "contexts/UserContextProvider/UserContextProvider";
import { GlobalProvider } from "contexts/globalContext";
import { AppProps } from "next/app";
import { useRouter } from "next/router";
import React, { ReactElement } from "react";
import { Toaster } from "sonner";
import { IGlobalResponse } from "api/prismic/global";
import { ChatProvider } from "components/Chat/context/chatContext";
import {
  I18nContextProvider,
  I18nDictionary,
} from "components/I18nContextProvider/I18nContextProvider";
import { Modals } from "components/Modals/Modals";
import { ModalContextProvider } from "components/Modals/context/ModalContextProvider";
import { Player } from "components/Player/Player";
import { PlayerContextProvider } from "components/Player/context/PlayerContextProvider";
import { UptimeStatusBar } from "components/UptimeStatusBar/UptimeStatusBar";
import { MarketSelector } from "components/marketSelectors/MarketSelector/MarketSelector";
import { fellix } from "helpers/fonts";
import { useAnalytics } from "hooks/useAnalytics";
import { useScrollLock } from "hooks/useScrollLock";
import { useSourcePoint, useTfcApi } from "hooks/useSourcePoint";
import { useTouchDetect } from "hooks/useTouchDetect";
import { useUptimeStatus } from "hooks/useUptimeStatus";
import { RootElementId } from "interfaces/interfaces";
import { IErrorProps } from "pages/_error";
import "styles/styles.scss";

export interface IAppProps {
  i18nDictionary: I18nDictionary;
  global: IGlobalResponse | null;
}

function MyApp({ Component, pageProps }: AppProps): ReactElement {
  const { i18nDictionary, global } = pageProps as IAppProps;
  useSourcePoint();
  useTfcApi();
  useAnalytics();
  useScrollLock(RootElementId.MODAL);
  useTouchDetect();
  const report = useUptimeStatus();

  // Check if error page.
  const { pathname } = useRouter();
  const { statusCode } = pageProps as IErrorProps;
  const isErrorPage =
    pathname === "/404" ||
    pathname === "/500" ||
    pathname === "/_error" ||
    statusCode >= 400;
  const isSonosPage = pathname.includes("/sonos");
  const isResetPasswordPage = pathname.includes("/reset-password");
  const showPlayer = !isErrorPage && !isSonosPage && !isResetPasswordPage;

  return (
    <>
      <style
        dangerouslySetInnerHTML={{
          __html: `
        html {
          font-family: ${fellix.style.fontFamily};
        }
      `,
        }}
      />

      <I18nContextProvider dictionary={i18nDictionary}>
        <GlobalProvider global={global || { i18n: [] }}>
          <UserContextProvider>
            <EpisodeProgressContextProvider>
              <SearchContextProvider>
                <PlayerContextProvider>
                  <ModalContextProvider>
                    <ChatProvider>
                      {report && <UptimeStatusBar report={report} />}
                      <MarketSelector
                        blockMarketSelector={isErrorPage || isSonosPage}
                      />
                      <Component {...pageProps} />
                      {process.env.NEXT_PUBLIC_VERCEL_ANALYTICS && (
                        <Analytics />
                      )}
                      {showPlayer && <Player />}
                      <Modals />
                      <Toaster
                        theme={"dark"}
                        position={"bottom-left"}
                        visibleToasts={1}
                        closeButton
                      />
                    </ChatProvider>
                  </ModalContextProvider>
                </PlayerContextProvider>
              </SearchContextProvider>
            </EpisodeProgressContextProvider>
          </UserContextProvider>
        </GlobalProvider>
      </I18nContextProvider>
    </>
  );
}

export default MyApp;
