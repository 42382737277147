import { I18nKey } from "locales/constants/I18nKey";
import { I18nDictionary } from "components/I18nContextProvider/I18nContextProvider";
import { replaceTemplate } from "./replaceTemplate";

export const getI18n = (
  dictionary: I18nDictionary,
  key: I18nKey,
  replace?: Record<string, string | undefined>
): string => {
  const value = dictionary[key];
  if (!value) {
    return key;
  }
  return replace ? replaceTemplate(value, replace) : value;
};
