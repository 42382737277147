import { I18nKey } from "locales/constants/I18nKey";
import Link from "next/link";
import React, { ReactElement, useMemo } from "react";
import { isPlayerNormalEpisode } from "components/Player/context/PlayerContextProvider";
import { usePlayer } from "components/Player/hooks/usePlayer";
import { PodcastImage } from "components/PodcastImage/PodcastImage";
import { buildEpisodeUrl, buildPodcastUrl } from "helpers/url";
import { useI18n } from "hooks/useI18n";
import { useTypedRouter } from "hooks/useTypedRouter";
import { Locale } from "interfaces/interfaces";
import styles from "./activeEpisode.module.scss";

const formatDate = (locale: Locale, date: Date): string => {
  return new Intl.DateTimeFormat(locale, {
    year: "numeric",
    month: "long",
    day: "numeric",
  }).format(date);
};

export const ActiveEpisode = (): ReactElement | null => {
  const { episode } = usePlayer();
  const { locale } = useTypedRouter();
  const { i18n } = useI18n();

  const date = useMemo(
    () =>
      episode && isPlayerNormalEpisode(episode)
        ? new Date(episode.published * 1000)
        : null,
    [episode]
  );
  const dateLabel = useMemo(
    () => (date ? formatDate(locale, date) : null),
    [date, locale]
  );

  if (!episode || !isPlayerNormalEpisode(episode)) {
    return null;
  }

  return (
    <section className={styles.container}>
      <div className={styles.heading}>{i18n(I18nKey.PLAYER_NOW_PLAYING)}</div>
      <div className={styles.itemsWrapper}>
        <Link
          legacyBehavior
          href={buildPodcastUrl(
            episode.podcast.id,
            episode.podcast.title,
            locale
          )}
        >
          <a>
            <PodcastImage
              className={styles.image}
              src={episode.podcast.image}
              alt={episode.podcast.title}
              width={59}
              height={59}
              key={episode.podcast.image}
            />
          </a>
        </Link>
        <Link
          legacyBehavior
          href={buildEpisodeUrl(episode.podcast, episode, locale)}
        >
          <a className={styles.textWrapper}>
            <div className={styles.title} data-testid="active-episode-title">
              {episode.title}
            </div>
            {date && dateLabel && (
              <div className={styles.date}>
                <time dateTime={date.toISOString()}>{dateLabel}</time>
              </div>
            )}
          </a>
        </Link>
      </div>
    </section>
  );
};
