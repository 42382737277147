const isHex = (hex: string): boolean => /^#(?:[0-9a-fA-F]{3}){1,2}$/.test(hex);

interface IRgb {
  // O-255
  r: number;
  // O-255
  g: number;
  // O-255
  b: number;
}

interface IHsl {
  // 0-360
  h: number;
  // 0-100
  s: number;
  // 0-100
  l: number;
}

/**
 * HEX to RGB. Hex should be format ##RRGGBB.
 */
export const hexToRgb = (hex: string): IRgb => {
  if (!isHex(hex)) {
    throw new Error(`Value is not hex: ${hex}`);
  }

  return {
    r: parseInt(hex.substring(1, 3), 16),
    g: parseInt(hex.substring(3, 5), 16),
    b: parseInt(hex.substring(5, 7), 16),
  };
};

/**
 * RGB to HSL
 */
export const rgbToHsl = (rgb: IRgb): IHsl => {
  // Make r, g, and b fractions of 1.
  const r = rgb.r / 255;
  const g = rgb.g / 255;
  const b = rgb.b / 255;

  // Find greatest and smallest channel values.
  const cMin = Math.min(r, g, b);
  const cMax = Math.max(r, g, b);
  const delta = cMax - cMin;
  let h = 0;
  let s = 0;
  let l = 0;

  // ---------------------------------------------------------------------------
  // Calculate hue
  // ---------------------------------------------------------------------------
  // No difference.
  if (delta === 0) {
    h = 0;
  }
  // Red is max.
  else if (cMax === r) {
    h = ((g - b) / delta) % 6;
  }
  // Green is max.
  else if (cMax == g) {
    h = (b - r) / delta + 2;
  }
  // Blue is max.
  else {
    h = (r - g) / delta + 4;
  }

  // Multiply to get degree value.
  h = Math.round(h * 60);

  // Make negative hues positive behind 360°.
  if (h < 0) h += 360;

  // ---------------------------------------------------------------------------
  // Calculate lightness
  // ---------------------------------------------------------------------------
  l = (cMax + cMin) / 2;

  // ---------------------------------------------------------------------------
  // Calculate saturation
  // ---------------------------------------------------------------------------
  s = delta == 0 ? 0 : delta / (1 - Math.abs(2 * l - 1));

  // ---------------------------------------------------------------------------
  // Return
  // ---------------------------------------------------------------------------
  return { h, s: s * 100, l: l * 100 };
};

/**
 * Internal helper function.
 */
const hueToRgb = (p: number, q: number, t: number): number => {
  if (t < 0) t += 1;
  if (t > 1) t -= 1;
  if (t < 1 / 6) return p + (q - p) * 6 * t;
  if (t < 1 / 2) return q;
  if (t < 2 / 3) return p + (q - p) * (2 / 3 - t) * 6;
  return p;
};

/**
 * HSL to RGB
 */
export const hslToRgb = (hsl: IHsl): IRgb => {
  const h = hsl.h / 360;
  const s = hsl.s / 100;
  const l = hsl.l / 100;

  // Achromatic.
  if (s == 0) {
    return {
      r: Math.round(l * 255),
      g: Math.round(l * 255),
      b: Math.round(l * 255),
    };
  }

  const q = l < 0.5 ? l * (1 + s) : l + s - l * s;
  const p = 2 * l - q;

  return {
    r: Math.round(hueToRgb(p, q, h + 1 / 3) * 255),
    g: Math.round(hueToRgb(p, q, h) * 255),
    b: Math.round(hueToRgb(p, q, h - 1 / 3) * 255),
  };
};

/**
 * RGB to HEX
 */
export const rgbToHex = (rgb: IRgb): string => {
  let r = rgb.r.toString(16);
  let g = rgb.g.toString(16);
  let b = rgb.b.toString(16);

  if (r.length === 1) r = "0" + r;
  if (g.length === 1) g = "0" + g;
  if (b.length === 1) b = "0" + b;

  return `#${r}${g}${b}`;
};
