import { useEffect } from "react";

/**
 * Update audio playback rate if it changes.
 */
export const useUpdatePlaybackRate = (
  audio: HTMLAudioElement | undefined,
  playbackRate: number
): void => {
  useEffect(() => {
    if (!audio) {
      return;
    }

    audio.playbackRate = playbackRate;
  }, [audio, playbackRate]);
};
