import { useEffect } from "react";
import { lockScroll, unlockScroll } from "helpers/preventScrollOnAppRoot";

/**
 * The site should be locked when the mobile version of the player is expanded.
 */
export const useMobileScrollLock = (active: boolean): void => {
  useEffect(() => {
    if (!active) {
      return;
    }

    const mediaQuery = window.matchMedia("(min-width: 768px)");
    let lockedScrollPosition: number | null = null;

    const mediaQueryHandler = (
      e: MediaQueryListEvent | MediaQueryList
    ): void => {
      if (e.matches) {
        // Desktop size - Unlock if locked.
        if (lockedScrollPosition !== null) {
          unlockScroll(lockedScrollPosition);
          lockedScrollPosition = null;
        }
      } else {
        // Mobile size - Lock if unlocked.
        if (lockedScrollPosition === null) {
          lockedScrollPosition = lockScroll();
        }
      }
    };

    mediaQuery.addEventListener("change", mediaQueryHandler);

    mediaQueryHandler(mediaQuery);

    return () => {
      mediaQuery.removeEventListener("change", mediaQueryHandler);
      if (lockedScrollPosition !== null) {
        unlockScroll(lockedScrollPosition);
      }
    };
  }, [active]);
};
