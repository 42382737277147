import { IPodplayUser } from "contexts/UserContextProvider/UserContextProvider";
import { TUserInfo } from "api/podplay/user";
import { convertTimeStampToBirthyear } from "helpers/convertBirthyear";
import { Language } from "interfaces/interfaces";

export const createPodplayUserFromUserInfo = (
  userInfo: TUserInfo
): IPodplayUser => {
  return {
    profile_name: userInfo.profile_name,
    email: userInfo.email,
    birthyear:
      userInfo.birthdate === null
        ? null
        : convertTimeStampToBirthyear(userInfo.birthdate),
    language_iso: userInfo.language_iso as Language,
    gender: userInfo.gender,
    user_id: userInfo.user_id,
  };
};
