export const chunkArray = <T>(array: T[], chunkSize: number): T[][] => {
  return array.reduce(
    (resultArray, item, index) => {
      const chunkIndex = Math.floor(index / chunkSize);

      if (!resultArray[chunkIndex]) {
        resultArray[chunkIndex] = [];
      }

      resultArray[chunkIndex].push(item);

      return resultArray;
    },
    [[]] as T[][]
  );
};
