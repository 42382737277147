import {
  MutableRefObject,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import {
  TPlayerEpisode,
  isPlayerLiveEpisode,
  isPlayerNormalEpisode,
} from "components/Player/context/PlayerContextProvider";
import { AudioUndefinedError } from "components/Player/helpers/AudioUndefinedError";
import { EpisodeUndefinedError } from "components/Player/helpers/EpisodeUndefinedError";
import { getDuration } from "components/Player/helpers/getDuration";
import {
  clearMediaSession,
  clearMediaSessionPosition,
  initiateMediaSession,
  updateMediaSessionPosition,
} from "components/Player/helpers/mediaSession";
import { useStopLive } from "components/Player/hooks/useStopLive";
import { isPositiveRealNumber } from "helpers/isPositiveRealNumber";
import { useAdjustPositionAfterStart } from "./useAdjustPositionAfterStart";
import { useAudioCallbacks } from "./useAudioCallbacks";
import { useBeforeBrowserUnload } from "./useBeforeBrowserUnload";
import { useForceUpdate } from "./useForceUpdate";
import { useIsBuffering } from "./useIsBuffering";
import { useUnmount } from "./useUnmount";
import { useUpdateMediaSessionPosition } from "./useUpdateMediaSessionPosition";
import { useUpdatePlaybackRate } from "./useUpdatePlaybackRate";

export interface IProps {
  readonly onEnded?: (episode: TPlayerEpisode, audio: HTMLAudioElement) => void;
  readonly onLoad?: (episode: TPlayerEpisode, audio: HTMLAudioElement) => void;
  readonly onPause?: (episode: TPlayerEpisode, audio: HTMLAudioElement) => void;
  readonly onPlay?: (episode: TPlayerEpisode, audio: HTMLAudioElement) => void;
  readonly onBeforeSeek?: () => void;
  readonly onAfterSeek?: () => void;
  readonly onSkipBackward?: (
    episode: TPlayerEpisode,
    targetTimeSec: number
  ) => void;
  readonly onSkipForward?: (
    episode: TPlayerEpisode,
    targetTimeSec: number
  ) => void;
  readonly onStart?: (episode: TPlayerEpisode, audio: HTMLAudioElement) => void;
  readonly playbackRate: number;
  readonly onUnload?: (
    episode: TPlayerEpisode,
    audio: HTMLAudioElement,
    saveProgress?: boolean
  ) => void;
  readonly nextTrackRef?: MutableRefObject<() => Promise<void>>;
}

// Some episodes from the api does not have duration. Use fallback (60 min)
// until real duration is available from audio element. This is needed because
// we can't wait to call MediaSession when starting a new episode. If we wait
// the old episode time is used until we call MediaSession later on.
export const approximatedDurationFallback = 60 * 60;

export interface IUseAudioData {
  buffered?: TimeRanges;
  currentTime: number;
  duration: number;
  episode: TPlayerEpisode | undefined;
  error: unknown;
  forceUpdateCount: number;
  isBuffering: boolean;
  load: (episode: TPlayerEpisode) => Promise<void>;
  pause: () => void;
  paused: boolean;
  play: () => Promise<void>;
  restartFromCurrentTime: () => Promise<void>;
  seekTo: (seconds: number) => void;
  skip: (seconds: number) => void;
  unload: (saveProgress?: boolean) => void;
  desiredStartTime: number;
  isAtEnd: boolean;
  _audio: HTMLAudioElement | undefined;
}

interface IUseAudio {
  (props: IProps): IUseAudioData;
}

export const useAudio: IUseAudio = ({
  onAfterSeek,
  onBeforeSeek,
  onEnded,
  onLoad,
  onPause,
  onPlay,
  onSkipBackward,
  onSkipForward,
  onStart,
  onUnload,
  playbackRate,
  nextTrackRef,
}) => {
  // Create audio element once and reuse when playing different episodes.
  const [audio] = useState<HTMLAudioElement | undefined>(() => {
    if (typeof window === "undefined") {
      // Server side can't create audio - Return undefined.
      return;
    }

    try {
      const audio = new Audio();
      audio.preload = "none";
      return audio;
    } catch (err) {
      // Some browsers (like crawlers) can't create audio elements.
      // TODO: Should we do something with the error or is it too much of a
      //  corner case?
    }
  });

  // NOTICE: Use useRef instead of useState to make changes occur immediately.
  // This is important because audio event could otherwise be fired before
  // useState has been run.

  const episodeRef = useRef<TPlayerEpisode>();
  // It is not possible to set the currentTime of an audio element
  // until it has been started at least once. This value will keep
  // track of the desired currentTime until it can be set.
  const desiredStartTimeRef = useRef(0);
  const isOnStartCalledRef = useRef(false);

  // ---------------------------------------------------------------------------
  // Seek to.
  // ---------------------------------------------------------------------------
  const seekTo = useCallback(
    (time: number) => {
      const episode = episodeRef.current;

      if (!audio) {
        throw new AudioUndefinedError();
      }

      if (!episode) {
        throw new EpisodeUndefinedError();
      }

      // Duration with fallbacks.
      // NOTICE: Media server can send back non number value as duration. Type
      // as "unknown" to have some type safety here.
      const duration = isPositiveRealNumber(audio.duration as unknown)
        ? audio.duration
        : episode.approximatedDuration || approximatedDurationFallback;
      // Make sure duration is at least the same as time.
      const safeDuration = Math.max(time, duration);

      // It's safe to change the currentTime when metadata is loaded.
      if (audio.readyState >= audio.HAVE_METADATA) {
        onBeforeSeek?.();
        audio.currentTime = time;
        if (isPlayerNormalEpisode(episode)) {
          updateMediaSessionPosition(time, safeDuration, audio.playbackRate);
        }

        onAfterSeek?.();
      } else {
        desiredStartTimeRef.current = time;
        if (isPlayerNormalEpisode(episode)) {
          updateMediaSessionPosition(time, safeDuration, audio.playbackRate);
        }
      }
    },
    [audio, onAfterSeek, onBeforeSeek]
  );

  // ---------------------------------------------------------------------------
  // Get current time.
  // ---------------------------------------------------------------------------
  const getCurrentTime = useCallback((): number => {
    if (!audio) {
      return 0;
    }

    // We can return currentTime directly when metadata has loaded. Before that
    // the currentTime could not be set.
    return audio.readyState >= audio.HAVE_METADATA
      ? audio.currentTime
      : desiredStartTimeRef.current;
  }, [audio]);

  // ---------------------------------------------------------------------------
  // Skip.
  // ---------------------------------------------------------------------------
  const skip = useCallback(
    async (seconds: number) => {
      const episode = episodeRef.current;

      if (!audio) {
        throw new AudioUndefinedError();
      }

      if (!episode) {
        throw new EpisodeUndefinedError();
      }

      const targetTimeSec = Math.max(getCurrentTime() + seconds, 0);
      seekTo(targetTimeSec);

      if (seconds < 0) {
        onSkipBackward?.(episode, targetTimeSec);
      }

      if (seconds > 0) {
        onSkipForward?.(episode, targetTimeSec);
      }
    },
    [audio, getCurrentTime, onSkipBackward, onSkipForward, seekTo]
  );

  // ---------------------------------------------------------------------------
  // Pause.
  // ---------------------------------------------------------------------------
  const pause = useCallback(() => {
    if (!audio) {
      throw new AudioUndefinedError();
    }

    audio.pause();
  }, [audio]);

  // ---------------------------------------------------------------------------
  // Play.
  // ---------------------------------------------------------------------------
  const play = useCallback(async () => {
    const episode = episodeRef.current;

    if (!audio) {
      throw new AudioUndefinedError();
    }

    if (!episode) {
      throw new EpisodeUndefinedError();
    }

    if (isPlayerLiveEpisode(episode)) {
      audio.src = episode.url;
      audio.load();
    }

    if (!isOnStartCalledRef.current) {
      onStart?.(episode, audio);
      isOnStartCalledRef.current = true;
    }

    // NOTICE: Start from beginning if we have reached the end. There is a
    // problem with play if currentTime is at the very end. This will make sure
    // the audio restarts from the beginning.
    if (!isNaN(audio.duration) && audio.currentTime === audio.duration) {
      audio.currentTime = 0;
    }

    await audio.play().catch(() => {
      // NOTICE: Play will throw if interrupted by pause.
    });
  }, [audio, onStart]);

  // ---------------------------------------------------------------------------
  // Ref functions - Used in MediaSession.
  // ---------------------------------------------------------------------------
  const playRef = useRef(play);
  const pauseRef = useRef(pause);
  const seekToRef = useRef(seekTo);
  const skipRef = useRef(skip);

  useEffect(() => {
    playRef.current = play;
    pauseRef.current = pause;
    skipRef.current = skip;
    seekToRef.current = seekTo;
  });

  // ---------------------------------------------------------------------------
  // Load.
  // ---------------------------------------------------------------------------
  const load = useCallback(
    async (
      newEpisode: TPlayerEpisode,
      savePreviousEpisodeProgressOnUnload?: boolean
    ): Promise<void> => {
      const episode = episodeRef.current;

      if (!audio) {
        throw new AudioUndefinedError();
      }

      // Tell that old episode is getting unloaded.
      if (episode) {
        onUnload?.(episode, audio, savePreviousEpisodeProgressOnUnload);
      }

      // Update audio element.
      audio.src = newEpisode.url;
      audio.title = `${newEpisode.title} - ${newEpisode.podcast.title}`;

      // Reset state.
      episodeRef.current = newEpisode;
      isOnStartCalledRef.current = false;
      desiredStartTimeRef.current = newEpisode.startTime;

      // Load - This will reset the media element to its initial state.
      // NOTICE: Only use this method if we have an old episode. This will
      // otherwise trigger unwanted preloading of audio when just mounting an
      // episode. I.e. when returning back to Podplay and the
      // "resume last played" functionality triggers.
      if (episode) {
        audio.load();
      }

      // Episode type.
      const isNormalEpisode = isPlayerNormalEpisode(newEpisode);

      // NOTICE: Playback rate will automatically be reset to 1 when changing
      // the "src" / calling "load". Update to desired playback rate.
      audio.playbackRate = isNormalEpisode ? playbackRate : 1;

      // Media session.
      initiateMediaSession({
        title: newEpisode.title,
        artist: newEpisode.podcast.title,
        image: newEpisode.podcast.image,
        play: playRef,
        pause: pauseRef,
        skip: isNormalEpisode ? skipRef : undefined,
        seekTo: isNormalEpisode ? seekToRef : undefined,
        nextTrack: nextTrackRef,
      });
      if (isPlayerNormalEpisode(newEpisode)) {
        // NOTICE: Duration is not available on audio element yet. Use
        // approximated duration from the api for now and update later when
        // metadata is loaded.
        const duration =
          newEpisode.approximatedDuration || approximatedDurationFallback;
        // Make sure duration is at least the same as time.
        const safeDuration = Math.max(duration, newEpisode.startTime);

        updateMediaSessionPosition(
          newEpisode.startTime,
          safeDuration,
          playbackRate
        );
      } else {
        // NOTICE: We can't set "duration" to "infinity" even though the
        // documentation state it, see https://developer.mozilla.org/en-US/docs/Web/API/MediaSession/setPositionState
        // Clear media session position instead.
        clearMediaSessionPosition();
      }

      // Tell that new episode is loaded.
      onLoad?.(newEpisode, audio);

      // Auto play.
      if (newEpisode.autoPlay) {
        onStart?.(newEpisode, audio);
        isOnStartCalledRef.current = true;

        await audio.play().catch(() => {
          // NOTICE: Play will throw if interrupted by pause.
        });
      }
    },
    [audio, nextTrackRef, onLoad, onStart, onUnload, playbackRate]
  );

  // ---------------------------------------------------------------------------
  // Unload.
  // ---------------------------------------------------------------------------
  const unload = useCallback(
    (saveProgress?: boolean) => {
      const episode = episodeRef.current;

      if (!audio) {
        throw new AudioUndefinedError();
      }

      if (!episode) {
        throw new EpisodeUndefinedError();
      }

      onUnload?.(episode, audio, saveProgress);

      if (!audio.paused && !audio.error) {
        audio.pause();
      }

      audio.removeAttribute("src");
      audio.removeAttribute("title");
      audio.load();

      episodeRef.current = undefined;

      clearMediaSession();
    },
    [audio, onUnload]
  );

  // ---------------------------------------------------------------------------
  // Restart from current time - Used when restarting from error.
  // ---------------------------------------------------------------------------
  const restartFromCurrentTime = useCallback(async () => {
    const episode = episodeRef.current;

    if (!audio) {
      throw new AudioUndefinedError();
    }

    if (!episode) {
      throw new EpisodeUndefinedError();
    }

    if (isPlayerNormalEpisode(episode)) {
      await load({
        ...episode,
        autoPlay: true,
        startTime: getCurrentTime(),
      });
    } else {
      await load({
        ...episode,
      });
    }
  }, [audio, getCurrentTime, load]);

  // ---------------------------------------------------------------------------
  // Various.
  // ---------------------------------------------------------------------------
  useAdjustPositionAfterStart(audio, desiredStartTimeRef);
  useAudioCallbacks(audio, episodeRef, onPause, onPlay, onEnded);
  useBeforeBrowserUnload(audio, episodeRef, onUnload);
  useUnmount(audio, episodeRef, onUnload);
  useUpdatePlaybackRate(audio, playbackRate);
  useUpdateMediaSessionPosition(audio, episodeRef, desiredStartTimeRef);
  useStopLive(audio, episodeRef);

  // NOTICE: Value will update when audio change and force this hook to
  // rerender. Do not remove.
  const forceUpdateCount = useForceUpdate(audio);

  const isBuffering = useIsBuffering(audio);

  // ---------------------------------------------------------------------------
  // Return.
  // ---------------------------------------------------------------------------
  const currentTime = getCurrentTime();
  const duration = getDuration(episodeRef.current, audio);
  return {
    buffered: audio?.buffered,
    currentTime,
    duration,
    episode: episodeRef.current,
    error: audio?.error || null,
    forceUpdateCount,
    isBuffering,
    load,
    pause,
    paused: audio ? audio.paused : true,
    play,
    restartFromCurrentTime,
    seekTo,
    skip,
    unload,
    _audio: audio,
    desiredStartTime: desiredStartTimeRef.current,
    isAtEnd: currentTime === duration,
  };
};
