import { MutableRefObject, useEffect } from "react";
import {
  TPlayerEpisode,
  isPlayerLiveEpisode,
} from "components/Player/context/PlayerContextProvider";
import { updateMediaSessionPosition } from "components/Player/helpers/mediaSession";
import { approximatedDurationFallback } from "components/Player/hooks/useAudio";
import { isPositiveRealNumber } from "helpers/isPositiveRealNumber";

const getProps = (
  audio: HTMLAudioElement,
  desiredStartTime: number,
  episode: TPlayerEpisode
): { position: number; duration: number; playbackRate: number } => {
  // Only use the real audio currentTime when it's possible to set currentTime.
  // Until then the desiredStartTime is used as the display value by the player.
  const position =
    audio.readyState >= audio.HAVE_METADATA
      ? audio.currentTime
      : desiredStartTime;

  // NOTICE: Media server can send back non number value as duration. Type
  // as "unknown" to have some type safety here.
  const duration = isPositiveRealNumber(audio.duration as unknown)
    ? audio.duration
    : episode.approximatedDuration || approximatedDurationFallback;

  // Make sure duration is at least the same as time.
  const safeDuration = Math.max(position, duration);

  // Playback rate should be safe to use directly.
  const playbackRate = audio.playbackRate;

  // Return.
  return { position, duration: safeDuration, playbackRate };
};

/**
 * Updates the media session position if duration or playback rate changes.
 */
export const useUpdateMediaSessionPosition = (
  audio: HTMLAudioElement | undefined,
  episodeRef: MutableRefObject<TPlayerEpisode | undefined>,
  desiredStartTimeRef: MutableRefObject<number>
) => {
  useEffect(() => {
    if (!audio) {
      return;
    }

    const handler = () => {
      const episode = episodeRef.current;
      const desiredStartTime = desiredStartTimeRef.current;

      if (!episode) {
        return;
      }

      // Don't update position if live episode. Duration "Infinity" can't be used.
      if (isPlayerLiveEpisode(episode)) {
        return;
      }

      const { position, duration, playbackRate } = getProps(
        audio,
        desiredStartTime,
        episode
      );
      updateMediaSessionPosition(position, duration, playbackRate);
    };

    audio.addEventListener("ratechange", handler);
    audio.addEventListener("durationchange", handler);

    return () => {
      audio.removeEventListener("ratechange", handler);
      audio.removeEventListener("durationchange", handler);
    };
  }, [audio, desiredStartTimeRef, episodeRef]);
};
