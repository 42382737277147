import classNames from "classnames";
import { useUser } from "contexts/UserContextProvider/UserContextProvider";
import { I18nKey } from "locales/constants/I18nKey";
import React, { ReactElement, useCallback } from "react";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { Item } from "components/Player/components/Maxi/components/Queue/components/Item/Item";
import { TPlayerQueueItem } from "components/Player/context/PlayerContextProvider";
import { createPlayerNormalEpisode } from "components/Player/helpers/createPlayerNormalEpisode";
import { usePlayer } from "components/Player/hooks/usePlayer";
import { getBrowserStorageItem, StorageItem } from "helpers/browserStorage";
import { useI18n } from "hooks/useI18n";
import styles from "./queue.module.scss";

export interface IProps {
  className?: string;
}

export const Queue = ({ className }: IProps): ReactElement => {
  const { load, queue, removeFromQueue, queueEpisodeProgress } = usePlayer();
  const { i18n } = useI18n();
  const { user, uid } = useUser();

  const loadItemFromQueue = useCallback(
    async (item: TPlayerQueueItem) => {
      if (!uid || user === undefined) {
        return;
      }

      removeFromQueue(item.episode.id);

      await load(
        createPlayerNormalEpisode({
          episode: item.episode,
          podcast: item.podcast,
          episodeProgress: queueEpisodeProgress[item.episode.id],
          autoPlay: true,
          uid,
          user,
          sourcePointConsents: getBrowserStorageItem(
            StorageItem.CONSENTS_SOURCE_POINT
          ),
        })
      );
    },
    [load, queueEpisodeProgress, removeFromQueue, uid, user]
  );

  return (
    <div className={classNames(className, styles.container)}>
      <div className={styles.heading}>
        {i18n(I18nKey.PLAYER_QUEUE_NEXT_HEADING)}
      </div>
      <ul className={styles.list}>
        <TransitionGroup component={null}>
          {queue.map((item) => {
            // NOTICE: React Transition Group uses key to identify items. When
            // an item changes position in the list we use the "moveCount" to
            // trick RTG to trigger the "exit" animation for the old position
            // and the "enter" animation for the new position.
            const transitionKey = `${item.episode.id}-${item.moveCount}`;
            return (
              <CSSTransition
                key={transitionKey}
                timeout={2000}
                classNames={{
                  enter: styles.itemEnter,
                  enterActive: styles.itemEnterActive,
                  exitActive: styles.itemExitActive,
                }}
              >
                <li className={styles.item}>
                  <Item
                    item={item}
                    progress={queueEpisodeProgress[item.episode.id]}
                    onClick={async () => {
                      await loadItemFromQueue(item);
                    }}
                  />
                </li>
              </CSSTransition>
            );
          })}
        </TransitionGroup>
      </ul>
      {!queue.length && (
        <div className={styles.noQueueWrapper}>
          <p className={styles.noQueue}>{i18n(I18nKey.PLAYER_QUEUE_EMPTY)}</p>
        </div>
      )}
    </div>
  );
};
