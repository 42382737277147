import dynamic from "next/dynamic";
import { IProps } from "components/ContextMenu/DynamicContextMenu";

export enum CalculatedAlign {
  TOP = "top",
  RIGHT = "right",
  BOTTOM = "bottom",
  LEFT = "left",
}

export enum DesktopAlign {
  VERTICAL = "vertical",
  HORIZONTAL = "horizontal",
}

export const DynamicContextMenu = dynamic<IProps>(
  () => import("./DynamicContextMenu").then((mod) => mod.DynamicContextMenu),
  {
    ssr: false,
  }
);

export const ContextMenu = (props: IProps) => {
  return <DynamicContextMenu {...props} />;
};
