import { RichTextField } from "@prismicio/types";
import React, { createContext, useContext } from "react";

interface IGlobalValue {
  text: string;
  html?: RichTextField;
}

export interface IGlobal {
  i18n: {
    exclusive_page_header: IGlobalValue;
    exclusive_page_description: IGlobalValue;
    exclusive_follow_header: IGlobalValue;
    exclusive_follow_description: IGlobalValue;
    exclusive_listen_header: IGlobalValue;
    exclusive_listen_description: IGlobalValue;
    exclusive_listen_success_header: IGlobalValue;
    exclusive_listen_success_description: IGlobalValue;
    exclusive_listen_success_button: IGlobalValue;
    exclusive_page_success_header: IGlobalValue;
    exclusive_page_success_description: IGlobalValue;
    exclusive_page_success_button: IGlobalValue;
    exclusive_follow_success_header: IGlobalValue;
    exclusive_follow_success_description: IGlobalValue;
    exclusive_follow_success_button: IGlobalValue;
    exclusive_generic_success_header: IGlobalValue;
    exclusive_generic_success_description: IGlobalValue;
    exclusive_generic_success_button: IGlobalValue;
    modalAuthError_heading: IGlobalValue;
    modalAuthError_description: IGlobalValue;
    forgotPassword_heading: IGlobalValue;
    forgotPassword_description: IGlobalValue;
    forgotPassword_successMessage: IGlobalValue;
    modalLogin_button: IGlobalValue;
    generic_email: IGlobalValue;
    generic_or: IGlobalValue;
    generic_send: IGlobalValue;
    generic_login: IGlobalValue;
    generic_ok: IGlobalValue;
    generic_required: IGlobalValue;
    generic_emailIsInvalid: IGlobalValue;
    generic_close: IGlobalValue;
    login_heading: IGlobalValue;
    generic_passwordTooShort: IGlobalValue;
    generic_profileName: IGlobalValue;
    genric_profileNameDescription: IGlobalValue;
    generic_password: IGlobalValue;
    generic_forgotPassword: IGlobalValue;
    generic_createAccount: IGlobalValue;
    generic_save: IGlobalValue;
    resetPassword_heading: IGlobalValue;
    resetPassword_newPassword: IGlobalValue;
    generic_birthyear: IGlobalValue;
    generic_gender: IGlobalValue;
    generic_male: IGlobalValue;
    generic_female: IGlobalValue;
    generic_otherGender: IGlobalValue;
    signup_invalidYearFormat: IGlobalValue;
    signup_birthyearTooYoung: IGlobalValue;
    signup_genderPlaceholder: IGlobalValue;
    signup_consents: IGlobalValue;
    generic_error: IGlobalValue;
    error_profileNameAlreadyExists: IGlobalValue;
    error_passwordMissmatch: IGlobalValue;
    error_invalidProfileName: IGlobalValue;
    profileName_heading: IGlobalValue;
    profileName_description: IGlobalValue;
    exclusive_chat_header: IGlobalValue;
    exclusive_chat_description: IGlobalValue;
    follow_onboarding_heading: IGlobalValue;
    follow_onboarding_description: IGlobalValue;
    follow_onboarding_explore_link: IGlobalValue;
    follow_onboarding_submit_button: IGlobalValue;
  };
}

const GlobalContext = createContext<IGlobal | undefined>(undefined);

export function GlobalProvider({ global, children }) {
  // Create a dictionary object.
  const dictionary = global?.i18n.reduce((memo, item) => {
    return {
      ...memo,
      [item.key]: { text: item.value, html: item.html },
    };
  }, {});

  // Default to key if undefined.
  const i18n = dictionary
    ? new Proxy(dictionary, {
        get(target, name) {
          if (typeof target[name] === "undefined") {
            return { text: name };
          }
          return target[name];
        },
      })
    : dictionary;

  return (
    <GlobalContext.Provider value={{ i18n }}>{children}</GlobalContext.Provider>
  );
}

export function useGlobal(): IGlobal {
  const context = useContext<IGlobal | undefined>(GlobalContext);
  if (!context) {
    throw new Error("Can't find Global context provider.");
  }
  return context;
}
