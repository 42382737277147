import { useRouter } from "next/router";
import { useEffect, useRef } from "react";
import { IModalContext } from "components/Modals/context/ModalContextProvider";
import { isModalType } from "components/Modals/modalTypes";

/**
 * Opens and closes modals depending on url hash.
 */
export const useHandleRouterChange = (
  modalType: IModalContext["modalType"],
  openModal: IModalContext["openModal"],
  closeModal: IModalContext["closeModal"]
) => {
  const { events: routerEvents } = useRouter();

  // Open modal on initialization if hash is modal and (another) modal is *NOT*
  // open yet.
  const initialModalChecked = useRef(false);
  useEffect(() => {
    if (initialModalChecked.current) {
      return;
    }
    initialModalChecked.current = true;

    // Don't proceed if a modal already is open.
    if (modalType) {
      return;
    }

    const hash = `${window.location.hash}`;
    if (isModalType(hash)) {
      openModal(hash);
    }
  }, [modalType, openModal]);

  // Open modal if hash changes to modal hash. This could happen if user clicks
  // on a link that contains a modal hash.
  useEffect(() => {
    const handleChange = () => {
      const hash = `${window.location.hash}`;
      if (isModalType(hash)) {
        openModal(hash);
      }
    };

    routerEvents.on("hashChangeComplete", handleChange);
    routerEvents.on("routeChangeComplete", handleChange);

    return () => {
      routerEvents.off("hashChangeComplete", handleChange);
      routerEvents.off("routeChangeComplete", handleChange);
    };
  }, [openModal, routerEvents]);

  // Close modal when router change starts.
  useEffect(() => {
    const handleChange = async () => {
      await closeModal();
    };
    routerEvents.on("hashChangeStart", handleChange);
    routerEvents.on("routeChangeStart", handleChange);
    return () => {
      routerEvents.off("hashChangeStart", handleChange);
      routerEvents.off("routeChangeStart", handleChange);
    };
  }, [closeModal, routerEvents]);
};
