import Cookies from "js-cookie";

/**
 * This functions sets or deletes the "isLoggedIn" cookie. It's used on the
 * server side (e.g. redirect user to different pages depending on if the user
 * is logged in or not).
 */
export const setIsLoggedInCookie = (isLoggedIn: boolean) => {
  if (isLoggedIn) {
    Cookies.set("isLoggedIn", "true");
  } else {
    Cookies.remove("isLoggedIn");
  }
};
