import { MutableRefObject } from "react";
import { TPlayerEpisode } from "components/Player/context/PlayerContextProvider";
import { clearMediaSession } from "components/Player/helpers/mediaSession";
import { IProps as IUseAudioProps } from "components/Player/hooks/useAudio";
import { useEffectOnce } from "hooks/useEffectOnce";

/**
 * Calls "onUnload" when "useAudio" unmounts and pauses the current audio. This
 * could happen if the whole "Player" component gets unmounted.
 */
export const useUnmount = (
  audio: HTMLAudioElement | undefined,
  episodeRef: MutableRefObject<TPlayerEpisode | undefined>,
  onUnload: IUseAudioProps["onUnload"]
): void => {
  useEffectOnce(() => {
    return () => {
      const episode = episodeRef.current;

      if (audio) {
        if (!audio.paused) {
          audio.pause();
        }
        audio.removeAttribute("src");
        audio.removeAttribute("title");
        clearMediaSession();
      }

      if (audio && episode && onUnload) {
        onUnload(episode, audio);
      }
    };
  });
};
