import dynamic from "next/dynamic";
import { IChatProps } from "./DynamicChat";

const DynamicChat = dynamic<IChatProps>(
  () => import("./DynamicChat").then((mod) => mod.DynamicChat),
  {
    ssr: false,
  }
);

export const Chat = (props: IChatProps) => {
  return <DynamicChat {...props} />;
};
