import { useEffect, useState } from "react";
import {
  getBrowserStorageItem,
  setBrowserStorageItem,
  StorageItem,
} from "helpers/browserStorage";
import { getUUIDv4 } from "helpers/getUUIDv4";

export const useNonUserUid = () => {
  const [nonUserUid, setNonUserUid] = useState<undefined | string>(undefined);

  useEffect(() => {
    const uid = getBrowserStorageItem(StorageItem.UID);
    if (uid) {
      setNonUserUid(uid);
    } else {
      // Create new uid value if it's undefined.
      const newUid = getUUIDv4();
      setBrowserStorageItem(StorageItem.UID, newUid);
      setNonUserUid(newUid);
    }
  }, []);

  // Return.
  return nonUserUid;
};
