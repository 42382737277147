import { useEffect, useState } from "react";

// NOTICE: Store initial state outside of hook to make value global. We only
// want the value to be false the first render on client side. Not every time a
// component that uses the hook mounts.
let initialState = false;

/**
 * Returns true after client first render. Could be used to avoid server/client
 * hydration mismatch.
 */
export const useIsHydrated = (): boolean => {
  const [isHydrated, setIsHydrated] = useState(initialState);
  useEffect(() => {
    initialState = true;
    setIsHydrated(true);
  }, []);
  return isHydrated;
};
