import dynamic from "next/dynamic";
import { ReactElement } from "react";
import React from "react";
import { useModal } from "components/Modals/hooks/useModal";
import { IModalsDynamicProps } from "./ModalsDynamic";

const ModalsDynamic = dynamic<IModalsDynamicProps>(
  () =>
    import("components/Modals/ModalsDynamic").then(
      (mod) => mod.ModalsDynamic
    ) as never,
  { ssr: false }
);

export const Modals = (): ReactElement | null => {
  const { modalType } = useModal();
  return modalType ? <ModalsDynamic modalType={modalType} /> : null;
};
