import * as category from "./category";
import * as consent from "./consent";
import * as episode from "./episode";
import * as follow from "./follow";
import * as history from "./history";
import * as podcast from "./podcast";
import * as search from "./search";
import * as toplist from "./toplist";
import * as user from "./user";

export default {
  category,
  consent,
  episode,
  follow,
  history,
  podcast,
  search,
  toplist,
  user,
};

export enum Method {
  POST = "POST",
  GET = "GET",
  PUT = "PUT",
  PATCH = "PATCH",
  DELETE = "DELETE",
}

export enum Order {
  ASC = "asc",
  DESC = "desc",
}
