import { Method } from "api/podplay/index";
import { podplayAuthFetch } from "helpers/authFetch";
import { setBrowserStorageItem, StorageItem } from "helpers/browserStorage";
import { IConsent, Language } from "interfaces/interfaces";

// -----------------------------------------------------------------------------
// USER CONSENTS
// -----------------------------------------------------------------------------

export interface IGetUserConsentsResponseBody {
  consents: IConsent[];
}

export function getUserConsentsUrl(language: Language): string {
  return `/${language}/user/consent`;
}

// -----------------------------------------------------------------------------
// UPDATE USER CONSENTS
// -----------------------------------------------------------------------------

export interface IUpdateUserConsentsResponseBody {
  consents: IConsent[];
}

export const updateUserConsents = async (
  language: Language,
  consents: IConsent[],
  requestInit?: RequestInit
) => {
  const response = await podplayAuthFetch<IUpdateUserConsentsResponseBody>(
    language,
    getUserConsentsUrl(language),
    {
      method: Method.PUT,
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ consents }),
      ...requestInit,
    }
  );

  // This API call return a new access_token in a header for some reason,
  // so we update it here.
  if (response.ok) {
    const accessToken = response.headers.get("x-access-token");
    if (accessToken) {
      setBrowserStorageItem(StorageItem.AUTH_ACCESS, accessToken);
    }
  }

  return response;
};
