import classNames from "classnames";
import { I18nKey } from "locales/constants/I18nKey";
import React, { ReactElement, useMemo, useState } from "react";
import { TEpisodeProgress } from "api/podplay/history";
import { DesktopAlign } from "components/ContextMenu/ContextMenu";
import { MoreHorizIcon } from "components/Icons/MoreHorizIcon";
import { TPlayerQueueItem } from "components/Player/context/PlayerContextProvider";
import { PodcastImage } from "components/PodcastImage/PodcastImage";
import {
  formatDurationAsMinutesRoundedUp,
  formatDurationAsTimeDatePeriod,
} from "helpers/time";
import { useI18n } from "hooks/useI18n";
import { useTypedRouter } from "hooks/useTypedRouter";
import { Locale } from "interfaces/interfaces";
import { QueueItemMoreMenu } from "./components/QueueItemMoreMenu/QueueItemMoreMenu";
import styles from "./item.module.scss";

const formatDate = (locale: Locale, date: Date): string => {
  return new Intl.DateTimeFormat(locale, {
    year: "numeric",
    month: "long",
    day: "numeric",
  }).format(date);
};

interface ITimeProps {
  progress: TEpisodeProgress | undefined;
  approximatedDuration: number | null;
}
const Time = ({ progress, approximatedDuration }: ITimeProps) => {
  const { i18n } = useI18n();

  // Completed.
  if (progress && progress.completed) {
    return <div>{i18n(I18nKey.EPISODE_PROGRESS_COMPLETED)}</div>;
  }

  // No duration.
  if (!approximatedDuration) {
    return null;
  }

  // Time left.
  if (progress && progress.progress !== 0) {
    const timeLeft = approximatedDuration - progress.progress;
    return (
      <div>
        <time dateTime={formatDurationAsTimeDatePeriod(timeLeft)}>
          {i18n(I18nKey.COMMON_DURATION_LEFT_MINUTES, {
            minutes: formatDurationAsMinutesRoundedUp(timeLeft),
          })}
        </time>
      </div>
    );
  }

  // Duration.
  return (
    <div>
      <time dateTime={formatDurationAsTimeDatePeriod(approximatedDuration)}>
        {i18n(I18nKey.COMMON_DURATION_MINUTES, {
          minutes: formatDurationAsMinutesRoundedUp(approximatedDuration),
        })}
      </time>
    </div>
  );
};

export interface IProps {
  className?: string;
  item: TPlayerQueueItem;
  progress: TEpisodeProgress | undefined;
  onClick: () => void;
}

export const Item = ({
  className,
  item,
  progress,
  onClick,
}: IProps): ReactElement => {
  if (typeof window === "undefined") {
    // NOTICE: Placeholder item should be used server side. This makes it
    // possible to avoid taking care of timezone differences between client and
    // server here.
    throw new Error("This component should not be used server side");
  }

  const { locale } = useTypedRouter();

  const date = useMemo(
    () => new Date(item.episode.published * 1000),
    [item.episode.published]
  );
  const dateLabel = useMemo(() => formatDate(locale, date), [date, locale]);
  const approximatedDuration = progress?.duration || item.episode.duration;
  const [showMoreMenu, setShowMoreMenu] = useState(false);
  const [moreMenuAnchorElm, setMoreMenuAnchorElm] =
    useState<HTMLElement | null>(null);
  const { i18n } = useI18n();

  return (
    <div className={classNames(className, styles.container)}>
      <button
        type="button"
        data-testid="queue-play-button"
        className={styles.itemButton}
        onClick={onClick}
        aria-label={i18n(I18nKey.COMMON_PLAY)}
      >
        <PodcastImage
          className={styles.image}
          src={item.podcast.image}
          alt={item.podcast.title}
          width={40}
          height={40}
          key={item.podcast.image}
        />
        <div className={styles.infoWrapper}>
          <div className={styles.title} data-testid="queue-episode-title">
            {item.episode.title}
          </div>
          <div className={styles.dateAndDurationWrapper}>
            <div>
              <time dateTime={date.toISOString()}>{dateLabel}</time>
            </div>
            <Time
              progress={progress}
              approximatedDuration={approximatedDuration}
            />
          </div>
        </div>
      </button>
      <button
        onClick={() => setShowMoreMenu(true)}
        className={styles.menuButton}
        type="button"
        aria-label={i18n(I18nKey.PLAYER_QUEUE_ITEM_MORE_BUTTON)}
        ref={setMoreMenuAnchorElm}
      >
        <MoreHorizIcon size={16} />
      </button>
      {showMoreMenu && (
        <QueueItemMoreMenu
          episode={item.episode}
          podcast={item.podcast}
          onClose={() => setShowMoreMenu(false)}
          desktopAlign={DesktopAlign.HORIZONTAL}
          anchorElm={moreMenuAnchorElm}
        />
      )}
    </div>
  );
};
