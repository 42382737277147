import * as Sentry from "@sentry/nextjs";
import { z, ZodError } from "zod";
import {
  TPlayerQueueItem,
  ZPlayerQueue,
  TPersistedEpisode,
  TPlayerEpisode,
  ZPersistedEpisode,
} from "components/Player/context/PlayerContextProvider";
import {
  setBrowserStorageItem,
  getBrowserStorageItem,
  removeBrowserStorageItem,
  StorageItem,
} from "helpers/browserStorage";

// -----------------------------------------------------------------------------
// Helper functions.
// -----------------------------------------------------------------------------
/**
 * Function to use when JSON.parse or zod throws error.
 */
const onError = (err: unknown): void => {
  if (process.env.NODE_ENV === "development") {
    // eslint-disable-next-line no-console
    console.log(err);
  }
  Sentry.withScope((scope) => {
    scope.setExtras({
      // Add errors to extras - Sentry will truncate the error message leaving
      // out important details.
      zodErrors: err instanceof ZodError ? err.errors : undefined,
    });
    Sentry.captureException(err);
  });
};

// -----------------------------------------------------------------------------
// Functions for episode and queue.
// -----------------------------------------------------------------------------

/**
 * Serializes episode and save data in localStorage.
 */
export const setPersistedEpisode = (
  episode: TPlayerEpisode | undefined,
  currentTime: number | undefined
): void => {
  if (episode) {
    const item: TPersistedEpisode = {
      episode,
      currentTime,
    };
    setBrowserStorageItem(StorageItem.PLAYER_EPISODE, JSON.stringify(item));
  } else {
    removeBrowserStorageItem(StorageItem.PLAYER_EPISODE);
  }
};

/**
 * Validates and returns persisted episode.
 */
export const getPersistedEpisode = (): TPersistedEpisode | undefined => {
  const item = getBrowserStorageItem(StorageItem.PLAYER_EPISODE);
  if (!item) {
    return undefined;
  }

  try {
    const episode = JSON.parse(item);
    return ZPersistedEpisode.parse(episode);
  } catch (err) {
    onError(err);
  }
  return undefined;
};

/**
 * Serialized queue and saves data in localStorage.
 */
export const setPersistedQueue = (queue: TPlayerQueueItem[]): void => {
  setBrowserStorageItem(StorageItem.PLAYER_QUEUE, JSON.stringify(queue));
};

/**
 * Validates and returns persisted queue.
 */
export const getPersistedQueue = (): TPlayerQueueItem[] | undefined => {
  const item = getBrowserStorageItem(StorageItem.PLAYER_QUEUE);
  if (!item) {
    return undefined;
  }

  try {
    const queue = JSON.parse(item);
    return ZPlayerQueue.parse(queue);
  } catch (err) {
    onError(err);
  }

  return undefined;
};

// -----------------------------------------------------------------------------
// Functions for misc.
// -----------------------------------------------------------------------------

const ZAutoplay = z.object({
  autoplay: z.boolean(),
});
export type TAutoplay = z.infer<typeof ZAutoplay>;

export const setPersistedAutoplay = (autoplay: boolean): void => {
  const item: TAutoplay = { autoplay };
  setBrowserStorageItem(StorageItem.PLAYER_AUTOPLAY, JSON.stringify(item));
};

export const getPersistedAutoplay = (): boolean | undefined => {
  const item = getBrowserStorageItem(StorageItem.PLAYER_AUTOPLAY);
  if (!item) {
    return undefined;
  }

  try {
    const misc = JSON.parse(item);
    return ZAutoplay.parse(misc).autoplay;
  } catch (err) {
    onError(err);
  }

  return undefined;
};

// -----------------------------------------------------------------------------
// Clear.
// -----------------------------------------------------------------------------

/**
 * Removes all persisted player data.
 */
export const clearPersistedPlayerData = () => {
  removeBrowserStorageItem(StorageItem.PLAYER_EPISODE);
  removeBrowserStorageItem(StorageItem.PLAYER_QUEUE);
  removeBrowserStorageItem(StorageItem.PLAYER_AUTOPLAY);
};
