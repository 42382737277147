import { stringify } from "query-string";
import { z } from "zod";
import { Method } from "api/podplay/index";
import { podplayFetch } from "helpers/podplayFetch";
import { validateResponse } from "helpers/validateResponse";
import { Language } from "interfaces/interfaces";

export const ZPodcastType = z.enum(["episodic", "serial"]);
export type TPodcastType = z.infer<typeof ZPodcastType>;

export const ZPodcast = z.object({
  author: z.string(),
  category_id: z.number().array(),
  description: z.string(),
  encoded: z.string(),
  id: z.number(),
  image: z.string(),
  language_iso: z.string(),
  link: z.string(),
  original: z.boolean(),
  popularity: z.number(),
  rss: z.string(),
  seasonal: z.boolean(),
  slug: z.string(),
  title: z.string(),
  type: ZPodcastType,
});
export type TPodcast = z.infer<typeof ZPodcast>;

// -----------------------------------------------------------------------------
// GET PODCAST
// -----------------------------------------------------------------------------

export const ZGetPodcastResponseBody = z.object({
  podcast: ZPodcast,
});
export type TGetPodcastResponseBody = z.infer<typeof ZGetPodcastResponseBody>;

export const getPodcastUrl = (
  language: Language,
  id: string | number
): string => `/${language}/podcast/${id}`;

export const getPodcast = async (
  language: Language,
  id: string | number,
  requestInit?: RequestInit
) => {
  const response = await podplayFetch<TGetPodcastResponseBody>(
    getPodcastUrl(language, id),
    requestInit
  );

  return validateResponse(response, ZGetPodcastResponseBody);
};

// -----------------------------------------------------------------------------
// GET MULTIPLE PODCASTS BY ID
// -----------------------------------------------------------------------------

export const getMultiplePodcastsByIdUrl = (
  language: Language,
  options?: { id: number[] | string[] }
): string => {
  const encodedSearchParams = options && stringify(options);
  const search = encodedSearchParams ? `?${encodedSearchParams}` : "";
  return `/${language}/podcast-by-id${search}`;
};

export const ZGetMultiplePodcastsByIdResponseBody = z.object({
  results: ZPodcast.array(),
});
export type TGetMultiplePodcastsByIdResponseBody = z.infer<
  typeof ZGetMultiplePodcastsByIdResponseBody
>;

export const getMultiplePodcastsById = async (
  language: Language,
  options?: { id: number[] | string[] },
  requestInit?: RequestInit
) => {
  const response = await podplayFetch<TGetMultiplePodcastsByIdResponseBody>(
    getMultiplePodcastsByIdUrl(language, options),
    {
      method: Method.GET,
      headers: {
        "Content-Type": "application/json",
      },
      ...requestInit,
    }
  );

  return validateResponse(response, ZGetMultiplePodcastsByIdResponseBody);
};
