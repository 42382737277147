import { useUser } from "contexts/UserContextProvider/UserContextProvider";
import { useCallback } from "react";
import { createPlayerNormalEpisode } from "components/Player/helpers/createPlayerNormalEpisode";
import { usePlayer } from "components/Player/hooks/usePlayer";
import { getBrowserStorageItem, StorageItem } from "helpers/browserStorage";

/**
 * Returns a function to load the first episode in the queue into the player.
 */
export const useLoadNextTrackFromQueue = (): ((
  autoPlay: boolean,
  savePreviousEpisodeProgressOnUnload?: boolean
) => Promise<void>) => {
  const { user, uid } = useUser();
  const { queue, load, removeFromQueue, queueEpisodeProgress } = usePlayer();

  return useCallback(
    async (autoPlay: boolean, savePreviousEpisodeProgressOnUnload = true) => {
      if (!uid || user === undefined) {
        return;
      }
      const firstItem = queue[0];

      if (!firstItem) {
        return;
      }

      const { episode, podcast } = firstItem;

      removeFromQueue(episode.id);

      // Load.
      const playerEpisode = createPlayerNormalEpisode({
        episode,
        podcast,
        episodeProgress: queueEpisodeProgress[episode.id],
        autoPlay,
        uid,
        user,
        sourcePointConsents: getBrowserStorageItem(
          StorageItem.CONSENTS_SOURCE_POINT
        ),
      });
      await load?.(playerEpisode, savePreviousEpisodeProgressOnUnload);
    },
    [uid, user, queue, removeFromQueue, queueEpisodeProgress, load]
  );
};
