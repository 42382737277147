import { MutableRefObject, useEffect } from "react";

/**
 * This hook makes sure that "currentTime" is at least the starting time
 * after metadata is loaded. If not, it seeks to the desired start time.
 */
export const useAdjustPositionAfterStart = (
  audio: HTMLAudioElement | undefined,
  desiredStartTimeRef: MutableRefObject<number>
): void => {
  useEffect(() => {
    if (!audio) {
      return;
    }

    const loadedMetadataHandler = () => {
      const desiredStartTime = desiredStartTimeRef.current;

      if (audio.currentTime < desiredStartTime) {
        // Update the audio.
        audio.currentTime = desiredStartTime;
      }
    };

    audio.addEventListener("loadedmetadata", loadedMetadataHandler);

    return () => {
      audio.removeEventListener("loadedmetadata", loadedMetadataHandler);
    };
  }, [audio, desiredStartTimeRef]);
};
