export class AudioUndefinedError extends Error {
  constructor() {
    super(
      "Audio undefined - This could happen if the player failed to create an audio element."
    );

    this.name = "AudioUndefinedError";

    // Set the prototype explicitly.
    Object.setPrototypeOf(this, AudioUndefinedError.prototype);
  }
}
