import * as Sentry from "@sentry/nextjs";
import { I18nKey } from "locales/constants/I18nKey";
import { useCallback, useEffect, useState } from "react";
import { II18n } from "components/I18nContextProvider/I18nContextProvider";
import { UTMCampaign, UTMSource } from "helpers/analytics";
import { limitTextLength } from "helpers/limitTextLength";
import { buildEpisodeShareUrl, buildPodcastShareUrl } from "helpers/url";
import { useIsHydrated } from "hooks/useIsHydrated";
import { Locale } from "interfaces/interfaces";

export interface IUseShare {
  isShareAvailable: boolean;
  share: (shareData: ShareData) => Promise<void>;
}

/**
 * Share via the native share api.
 */
export const useShare = (): IUseShare => {
  const isHydrated = useIsHydrated();
  const [isShareAvailable, setIsShareAvailable] = useState(() =>
    isHydrated ? !!window.navigator.share : false
  );

  useEffect(() => {
    setIsShareAvailable(isHydrated ? !!window.navigator.share : false);
  }, [isHydrated]);

  const share = useCallback(async (shareData: ShareData) => {
    try {
      await navigator.share(shareData);
    } catch (err) {
      // AbortError happens when the user closes the share menu without sharing.
      // Do not report to Sentry.
      if (err instanceof Error && err.name === "AbortError") {
        return;
      }
      Sentry.captureException(err);
    }
  }, []);

  return { isShareAvailable, share };
};

export function createEpisodeShareData(
  episode: { id: number | string; title: string; description: string },
  podcast: { id: number; title: string; original: boolean },
  i18n: II18n,
  locale: Locale
): ShareData {
  // NOTICE: Prop "title" may be ignored by the target.
  const title = i18n(I18nKey.SEO_EPISODE_PAGE_TITLE, {
    title: episode.title,
    podcastTitle: podcast.title,
  });
  // Use same description logic as episode page - We are linking to episode
  // the page.
  const text = episode.description
    ? i18n(I18nKey.SEO_EPISODE_PAGE_DESCRIPTION, {
        description: limitTextLength(episode.description, 200),
      })
    : "";
  // Episode page url.
  const url = `${buildEpisodeShareUrl(
    podcast,
    episode,
    UTMCampaign.SHARE,
    UTMSource,
    locale
  )}`;

  return { title, text, url };
}

export function createPodcastShareData(
  podcast: {
    id: number;
    title: string;
    original: boolean;
    description: string;
  },
  i18n: II18n,
  locale: Locale
): ShareData {
  // NOTICE: Prop "title" may be ignored by the target.
  const title = i18n(I18nKey.SEO_PODCAST_PAGE_TITLE, {
    title: podcast.title,
  });

  const text = podcast.description
    ? limitTextLength(podcast.description, 200)
    : "";

  // Podcast page url
  const url = `${buildPodcastShareUrl(
    podcast,
    UTMCampaign.SHARE,
    UTMSource,
    locale
  )}`;

  return { title, text, url };
}
