import { z } from "zod";
import { Method } from "api/podplay/index";
import { podplayAuthFetch } from "helpers/authFetch";
import { convertBirthyearToTimeStamp } from "helpers/convertBirthyear";
import { podplayFetch } from "helpers/podplayFetch";
import { removeEmptyProperties } from "helpers/removeEmptyProperties";
import { removeNullishProperties } from "helpers/removeNullishProperties";
import { Language } from "interfaces/interfaces";

// -----------------------------------------------------------------------------
// CREATE NEW USER
// -----------------------------------------------------------------------------

export interface ICreateNewUserResponseBody {
  access_token: string;
  refresh_token: string;
}

export const createNewUser = (
  language: Language,
  values: {
    email: string;
    password: string;
    gender: TGender;
    birthdate: number;
    required_consents_accepted: boolean;
    profile_name: string | undefined;
  },
  requestInit?: RequestInit
) => {
  return podplayFetch<ICreateNewUserResponseBody>(`/${language}/signup`, {
    method: Method.POST,
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(
      removeEmptyProperties({
        ...values,
      })
    ),
    ...requestInit,
  });
};

// -----------------------------------------------------------------------------
// LOGIN USER
// -----------------------------------------------------------------------------

export interface ILoginUserResponseBody {
  access_token: string;
  refresh_token: string;
}

export const loginUser = async (
  language: Language,
  values: {
    email: string;
    password: string;
  },
  requestInit?: RequestInit
) => {
  return podplayFetch<ILoginUserResponseBody>(`/${language}/login`, {
    method: Method.POST,
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(values),
    ...requestInit,
  });
};

// -----------------------------------------------------------------------------
// REFRESH ACCESS TOKEN
// -----------------------------------------------------------------------------

export interface IRefreshAccessTokenParsedBody {
  access_token: string;
}

export const getRefreshAccessTokenUrl = (language: Language): string =>
  `/${language}/refresh-token`;

export const refreshAccessToken = (
  language: Language,
  refreshToken: string,
  requestInit?: RequestInit
) => {
  return podplayFetch<IRefreshAccessTokenParsedBody>(
    getRefreshAccessTokenUrl(language),
    {
      ...requestInit,
      method: Method.POST,
      headers: { Authorization: `Bearer ${refreshToken}` },
      body: "",
    }
  );
};

// -----------------------------------------------------------------------------
// GET USER INFO
// -----------------------------------------------------------------------------

// NOTICE: Function name could be confusing because it is the same as the
// function "gdprGetUserData" path ("/user/userinfo").
export const getUserInfoUrl = (language: Language): string => {
  return `/${language}/user`;
};

export const ZGender = z.enum(["male", "female", "other"]);
export type TGender = z.infer<typeof ZGender>;

export const ZUserInfo = z.object({
  birthdate: z.union([z.number(), z.null()]),
  email: z.string(),
  gender: z.union([ZGender, z.null()]),
  language_iso: z.string(),
  profile_name: z.union([z.string(), z.null()]),
  user_id: z.string(),
});
export type TUserInfo = z.infer<typeof ZUserInfo>;

export interface IAccessToken {
  exp?: number;
  // User id.
  sub: string;
  claims: {
    gender: TGender | null;
    birthdate: number | null;
    email: string;
    language: Language;
    profile_name: string | null;
  };
}

// TODO: Remove "IGetUserInfoResponseBody"? What about Gender? We can't work
//  with native enums when using Zod.

export interface IGetUserInfoResponseBody {
  birthdate: number | null;
  email: string;
  gender: TGender | null;
  language_iso: string;
  profile_name: string | null;
  user_id: string;
}

export const getUserInfo = (language: Language, requestInit?: RequestInit) => {
  return podplayAuthFetch(language, getUserInfoUrl(language), requestInit);
};

// -----------------------------------------------------------------------------
// UPDATE USER INFO
// -----------------------------------------------------------------------------

export interface IUpdateUserInfoResponseBody {
  birthdate: number;
  email: string;
  gender: TGender;
  language_iso: string;
  profile_name: string;
  user_id: string;
}

export const updateUserInfo = (
  language: Language,
  values: {
    password?: string;
    email?: string;
    birthyear?: string;
    gender?: TGender;
    profile_name?: string;
  },
  requestInit?: RequestInit
) => {
  // Convert birthyear to timestamp.
  const birthdate = values.birthyear
    ? convertBirthyearToTimeStamp(parseInt(values.birthyear))
    : null;

  // Convert empty profile name to null.
  const profile_name = values.profile_name === "" ? null : values.profile_name;

  const payload = removeNullishProperties({
    password: values.password,
    email: values.email,
    gender: values.gender,
    profile_name,
    birthdate,
  });

  return podplayAuthFetch<IUpdateUserInfoResponseBody>(
    language,
    getUserInfoUrl(language),
    {
      method: Method.PATCH,
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
      ...requestInit,
    }
  );
};

// -----------------------------------------------------------------------------
// DELETE USER
// -----------------------------------------------------------------------------

export const deleteUser = (
  language: Language,
  password: string,
  requestInit?: RequestInit
) => {
  return podplayAuthFetch<never>(language, getUserInfoUrl(language), {
    method: Method.DELETE,
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      password,
    }),
    ...requestInit,
  });
};

// -----------------------------------------------------------------------------
// UPDATE USER PASSWORD
// -----------------------------------------------------------------------------

export const updateUserPassword = (
  language: Language,
  password: string,
  new_password: string,
  requestInit?: RequestInit
) => {
  return podplayAuthFetch<never>(language, `/${language}/user/password`, {
    method: Method.PUT,
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      password,
      new_password,
    }),
    ...requestInit,
  });
};

// -----------------------------------------------------------------------------
// GDPR GET USER DATA
// -----------------------------------------------------------------------------

// NOTICE: Endpoint path name "/user/userinfo" could be confusing because it
// has the same name as the method "getUserInfo" (path "/user").
export const getGdprGetUserDataUrl = (language: Language): string => {
  return `/${language}/user/userinfo`;
};

export const gdprGetUserData = (
  language: Language,
  requestInit?: RequestInit
) => {
  return podplayAuthFetch<never>(
    language,
    getGdprGetUserDataUrl(language),
    requestInit
  );
};

// -----------------------------------------------------------------------------
// RESET USER PASSWORD
// (Email the user with a link to reset the password.)
// -----------------------------------------------------------------------------

export const resetUserPassword = (
  language: Language,
  email: string,
  requestInit?: RequestInit
) => {
  return podplayFetch<never>(`/${language}/forgot-password`, {
    method: Method.POST,
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      email,
    }),
    ...requestInit,
  });
};

// -----------------------------------------------------------------------------
// RESET PASSWORD
// -----------------------------------------------------------------------------

export interface IResetPasswordResponseBody {
  access_token: string;
  refresh_token: string;
}

export const resetPassword = (
  language: Language,
  values: {
    new_password: string;
    signature: string;
  },
  requestInit?: RequestInit
) => {
  return podplayFetch<IResetPasswordResponseBody>(
    `/${language}/reset-password`,
    {
      method: Method.POST,
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(values),
      ...requestInit,
    }
  );
};
