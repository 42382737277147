import classNames from "classnames";
import { I18nKey } from "locales/constants/I18nKey";
import React, { ReactElement } from "react";
import { Forward30Icon } from "components/Icons/Forward30Icon";
import { LoadingIcon } from "components/Icons/LoadingIcon";
import { PauseIcon } from "components/Icons/PauseIcon";
import { PlayIcon } from "components/Icons/PlayIcon";
import { Rewind15Icon } from "components/Icons/Rewind15Icon";
import { usePlayer } from "components/Player/hooks/usePlayer";
import { useI18n } from "hooks/useI18n";
import { Scrubber } from "./components/Scrubber/Scrubber";
import { ScrubberLive } from "./components/Scrubber/ScrubberLive";
import styles from "./playerControls.module.scss";

export interface IProps {
  className?: string;
}

export const PlayerControls = ({ className }: IProps): ReactElement | null => {
  const { skip, episode, paused, pause, play, duration, isBuffering } =
    usePlayer();

  // NOTICE: Using "key" on "Scrubber" component to reset internal state when
  // episode changes.
  const scrubberKey = episode?.id || "none";

  const { i18n } = useI18n();

  return (
    <div className={classNames(className, styles.container)}>
      {duration === Infinity ? (
        <ScrubberLive className={styles.scrubber} />
      ) : (
        <Scrubber key={scrubberKey} className={styles.scrubber} />
      )}

      <div className={styles.buttonWrapper}>
        <button
          type="button"
          data-testid="player-maxi-skipBackward"
          className={classNames(styles.button, styles.skipButton)}
          onClick={() => skip(-15)}
          aria-label={i18n(I18nKey.PLAYER_SKIP_BACKWARD)}
          disabled={duration === Infinity}
        >
          <Rewind15Icon size={32} />
        </button>
        <button
          type="button"
          data-testid={
            paused ? "player-maxi-playButton" : "player-maxi-pauseButton"
          }
          className={classNames(styles.button, styles.playButton)}
          onClick={paused ? play : pause}
          aria-label={
            paused ? i18n(I18nKey.COMMON_PLAY) : i18n(I18nKey.COMMON_PAUSE)
          }
        >
          {isBuffering ? (
            <LoadingIcon size={54} className={styles.loadingIcon} />
          ) : paused ? (
            <PlayIcon size={54} />
          ) : (
            <PauseIcon size={54} />
          )}
        </button>
        <button
          type="button"
          data-testid="player-maxi-skipForward"
          className={classNames(styles.button, styles.skipButton)}
          onClick={() => skip(30)}
          aria-label={i18n(I18nKey.PLAYER_SKIP_FORWARD)}
          disabled={duration === Infinity}
        >
          <Forward30Icon size={32} />
        </button>
      </div>
    </div>
  );
};
