import { useUser } from "contexts/UserContextProvider/UserContextProvider";
import { I18nKey } from "locales/constants/I18nKey";
import React, {
  MouseEventHandler,
  useCallback,
  useEffect,
  useRef,
} from "react";
import { TPodcast } from "api/podplay/podcast";
import { TSendbird } from "api/studio";
import { useChat } from "components/Chat/context/chatContext";
import { ModalOrigin } from "components/Modals/context/ModalContextProvider";
import { useModal } from "components/Modals/hooks/useModal";
import { ModalType } from "components/Modals/modalTypes";
import { usePlayer } from "components/Player/hooks/usePlayer";
import { useI18n } from "hooks/useI18n";

export const ChatButton = (props: {
  className: string;
  chatProvider: TSendbird | undefined;
  podcast: TPodcast;
  disabled?: boolean;
  children: React.ReactNode | React.ReactNode[];
}) => {
  const { i18n } = useI18n();
  const { active, openChat, closeChat } = useChat();
  const { openModal } = useModal();
  const { setIsExpanded } = usePlayer();
  const { user } = useUser();

  const triggerChat = useCallback(async () => {
    if (!props.chatProvider) {
      return;
    }
    if (active) {
      closeChat();
    } else {
      setIsExpanded(false);
      openChat(props.chatProvider, props.podcast.image);
    }
  }, [
    active,
    closeChat,
    openChat,
    props.chatProvider,
    props.podcast.image,
    setIsExpanded,
  ]);

  const triggerChatRef = useRef(triggerChat);
  useEffect(() => {
    triggerChatRef.current = triggerChat;
  }, [triggerChat]);

  const checkUser = useCallback(async () => {
    // Don't do anything if we don't know if user is logged in or not. We
    // should know it the moment after the first client render making this just
    // a theoretical problem.
    if (user === undefined) {
      return;
    }

    // Not logged in.
    if (!user) {
      return openModal(ModalType.LOGIN_OR_SIGNUP, {
        callback: triggerChatRef,
        origin: ModalOrigin.CHAT,
      });
    }

    // No profile name.
    if (!user.profile_name) {
      return openModal(ModalType.PROFILE_NAME, {
        callback: triggerChatRef,
        origin: ModalOrigin.CHAT,
      });
    }

    // Everything ok - Trigger chat.
    await triggerChat();
  }, [openModal, triggerChat, user]);

  const onChatButtonClick: MouseEventHandler<HTMLButtonElement> = async (
    event
  ) => {
    event.stopPropagation();
    await checkUser();
  };

  return (
    <button
      data-testid="chat-button"
      type="button"
      disabled={props.disabled}
      aria-label={i18n(I18nKey.PLAYER_CHAT_BUTTON)}
      className={props.className}
      onClick={onChatButtonClick}
    >
      {props.children}
    </button>
  );
};
