export enum I18nKey {
  BROADCAST_COUNTDOWN = "BROADCAST_COUNTDOWN",
  BROADCAST_LISTENERS = "BROADCAST_LISTENERS",
  BROADCAST_ON_AIR = "BROADCAST_ON_AIR",
  BROADCAST_TIME = "BROADCAST_TIME",
  CATEGORIES_PAGE_HEADING = "CATEGORIES_PAGE_HEADING",
  CATEGORIES_PAGE_POPULAR_HEADING = "CATEGORIES_PAGE_POPULAR_HEADING",
  CATEGORIES_PAGE_ALL_HEADING = "CATEGORIES_PAGE_ALL_HEADING",
  COMMON_CLOSE = "COMMON_CLOSE",
  COMMON_DURATION_LEFT_MINUTES = "COMMON_DURATION_LEFT_MINUTES",
  COMMON_DURATION_MINUTES = "COMMON_DURATION_MINUTES",
  COMMON_LOGIN = "COMMON_LOGIN",
  COMMON_OPEN_IN_NEW_WINDOW = "COMMON_OPEN_IN_NEW_WINDOW",
  COMMON_PAUSE = "COMMON_PAUSE",
  COMMON_PLAY = "COMMON_PLAY",
  COMMON_RESTART = "COMMON_RESTART",
  COMMON_RESUME = "COMMON_RESUME",
  COMMON_SIGNUP = "COMMON_SIGNUP",
  COMMON_PLAYBACK_RATE = "COMMON_PLAYBACK_RATE",
  COMMON_SHARE = "COMMON_SHARE",
  CONSENTS_FORM_ERROR_BODY_COMMON = "CONSENTS_FORM_ERROR_BODY_COMMON",
  CONSENTS_FORM_ERROR_BODY_TEMPORARY = "CONSENTS_FORM_ERROR_BODY_TEMPORARY",
  CONSENT_MODAL_HEADING = "CONSENT_MODAL_HEADING",
  CONSENT_MODAL_SUBMIT_BUTTON = "CONSENT_MODAL_SUBMIT_BUTTON",
  EPISODE_DATE_JUST_NOW = "EPISODE_DATE_JUST_NOW",
  EPISODE_DATE_HOUR = "EPISODE_DATE_HOUR",
  EPISODE_DATE_HOURS = "EPISODE_DATE_HOURS",
  EPISODE_DETAILS_MENU_ARIA_LABEL = "EPISODE_DETAILS_MENU_ARIA_LABEL",
  EPISODE_DETAILS_MENU_MARK_PLAYED = "EPISODE_DETAILS_MENU_MARK_PLAYED",
  EPISODE_DETAILS_MENU_MARK_UNPLAYED = "EPISODE_DETAILS_MENU_MARK_UNPLAYED",
  EPISODE_OPTIONS_MENU_PODCAST_LINK = "EPISODE_OPTIONS_MENU_PODCAST_LINK",
  EPISODE_OPTIONS_MENU_EPISODE_LINK = "EPISODE_OPTIONS_MENU_EPISODE_LINK",
  EPISODE_PAGE_CATEGORIES_HEADING = "EPISODE_PAGE_CATEGORIES_HEADING",
  ERROR_MESSAGE_BUTTON = "ERROR_MESSAGE_BUTTON",
  ERROR_MESSAGE_TITLE = "ERROR_MESSAGE_TITLE",
  ERROR_PAGE_404_BODY = "ERROR_PAGE_404_BODY",
  ERROR_PAGE_404_BUTTON = "ERROR_PAGE_404_BUTTON",
  ERROR_PAGE_404_HEADING = "ERROR_PAGE_404_HEADING",
  ERROR_PAGE_COMMON_BODY = "ERROR_PAGE_COMMON_BODY",
  ERROR_PAGE_COMMON_BUTTON = "ERROR_PAGE_COMMON_BUTTON",
  ERROR_PAGE_COMMON_HEADING = "ERROR_PAGE_COMMON_HEADING",
  ERROR_PAGE_STATUS_CODE = "ERROR_PAGE_STATUS_CODE",
  FORM_SELECT_GENDER_OPTION_FEMALE = "FORM_SELECT_GENDER_OPTION_FEMALE",
  FORM_SELECT_GENDER_OPTION_MALE = "FORM_SELECT_GENDER_OPTION_MALE",
  FORM_SELECT_GENDER_OPTION_OTHER = "FORM_SELECT_GENDER_OPTION_OTHER",
  FORM_SELECT_GENDER_PLACEHOLDER = "FORM_SELECT_GENDER_PLACEHOLDER",
  FORM_VALIDATION_BIRTHYEAR_TOO_YOUNG = "FORM_VALIDATION_BIRTHYEAR_TOO_YOUNG",
  FORM_VALIDATION_EMAIL = "FORM_VALIDATION_EMAIL",
  FORM_VALIDATION_PROFILENAME = "FORM_VALIDATION_PROFILENAME",
  FORM_VALIDATION_PASSWORD_TOO_SHORT = "FORM_VALIDATION_PASSWORD_TOO_SHORT",
  FORM_VALIDATION_REQUIRED = "FORM_VALIDATION_REQUIRED",
  FORM_VALIDATION_YEAR_INVALID_FORMAT = "FORM_VALIDATION_YEAR_INVALID_FORMAT",
  FORM_VALIDATION_YEAR_INVALID_VALUE = "FORM_VALIDATION_YEAR_INVALID_VALUE",
  LOGIN_ERROR_BODY_COMMON = "LOGIN_ERROR_BODY_COMMON",
  LOGIN_ERROR_BODY_TEMPORARY = "LOGIN_ERROR_BODY_TEMPORARY",
  LOGIN_ERROR_BODY_USER_ALREADY_LOGGED_IN = "LOGIN_ERROR_BODY_USER_ALREADY_LOGGED_IN",
  LOGIN_ERROR_BODY_USER_EMAIL_NOT_VERIFIED = "LOGIN_ERROR_BODY_USER_EMAIL_NOT_VERIFIED",
  LOGIN_ERROR_BODY_USER_NOT_FOUND = "LOGIN_ERROR_BODY_USER_NOT_FOUND",
  LOGIN_ERROR_BODY_USER_PASSWORD_MISMATCH = "LOGIN_ERROR_BODY_USER_PASSWORD_MISMATCH",
  LOGIN_HEADER = "LOGIN_HEADER",
  MARKET_SELECTOR_BUTTON = "MARKET_SELECTOR_BUTTON",
  MARKET_SELECTOR_COUNTRY = "MARKET_SELECTOR_COUNTRY",
  MARKET_SELECTOR_FINLAND = "MARKET_SELECTOR_FINLAND",
  MARKET_SELECTOR_GLOBAL = "MARKET_SELECTOR_GLOBAL",
  MARKET_SELECTOR_LABEL = "MARKET_SELECTOR_LABEL",
  MARKET_SELECTOR_NORWAY = "MARKET_SELECTOR_NORWAY",
  MARKET_SELECTOR_SELECT_DEFAULT = "MARKET_SELECTOR_SELECT_DEFAULT",
  MARKET_SELECTOR_SELECT_FOOTER_DEFAULT = "MARKET_SELECTOR_SELECT_FOOTER_DEFAULT",
  MARKET_SELECTOR_SWEDEN = "MARKET_SELECTOR_SWEDEN",
  PODCAST_LEGAL_TEXT = "PODCAST_LEGAL_TEXT",
  PODCAST_LEGAL_TEXT_ORIGINAL = "PODCAST_LEGAL_TEXT_ORIGINAL",
  PODCAST_LEGAL_TEXT_RSS = "PODCAST_LEGAL_TEXT_RSS",
  PODCAST_PAGE_ABOUT_TOTAL_EPISODES = "PODCAST_PAGE_ABOUT_TOTAL_EPISODES",
  PODCAST_PAGE_ABOUT_HEADER = "PODCAST_PAGE_ABOUT_HEADER",
  PODCAST_PAGE_DESCRIPTION_READ_LESS_BUTTON = "PODCAST_PAGE_DESCRIPTION_READ_LESS_BUTTON",
  PODCAST_PAGE_DESCRIPTION_READ_MORE_BUTTON = "PODCAST_PAGE_DESCRIPTION_READ_MORE_BUTTON",
  PODCAST_PAGE_EPISODE_LIST_HEADER = "PODCAST_PAGE_EPISODE_LIST_HEADER",
  PODCAST_PAGE_EPISODE_LIST_SORT_BUTTON = "PODCAST_PAGE_EPISODE_LIST_SORT_BUTTON",
  PODCAST_PAGE_EPISODE_SHOW_MORE_BUTTON = "PODCAST_PAGE_EPISODE_SHOW_MORE_BUTTON",
  PODCAST_PAGE_FOLLOW_BUTTON_FOLLOW = "PODCAST_PAGE_FOLLOW_BUTTON_FOLLOW",
  PODCAST_PAGE_FOLLOW_BUTTON_FOLLOWING = "PODCAST_PAGE_FOLLOW_BUTTON_FOLLOWING",
  PODCAST_PAGE_HERO_PLAY_BUTTON_TITLE_PLAY_LATEST = "PODCAST_PAGE_HERO_PLAY_BUTTON_TITLE_PLAY_LATEST",
  PODCAST_PAGE_HERO_PLAY_BUTTON_TITLE_PLAY_PREVIOUS = "PODCAST_PAGE_HERO_PLAY_BUTTON_TITLE_PLAY_PREVIOUS",
  PODCAST_PAGE_MORE_MENU_BUTTON_TITLE = "PODCAST_PAGE_MORE_MENU_BUTTON_TITLE",
  PODCAST_PAGE_TOAST_FOLLOW = "PODCAST_PAGE_TOAST_FOLLOW",
  PODCAST_PAGE_TOAST_UNFOLLOW = "PODCAST_PAGE_TOAST_UNFOLLOW",
  PODCAST_REPORT_CONTENT_LINK = "PODCAST_REPORT_CONTENT_LINK",
  RESET_PASSWORD_ERROR_BODY_COMMON = "RESET_PASSWORD_ERROR_BODY_COMMON",
  RESET_PASSWORD_ERROR_BODY_TEMPORARY = "RESET_PASSWORD_ERROR_BODY_TEMPORARY",
  RESET_PASSWORD_ERROR_INVALID_SIGNATURE = "RESET_PASSWORD_ERROR_INVALID_SIGNATURE",
  RESET_PASSWORD_ERROR_SIGNATURE_COULD_NOT_BE_LOADED = "RESET_PASSWORD_ERROR_SIGNATURE_COULD_NOT_BE_LOADED",
  RESET_PASSWORD_ERROR_SIGNATURE_EXPIRED = "RESET_PASSWORD_ERROR_SIGNATURE_EXPIRED",
  SEO_CATEGORY_TOPLIST_PAGE_DESCRIPTION = "SEO_CATEGORY_TOPLIST_PAGE_DESCRIPTION",
  SEO_CATEGORY_TOPLIST_PAGE_SHARE_DESCRIPTION = "SEO_CATEGORY_TOPLIST_PAGE_SHARE_DESCRIPTION",
  SEO_CATEGORY_TOPLIST_PAGE_SHARE_TITLE = "SEO_CATEGORY_TOPLIST_PAGE_SHARE_TITLE",
  SEO_CATEGORY_TOPLIST_PAGE_TITLE = "SEO_CATEGORY_TOPLIST_PAGE_TITLE",
  SEO_CATEGORIES_PAGE_DESCRIPTION = "SEO_CATEGORIES_PAGE_DESCRIPTION",
  SEO_CATEGORIES_PAGE_SHARE_DESCRIPTION = "SEO_CATEGORIES_PAGE_SHARE_DESCRIPTION",
  SEO_CATEGORIES_PAGE_SHARE_TITLE = "SEO_CATEGORIES_PAGE_SHARE_TITLE",
  SEO_CATEGORIES_PAGE_TITLE = "SEO_CATEGORIES_PAGE_TITLE",
  SEO_EPISODE_PAGE_DESCRIPTION = "SEO_EPISODE_PAGE_DESCRIPTION",
  SEO_EPISODE_PAGE_TITLE = "SEO_EPISODE_PAGE_TITLE",
  SEO_ERROR_PAGE_404_TITLE = "SEO_ERROR_PAGE_404_TITLE",
  SEO_ERROR_PAGE_COMMON_TITLE = "SEO_ERROR_PAGE_COMMON_TITLE",
  SEO_PODCAST_PAGE_TITLE = "SEO_PODCAST_PAGE_TITLE",
  SEO_SITE_NAME = "SEO_SITE_NAME",
  SEO_START_PAGE_DESCRIPTION = "SEO_START_PAGE_DESCRIPTION",
  SEO_START_PAGE_SHARE_DESCRIPTION = "SEO_START_PAGE_SHARE_DESCRIPTION",
  SEO_START_PAGE_SHARE_TITLE = "SEO_START_PAGE_SHARE_TITLE",
  SEO_START_PAGE_TITLE = "SEO_START_PAGE_TITLE",
  SEO_HISTORY_PAGE_DESCRIPTION = "SEO_HISTORY_PAGE_DESCRIPTION",
  SEO_HISTORY_PAGE_SHARE_DESCRIPTION = "SEO_HISTORY_PAGE_SHARE_DESCRIPTION",
  SEO_HISTORY_PAGE_SHARE_TITLE = "SEO_HISTORY_PAGE_SHARE_TITLE",
  SEO_HISTORY_PAGE_TITLE = "SEO_HISTORY_PAGE_TITLE",
  SEO_USER_DATA_PAGE_DESCRIPTION = "SEO_USER_DATA_PAGE_DESCRIPTION",
  SEO_USER_DATA_PAGE_SHARE_DESCRIPTION = "SEO_USER_DATA_PAGE_SHARE_DESCRIPTION",
  SEO_USER_DATA_PAGE_SHARE_TITLE = "SEO_USER_DATA_PAGE_SHARE_TITLE",
  SEO_USER_DATA_PAGE_TITLE = "SEO_USER_DATA_PAGE_TITLE",
  SEO_USER_DELETE_ACCOUNT_PAGE_DESCRIPTION = "SEO_USER_DELETE_ACCOUNT_PAGE_DESCRIPTION",
  SEO_USER_DELETE_ACCOUNT_PAGE_SHARE_DESCRIPTION = "SEO_USER_DELETE_ACCOUNT_PAGE_SHARE_DESCRIPTION",
  SEO_USER_DELETE_ACCOUNT_PAGE_SHARE_TITLE = "SEO_USER_DELETE_ACCOUNT_PAGE_SHARE_TITLE",
  SEO_USER_DELETE_ACCOUNT_PAGE_TITLE = "SEO_USER_DELETE_ACCOUNT_PAGE_TITLE",
  SEO_USER_INFO_PAGE_DESCRIPTION = "SEO_USER_INFO_PAGE_DESCRIPTION",
  SEO_USER_INFO_PAGE_SHARE_DESCRIPTION = "SEO_USER_INFO_PAGE_SHARE_DESCRIPTION",
  SEO_USER_INFO_PAGE_SHARE_TITLE = "SEO_USER_INFO_PAGE_SHARE_TITLE",
  SEO_USER_INFO_PAGE_TITLE = "SEO_USER_INFO_PAGE_TITLE",
  SEO_USER_PAGE_DESCRIPTION = "SEO_USER_PAGE_DESCRIPTION",
  SEO_USER_PAGE_SHARE_DESCRIPTION = "SEO_USER_PAGE_SHARE_DESCRIPTION",
  SEO_USER_PAGE_SHARE_TITLE = "SEO_USER_PAGE_SHARE_TITLE",
  SEO_USER_PAGE_TITLE = "SEO_USER_PAGE_TITLE",
  SEO_USER_PASSWORD_PAGE_DESCRIPTION = "SEO_USER_PASSWORD_PAGE_DESCRIPTION",
  SEO_USER_PASSWORD_PAGE_SHARE_DESCRIPTION = "SEO_USER_PASSWORD_PAGE_SHARE_DESCRIPTION",
  SEO_USER_PASSWORD_PAGE_SHARE_TITLE = "SEO_USER_PASSWORD_PAGE_SHARE_TITLE",
  SEO_USER_PASSWORD_PAGE_TITLE = "SEO_USER_PASSWORD_PAGE_TITLE",
  SIGNUP_ERROR_BODY_COMMON = "SIGNUP_ERROR_BODY_COMMON",
  SIGNUP_ERROR_BODY_EMAIL_EXIST = "SIGNUP_ERROR_BODY_EMAIL_EXIST",
  SIGNUP_ERROR_BODY_TEMPORARY = "SIGNUP_ERROR_BODY_TEMPORARY",
  SIGNUP_HEADER = "SIGNUP_HEADER",
  SITE_FOOTER_ITEM_PRIVACY_PREFERENCES = "SITE_FOOTER_ITEM_PRIVACY_PREFERENCES",
  SITE_HEADER_LOGO_TITLE = "SITE_HEADER_LOGO_TITLE",
  SITE_HEADER_MENU_ITEM_CREATE_ACCOUNT = "SITE_HEADER_MENU_ITEM_CREATE_ACCOUNT",
  SITE_HEADER_MENU_ITEM_LOGIN = "SITE_HEADER_MENU_ITEM_LOGIN",
  SITE_HEADER_MENU_ITEM_SIGN_OUT = "SITE_HEADER_MENU_ITEM_SIGN_OUT",
  SITE_HEADER_SEARCH_HEADER = "SITE_HEADER_SEARCH_HEADER",
  SITE_HEADER_SEARCH_LABEL = "SITE_HEADER_SEARCH_LABEL",
  SITE_HEADER_SEARCH_NO_RESULTS_HEADER = "SITE_HEADER_SEARCH_NO_RESULTS_HEADER",
  SITE_HEADER_SEARCH_NO_RESULTS_LABEL = "SITE_HEADER_SEARCH_NO_RESULTS_LABEL",
  SITE_HEADER_SEARCH_PLACEHOLDER = "SITE_HEADER_SEARCH_PLACEHOLDER",
  SITE_HEADER_SEARCH_PRE_HEADER = "SITE_HEADER_SEARCH_PRE_HEADER",
  SITE_HEADER_SEARCH_RECENT_SEARCHES_CLEAR = "SITE_HEADER_SEARCH_RECENT_SEARCHES_CLEAR",
  SITE_HEADER_SEARCH_RECENT_SEARCHES_HEADER = "SITE_HEADER_SEARCH_RECENT_SEARCHES_HEADER",
  SITE_HEADER_SEARCH_RECENT_SEARCHES_REMOVE_ITEM_ARIA_LABEL = "SITE_HEADER_SEARCH_RECENT_SEARCHES_REMOVE_ITEM_ARIA_LABEL",
  SITE_MANIFEST_NAME = "SITE_MANIFEST_NAME",
  SITE_MANIFEST_NAME_SHORT = "SITE_MANIFEST_NAME_SHORT",
  SLOGAN = "SLOGAN",
  START_PAGE_FEATURED_PODCAST_BUTTON = "START_PAGE_FEATURED_PODCAST_BUTTON",
  USER_DATA_MENU_LABEL = "USER_DATA_MENU_LABEL",
  USER_DATA_PAGE_BUTTON = "USER_DATA_PAGE_BUTTON",
  USER_DATA_PAGE_DESCRIPTION = "USER_DATA_PAGE_DESCRIPTION",
  USER_DATA_PAGE_ERROR_BODY_COMMON = "USER_DATA_PAGE_ERROR_BODY_COMMON",
  USER_DATA_PAGE_ERROR_BODY_TEMPORARY = "USER_DATA_PAGE_ERROR_BODY_TEMPORARY",
  USER_DATA_PAGE_ERROR_BODY_TOO_FREQUENTLY = "USER_DATA_PAGE_ERROR_BODY_TOO_FREQUENTLY",
  USER_DATA_PAGE_SUCCESS_BODY = "USER_DATA_PAGE_SUCCESS_BODY",
  USER_DATA_PAGE_TITLE = "USER_DATA_PAGE_TITLE",
  USER_DELETE_ACCOUNT_MENU_LABEL = "USER_DELETE_ACCOUNT_MENU_LABEL",
  USER_DELETE_ACCOUNT_PAGE_BUTTON = "USER_DELETE_ACCOUNT_PAGE_BUTTON",
  USER_DELETE_ACCOUNT_PAGE_ERROR_BODY_COMMON = "USER_DELETE_ACCOUNT_PAGE_ERROR_BODY_COMMON",
  USER_DELETE_ACCOUNT_PAGE_ERROR_BODY_TEMPORARY = "USER_DELETE_ACCOUNT_PAGE_ERROR_BODY_TEMPORARY",
  USER_DELETE_ACCOUNT_PAGE_ERROR_BODY_USER_PASSWORD_MISMATCH = "USER_DELETE_ACCOUNT_PAGE_ERROR_BODY_USER_PASSWORD_MISMATCH",
  USER_DELETE_ACCOUNT_PAGE_TEXT = "USER_DELETE_ACCOUNT_PAGE_TEXT",
  USER_DELETE_ACCOUNT_PAGE_TITLE = "USER_DELETE_ACCOUNT_PAGE_TITLE",
  USER_INFO_MENU_LABEL = "USER_INFO_MENU_LABEL",
  USER_INFO_PAGE_HEADER = "USER_INFO_PAGE_HEADER",
  USER_INFO_PAGE_TITLE = "USER_INFO_PAGE_TITLE",
  USER_INFO_RETURN_TO_MENU_BUTTON = "USER_INFO_RETURN_TO_MENU_BUTTON",
  USER_PASSWORD_MENU_LABEL = "USER_PASSWORD_MENU_LABEL",
  USER_PASSWORD_PAGE_ERROR_BODY_COMMON = "USER_PASSWORD_PAGE_ERROR_BODY_COMMON",
  USER_PASSWORD_PAGE_ERROR_BODY_TEMPORARY = "USER_PASSWORD_PAGE_ERROR_BODY_TEMPORARY",
  USER_PASSWORD_PAGE_ERROR_BODY_USER_PASSWORD_MISMATCH = "USER_PASSWORD_PAGE_ERROR_BODY_USER_PASSWORD_MISMATCH",
  USER_PASSWORD_PAGE_NEW_PASSWORD_LABEL = "USER_PASSWORD_PAGE_NEW_PASSWORD_LABEL",
  USER_PASSWORD_PAGE_NEW_PASSWORD_PLACEHOLDER = "USER_PASSWORD_PAGE_NEW_PASSWORD_PLACEHOLDER",
  USER_PASSWORD_PAGE_PASSWORD_LABEL = "USER_PASSWORD_PAGE_PASSWORD_LABEL",
  USER_PASSWORD_PAGE_PASSWORD_PLACEHOLDER = "USER_PASSWORD_PAGE_PASSWORD_PLACEHOLDER",
  USER_PASSWORD_PAGE_SUBMIT = "USER_PASSWORD_PAGE_SUBMIT",
  USER_PASSWORD_PAGE_SUCCESS_BODY = "USER_PASSWORD_PAGE_SUCCESS_BODY",
  USER_PASSWORD_PAGE_TITLE = "USER_PASSWORD_PAGE_TITLE",
  PLAYER_ARIA_LABEL = "PLAYER_ARIA_LABEL",
  PLAYER_ERROR_MESSAGE = "PLAYER_ERROR_MESSAGE",
  PLAYER_EXPAND = "PLAYER_EXPAND",
  PLAYER_COLLAPSE = "PLAYER_COLLAPSE",
  PLAYER_LIVE_BADGE = "PLAYER_LIVE_BADGE",
  PLAYER_CHAT_BUTTON = "PLAYER_CHAT_BUTTON",
  PLAYER_SKELETON = "PLAYER_SKELETON",
  PLAYER_NOW_PLAYING = "PLAYER_NOW_PLAYING",
  PLAYER_QUEUE_NEXT_HEADING = "PLAYER_QUEUE_NEXT_HEADING",
  PLAYER_QUEUE_EMPTY = "PLAYER_QUEUE_EMPTY",
  PLAYER_QUEUE_ITEM_MORE_BUTTON = "PLAYER_QUEUE_ITEM_MORE_BUTTON",
  PLAYER_SKIP_BACKWARD = "PLAYER_SKIP_BACKWARD",
  PLAYER_SKIP_FORWARD = "PLAYER_SKIP_FORWARD",
  PLAYER_SCRUBBER = "PLAYER_SCRUBBER",
  PLAYER_SCRUBBER_TIME_PLAYED = "PLAYER_SCRUBBER_TIME_PLAYED",
  PLAYER_SCRUBBER_TIME_LEFT = "PLAYER_SCRUBBER_TIME_LEFT",
  PLAYER_SCRUBBER_LIVE = "PLAYER_SCRUBBER_LIVE",
  PLAYER_BOTTOM_SHARE = "PLAYER_BOTTOM_SHARE",
  PLAYER_BOTTOM_PLAYBACK_RATE = "PLAYER_BOTTOM_PLAYBACK_RATE",
  PLAYER_BOTTOM_CHAT = "PLAYER_BOTTOM_CHAT",
  PLAYER_BOTTOM_AUTOPLAY = "PLAYER_BOTTOM_AUTOPLAY",
  PLAYER_QUEUE_MORE_MENU_EPISODE = "PLAYER_QUEUE_MORE_MENU_EPISODE",
  PLAYER_QUEUE_MORE_MENU_PODCAST = "PLAYER_QUEUE_MORE_MENU_PODCAST",
  PLAYER_QUEUE_MORE_MENU_MOVE_FIRST = "PLAYER_QUEUE_MORE_MENU_MOVE_FIRST",
  PLAYER_QUEUE_MORE_MENU_MOVE_LAST = "PLAYER_QUEUE_MORE_MENU_MOVE_LAST",
  PLAYER_QUEUE_MORE_MENU_REMOVE = "PLAYER_QUEUE_MORE_MENU_REMOVE",
  USER_USER_INFO_PAGE_ERROR_BODY_COMMON = "USER_USER_INFO_PAGE_ERROR_BODY_COMMON",
  USER_USER_INFO_PAGE_ERROR_BODY_TEMPORARY = "USER_USER_INFO_PAGE_ERROR_BODY_TEMPORARY",
  USER_USER_INFO_PAGE_ERROR_BODY_USER_EXIST = "USER_USER_INFO_PAGE_ERROR_BODY_USER_EXIST",
  USER_USER_INFO_PAGE_ERROR_BODY_USER_PASSWORD_MISMATCH = "USER_USER_INFO_PAGE_ERROR_BODY_USER_PASSWORD_MISMATCH",
  USER_USER_INFO_PAGE_ERROR_BODY_PROFILE_NAME_ALREADY_EXISTS = "USER_USER_INFO_PAGE_ERROR_BODY_PROFILE_NAME_ALREADY_EXISTS",
  USER_USER_INFO_PAGE_ERROR_BODY_EMAIL_ALREADY_EXISTS = "USER_USER_INFO_PAGE_ERROR_BODY_EMAIL_ALREADY_EXISTS",
  USER_USER_INFO_PAGE_FORM_BIRTHYEAR_LABEL = "USER_USER_INFO_PAGE_FORM_BIRTHYEAR_LABEL",
  USER_USER_INFO_PAGE_FORM_EMAIL_LABEL = "USER_USER_INFO_PAGE_FORM_EMAIL_LABEL",
  USER_USER_INFO_PAGE_FORM_PROFILENAME_LABEL = "USER_USER_INFO_PAGE_FORM_PROFILENAME_LABEL",
  USER_USER_INFO_PAGE_FORM_PROFILENAME_DESCRIPTION = "USER_USER_INFO_PAGE_FORM_PROFILENAME_DESCRIPTION",
  USER_USER_INFO_PAGE_FORM_PASSWORD_DESCRIPTION = "USER_USER_INFO_PAGE_FORM_PASSWORD_DESCRIPTION",
  USER_USER_INFO_PAGE_FORM_GENDER_LABEL = "USER_USER_INFO_PAGE_FORM_GENDER_LABEL",
  USER_USER_INFO_PAGE_FORM_SUBMIT = "USER_USER_INFO_PAGE_FORM_SUBMIT",
  USER_USER_INFO_PAGE_SUCCESS_BODY = "USER_USER_INFO_PAGE_SUCCESS_BODY",
  EPISODE_PROGRESS_COMPLETED = "EPISODE_PROGRESS_COMPLETED",
  CHAT_MEMBERS = "CHAT_MEMBERS",
  CHAT_ERROR = "CHAT_ERROR",
  CHAT_LOADING = "CHAT_LOADING",
  CHAT_EMPTY_LIST = "CHAT_EMPTY_LIST",
  HOME_PAGE_HEADING = "HOME_PAGE_HEADING",
  HISTORY_PAGE_HEADING = "HISTORY_PAGE_HEADING",
  HOME_PAGE_LIVE_EPISODES_HEADER = "HOME_PAGE_LIVE_EPISODES_HEADER",
  HOME_PAGE_FEED_EPISODES_HEADER = "HOME_PAGE_FEED_EPISODES_HEADER",
  HOME_PAGE_FEED_EPISODES_NO_EPISODES_HEADER = "HOME_PAGE_FEED_EPISODES_NO_EPISODES_HEADER",
  HOME_PAGE_FEED_RECOMMENDED_PODCASTS_HEADER = "HOME_PAGE_FEED_RECOMMENDED_PODCASTS_HEADER",
  HOME_PAGE_MENU_FEED = "HOME_PAGE_MENU_FEED",
  HOME_PAGE_MENU_HISTORY = "HOME_PAGE_MENU_HISTORY",
  FEATURE_CAROUSEL_SCROLL_DOT_ARIA_LABEL = "FEATURE_CAROUSEL_SCROLL_DOT_ARIA_LABEL",
  FEED_PAGE_RECOMMENDED_PODCASTS_GA_ID = "FEED_PAGE_RECOMMENDED_PODCASTS_GA_ID",
  FEED_PAGE_RECOMMENDED_PODCASTS_GA_NAME = "FEED_PAGE_RECOMMENDED_PODCASTS_GA_NAME",
  PODCAST_PAGE_SIMILAR_PODCASTS_GA_ID = "PODCAST_PAGE_SIMILAR_PODCASTS_GA_ID",
  PODCAST_PAGE_SIMILAR_PODCASTS_GA_NAME = "PODCAST_PAGE_SIMILAR_PODCASTS_GA_NAME",
  FEED_TITLE_TODAY = "FEED_TITLE_TODAY",
  FEED_TITLE_WEEK = "FEED_TITLE_WEEK",
  FEED_TITLE_MONTH = "FEED_TITLE_MONTH",
  QUEUE_FIRST = "QUEUE_FIRST",
  QUEUE_LAST = "QUEUE_LAST",
  QUEUE_REMOVE = "QUEUE_REMOVE",
}
