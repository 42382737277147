import { stringify } from "query-string";
import { Language } from "interfaces/interfaces";

// -----------------------------------------------------------------------------
// SEARCH AND FILTER PODCASTS
// -----------------------------------------------------------------------------

export interface ISearchAndFilterPodcastsResponseBody {
  results: {
    id: number;
    title: string;
    author: string;
    original: boolean;
    image: string;
    popularity: number;
    language_iso: string;
  }[];
}

export function getSearchAndFilterPodcastsUrl(
  language: Language,
  q: string
): string {
  const encodedSearchParams = stringify({ q });
  const search = encodedSearchParams ? `?${encodedSearchParams}` : "";
  return `/${language}/search${search}`;
}
