import classNames from "classnames";
import { I18nKey } from "locales/constants/I18nKey";
import React, { ReactElement } from "react";
import { useAudioProgress } from "components/Player/hooks/useAudioProgress";
import { formatDurationAsTimeDatePeriod } from "helpers/time";
import { useI18n } from "hooks/useI18n";
import { secondsToMMSS } from "./helpers/secondsToMMSS";
import styles from "./scrubber.module.scss";

export interface IProps {
  className?: string;
}

export const ScrubberLive = ({ className }: IProps): ReactElement => {
  const { currentTime } = useAudioProgress();
  const { i18n } = useI18n();
  return (
    <div className={classNames(styles.container, className)}>
      <button type="button" className={styles.timeButton} disabled>
        <span className="sr-only">
          {i18n(I18nKey.PLAYER_SCRUBBER_TIME_PLAYED)}{" "}
        </span>
        <time
          data-testid="player-maxi-timePlayed"
          className={styles.time}
          dateTime={formatDurationAsTimeDatePeriod(currentTime)}
        >
          {secondsToMMSS(currentTime)}
        </time>
      </button>
      <div className={styles.wrapper}>
        <div className={styles.progressTrack}>
          <div className={styles.progressBar} style={{ width: "100%" }} />
        </div>
        <label className="sr-only" htmlFor="player-scrubber">
          {i18n(I18nKey.PLAYER_SCRUBBER)}
        </label>
        <input
          id="player-scrubber"
          data-testid="player-maxi-scrubber"
          className={styles.range}
          type="range"
          min={0}
          max={1}
          step={1}
          value={1}
          disabled
        />
      </div>
      <button type="button" className={styles.timeButton} disabled>
        <span className={styles.time}>
          {i18n(I18nKey.PLAYER_SCRUBBER_LIVE)}
        </span>
      </button>
    </div>
  );
};
