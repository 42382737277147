import { podplayFetch } from "helpers/podplayFetch";
import { ICategory, Language } from "interfaces/interfaces";

// -----------------------------------------------------------------------------
// LIST CATEGORIES
// -----------------------------------------------------------------------------

export interface IListCategoriesResponseBody {
  results: ICategory[];
}

export const listCategories = async (language: Language) => {
  return podplayFetch<IListCategoriesResponseBody>(`/${language}/category`);
};

// -----------------------------------------------------------------------------
// CATEGORY
// -----------------------------------------------------------------------------

export interface IGetCategoryResponseBody {
  category: ICategory;
  limit: number;
  offset: number;
  total: number;
}

export const getCategory = async (language: Language, id: string | number) => {
  return podplayFetch<IGetCategoryResponseBody>(`/${language}/category/${id}`);
};
