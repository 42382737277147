import { useCallback, useEffect, useRef } from "react";
import { TPlayerEpisode } from "components/Player/context/PlayerContextProvider";
import { clearPersistedPlayerData } from "components/Player/helpers/persist";

/**
 * This callback will be called when the user is logging out.
 */
export const useBeforeLogout = (
  currentTime: number,
  duration: number,
  paused: boolean,
  episode: TPlayerEpisode | undefined,
  storeEpisodeProgress: (
    episode: TPlayerEpisode,
    currentTime: number,
    duration: number
  ) => Promise<void>
) => {
  // Store current time in ref to avoid updating callback too frequently - The
  // callback function will be passed on to player context.
  const currentTimeRef = useRef(currentTime);
  useEffect(() => {
    currentTimeRef.current = currentTime;
  });

  return useCallback(async () => {
    clearPersistedPlayerData();

    if (paused || !episode) {
      return;
    }

    await storeEpisodeProgress(episode, currentTimeRef.current, duration);
    // TODO: Old player called listening statistics here... should we keep that
    //  logic or maybe not worth it?
  }, [duration, episode, paused, storeEpisodeProgress]);
};
