import classNames from "classnames";
import Link from "next/link";
import React, { ReactElement, ReactNode, RefObject } from "react";
import styles from "./menuItem.module.scss";

export interface IProps {
  children: ReactNode;
}

export const MenuItem = ({ children }: IProps): ReactElement => {
  return <li className={styles.container}>{children}</li>;
};

export interface IPropsButton {
  onClick: () => void;
  disabled?: boolean;
  children: ReactNode;
  activeRef?: RefObject<HTMLLIElement>;
}

export const MenuItemButton = ({
  onClick,
  children,
  disabled,
  activeRef,
}: IPropsButton): ReactElement => {
  return (
    <li
      className={classNames(styles.container, {
        [styles.containerDisabled]: disabled,
      })}
      ref={activeRef}
    >
      <button
        className={styles.button}
        onClick={onClick}
        disabled={disabled}
        type="button"
      >
        {children}
      </button>
    </li>
  );
};

export interface IPropsLink {
  href: string;
  children: ReactNode;
  activeRef?: RefObject<HTMLLIElement>;
}

export const MenuItemLink = ({
  href,
  children,
  activeRef,
}: IPropsLink): ReactElement => {
  return (
    <li className={styles.container} ref={activeRef}>
      <Link href={href}>{children}</Link>
    </li>
  );
};
