import { trackGoal } from "fathom-client";
import { TPodcast } from "api/podplay/podcast";
import { ModalOrigin } from "components/Modals/context/ModalContextProvider";
import { TPlayerEpisode } from "components/Player/context/PlayerContextProvider";
import { hasGoogleAnalytics } from "helpers/hasGoogleAnalytics";
import { identifyAsUser } from "helpers/hotjar";

const FID_SIGNUP_VIA_FOLLOW = "WQQO2NWB";
const FID_SIGNUP_VIA_EPISODE = "QCXVYRHW";
const FID_SIGNUP_VIA_PAGE = "B0FYK5GG";
const FID_SIGNUP_VIA_LIVE = "1HVKHOMO";
const FID_SIGNUP_VIA_CHAT = "L4DVTXCQ";
const FID_SIGNUP = "OL20UMBA";

// Max length of event-parameter value, see: https://support.google.com/analytics/answer/9267744?hl=en
const EVENT_PARAMETER_MAX_LENGTH = 100;
const EVENT_NAME_MAX_LENGTH = 40;

export const limitEventParameterLength = (value: string): string => {
  return value.substring(0, EVENT_PARAMETER_MAX_LENGTH);
};

export const limitEventNameLength = (value: string): string => {
  return value.substring(0, EVENT_NAME_MAX_LENGTH);
};

export const setUser = (userId: string): void => {
  if (hasGoogleAnalytics()) {
    global.gtag("set", { user_id: userId });
  }
};

export const deleteUser = (): void => {
  if (hasGoogleAnalytics()) {
    global.gtag("set", { user_id: null });
  }
};

export const trackPlaybackStart = (
  episode: TPlayerEpisode,
  playbackRate: number
): void => {
  trackCustomEvent(CustomEventName.PLAYBACK_START, {
    podcast_name: episode.podcast.title,
    podcast_id: episode.podcast.id,
    episode_name: episode.title,
    episode_id: episode.id,
    bauer_content: episode.podcast.original,
    playback_rate: playbackRate,
  });
};

export const trackPlaybackStop = (
  episode: TPlayerEpisode,
  stopPositionSec: number
): void => {
  trackCustomEvent(CustomEventName.PLAYBACK_STOP, {
    podcast_name: episode.podcast.title,
    podcast_id: episode.podcast.id,
    episode_name: episode.title,
    episode_id: episode.id,
    bauer_content: episode.podcast.original,
    stopPositionSec,
  });
};

export const trackPlaybackSkipForward = (
  episode: TPlayerEpisode,
  currentPositionSec: number
): void => {
  trackCustomEvent(CustomEventName.PLAYBACK_SKIP_FORWARD, {
    podcast_name: episode.podcast.title,
    podcast_id: episode.podcast.id,
    episode_name: episode.title,
    episode_id: episode.id,
    bauer_content: episode.podcast.original,
    currentPositionSec,
  });
};

export const trackPlaybackSkipBackward = (
  episode: TPlayerEpisode,
  currentPositionSec: number
): void => {
  trackCustomEvent(CustomEventName.PLAYBACK_SKIP_BACKWARD, {
    podcast_name: episode.podcast.title,
    podcast_id: episode.podcast.id,
    episode_name: episode.title,
    episode_id: episode.id,
    bauer_content: episode.podcast.original,
    currentPositionSec,
  });
};

export const trackEpisodeShare = (
  episode: { title: string; id: number | string },
  podcast: { id: number; title: string; original: boolean }
): void => {
  trackCustomEvent(CustomEventName.EPISODE_SHARE, {
    podcast_name: podcast.title,
    podcast_id: podcast.id,
    episode_name: episode.title,
    episode_id: episode.id,
    bauer_content: podcast.original,
  });
};

export const trackPodcastShare = (podcast: {
  id: number;
  title: string;
  original: boolean;
}): void => {
  trackCustomEvent(CustomEventName.PODCAST_SHARE, {
    podcast_name: podcast.title,
    podcast_id: podcast.id,
    bauer_content: podcast.original,
  });
};

export const trackFollowPodcast = (podcast: TPodcast): void => {
  trackCustomEvent(CustomEventName.FOLLOW_PODCAST, {
    podcast_name: podcast.title,
    podcast_id: podcast.id,
    bauer_content: podcast.original,
  });
};

export const trackUnfollowPodcast = (podcast: TPodcast): void => {
  trackCustomEvent(CustomEventName.UNFOLLOW_PODCAST, {
    podcast_name: podcast.title,
    podcast_id: podcast.id,
    bauer_content: podcast.original,
  });
};

// NOTICE: Event name max length is 40, see https://support.google.com/analytics/answer/9267744?hl=en.
export enum CustomEventName {
  // Player events.
  PLAYBACK_START = "playback_start",
  PLAYBACK_STOP = "playback_stop",
  PLAYBACK_SKIP_FORWARD = "playback_skip_forward",
  PLAYBACK_SKIP_BACKWARD = "playback_skip_backward",

  // Modals.
  MODAL_AUTH_ERROR = "modal_auth_error",
  MODAL_CONSENTS = "modal_consents",
  MODAL_FORGOT_PASSWORD = "modal_forgot_password",
  MODAL_FORGOT_PASSWORD_SUCCESS = "modal_forgot_password_success",
  MODAL_LOGIN = "modal_login",
  // Login or Signup.
  MODAL_LOGIN_OR_SIGNUP = "modal_login_or_signup",
  MODAL_LOGIN_OR_SIGNUP_FOLLOW = "modal_login_or_signup_follow",
  MODAL_LOGIN_OR_SIGNUP_EPISODE = "modal_login_or_signup_episode",
  MODAL_LOGIN_OR_SIGNUP_PAGE = "modal_login_or_signup_page",
  MODAL_LOGIN_OR_SIGNUP_LIVE = "modal_login_or_signup_live",
  MODAL_LOGIN_OR_SIGNUP_CHAT = "modal_login_or_signup_chat",

  // Login Success.
  MODAL_LOGIN_SUCCESS_FOLLOW = "modal_login_success_follow",
  MODAL_LOGIN_SUCCESS_EPISODE = "modal_login_success_episode",
  MODAL_LOGIN_SUCCESS_PAGE = "modal_login_success_page",
  MODAL_LOGIN_SUCCESS_LIVE = "modal_login_success_live",
  MODAL_LOGIN_SUCCESS_CHAT = "modal_login_success_chat",

  // Signup Success.
  MODAL_SIGNUP_SUCCESS_FOLLOW = "modal_signup_success_follow",
  MODAL_SIGNUP_SUCCESS_EPISODE = "modal_signup_success_episode",
  MODAL_SIGNUP_SUCCESS_PAGE = "modal_signup_success_page",
  MODAL_SIGNUP_SUCCESS_LIVE = "modal_signup_success_live",
  MODAL_SIGNUP_SUCCESS_CHAT = "modal_signup_success_chat",

  MODAL_RESET_PASSWORD = "modal_reset_password",
  MODAL_SIGNUP = "modal_signup",
  MODAL_PROFILE_NAME = "modal_profile_name",
  MODAL_FOLLOW_ONBOARDING = "modal_follow_onboarding",

  // Follow podcast.
  FOLLOW_PODCAST = "follow_podcast",
  UNFOLLOW_PODCAST = "unfollow_podcast",

  // Share
  EPISODE_SHARE = "episode_share",
  PODCAST_SHARE = "podcast_share",
}

export enum UTMCampaign {
  SHARE = "share",
}

export const UTMSource = "podplay_web";

export const trackCustomEvent = (
  eventName: CustomEventName,
  params = {}
): void => {
  const validParams = Object.entries(params).reduce((obj, current) => {
    const [key, value] = current;
    obj[key] =
      typeof value === "string" ? limitEventParameterLength(value) : value;
    return obj;
  }, {});

  if (hasGoogleAnalytics()) {
    global.gtag("event", limitEventNameLength(eventName), {
      ...validParams,
    });
  }
};

export const trackSearch = (searchTerm: string): void => {
  if (hasGoogleAnalytics()) {
    global.gtag("event", "search", {
      search_term: searchTerm,
    });
  }
};

export const trackLogin = (): void => {
  // Track login in Hotjar.
  identifyAsUser();

  // Track login in Google Analytics.
  if (hasGoogleAnalytics()) {
    global.gtag("event", "login", {
      method: "Podplay",
    });
  }
};

export const trackSignup = (modalOrigin: ModalOrigin | undefined): void => {
  // This signup started by the user pressing an exclusive episode.
  if (modalOrigin === ModalOrigin.EPISODE) {
    trackGoal(FID_SIGNUP_VIA_EPISODE, 1);
  }

  // This signup started by the user pressing a follow button.
  if (modalOrigin === ModalOrigin.FOLLOW) {
    trackGoal(FID_SIGNUP_VIA_FOLLOW, 1);
  }

  // This signup started by the user pressing a link to an private page.
  if (modalOrigin === ModalOrigin.PAGE) {
    trackGoal(FID_SIGNUP_VIA_PAGE, 1);
  }

  // This signup started by the user pressing a live episode
  if (modalOrigin === ModalOrigin.LIVE) {
    trackGoal(FID_SIGNUP_VIA_LIVE, 1);
  }

  // This signup started by the user pressing a chat button.
  if (modalOrigin === ModalOrigin.CHAT) {
    trackGoal(FID_SIGNUP_VIA_CHAT, 1);
  }

  // The user opened the signup modal on its own.
  if (!modalOrigin) {
    trackGoal(FID_SIGNUP, 1);
  }

  if (hasGoogleAnalytics()) {
    global.gtag("event", "sign_up", {
      method: "Podplay",
    });
  }
};
