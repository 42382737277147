import { I18nKey } from "locales/constants/I18nKey";
import React, { ReactElement } from "react";
import { PlayIcon } from "components/Icons/PlayIcon";
import { PodcastIcon } from "components/Icons/PodcastIcon";
import { useI18n } from "hooks/useI18n";
import styles from "./skeleton.module.scss";

export const Skeleton = (): ReactElement => {
  const { i18n } = useI18n();
  return (
    <div data-testid="player-mini-skeleton" className={styles.container}>
      <div className={styles.imageWrapper}>
        <PodcastIcon size={24} />
      </div>
      <div className={styles.text}>{i18n(I18nKey.PLAYER_SKELETON)}</div>
      <div className={styles.button}>
        <PlayIcon size={24} />
      </div>
    </div>
  );
};
