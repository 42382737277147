import { lstatFetch } from "api/lstat/lstatFetch";
import { Method } from "api/podplay";
import { podplayAuthFetch, lstatAuthFetch } from "helpers/authFetch";
import { podplayFetch } from "helpers/podplayFetch";
import { Language } from "interfaces/interfaces";

export interface ICreateNewListeningSessionResponseBody {
  episode_id: number;
  episode_listening_session_id: string;
}

export interface IRegisterListeningSessionResponseBody {
  status: number;
}

export const createNewAnonymousListeningSession = async (
  language: Language,
  episodeId: number | string
) => {
  return podplayFetch<ICreateNewListeningSessionResponseBody>(
    `/${language}/episode/${episodeId}/new-listening-session`
  );
};

export const createNewUserListeningSession = async (
  language: Language,
  episodeId: number | string
) => {
  return podplayAuthFetch<ICreateNewListeningSessionResponseBody>(
    language,
    `/${language}/episode/${episodeId}/new-listening-session`
  );
};

export const registerAnonymousListeningSession = async (
  episode_listening_session_id: string,
  episode_duration_sec: number | null,
  start_position_sec: number,
  end_position_sec: number,
  timestamp: number,
  requestInit?: RequestInit
) => {
  return lstatFetch<IRegisterListeningSessionResponseBody>("/register", {
    method: Method.POST,
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      episode_listening_session_id,
      episode_duration_sec: Math.floor(episode_duration_sec || 0),
      listening_segments: [
        {
          start_position_sec: Math.floor(start_position_sec),
          end_position_sec: Math.floor(end_position_sec),
          timestamp: timestamp,
        },
      ],
    }),
    ...requestInit,
  });
};

export const registerUserListeningSession = async (
  language: Language,
  episode_listening_session_id: string,
  episode_duration_sec: number | null,
  start_position_sec: number,
  end_position_sec: number,
  timestamp: number,
  requestInit?: RequestInit
) => {
  return lstatAuthFetch<IRegisterListeningSessionResponseBody>(
    language,
    "/register",
    {
      method: Method.POST,
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        episode_listening_session_id,
        episode_duration_sec: Math.floor(episode_duration_sec || 0),
        listening_segments: [
          {
            start_position_sec: Math.floor(start_position_sec || 0),
            end_position_sec: Math.floor(end_position_sec),
            timestamp: timestamp,
          },
        ],
      }),
      ...requestInit,
    }
  );
};
