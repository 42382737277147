export class EpisodeUndefinedError extends Error {
  constructor() {
    super(
      'Episode undefined - This could happen if the "load" method was not called before other methods. It could also indicate a logical error in the player that should be corrected.'
    );

    this.name = "EpisodeUndefinedError";

    // Set the prototype explicitly.
    Object.setPrototypeOf(this, EpisodeUndefinedError.prototype);
  }
}
