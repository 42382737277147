import classNames from "classnames";
import { I18nKey } from "locales/constants/I18nKey";
import React, { ReactElement, useRef, useState } from "react";
import { ContextMenu, DesktopAlign } from "components/ContextMenu/ContextMenu";
import { MenuItemButton } from "components/ContextMenu/components/MenuItem/MenuItem";
import { AllInclusiveIcon } from "components/Icons/AllInclusiveIcon";
import { CheckIcon } from "components/Icons/CheckIcon";
import { ShareIcon } from "components/Icons/ShareIcon";
import { SpeedIcon } from "components/Icons/SpeedIcon";
import { usePlayer } from "components/Player/hooks/usePlayer";
import { trackEpisodeShare } from "helpers/analytics";
import { useI18n } from "hooks/useI18n";
import { useShare, createEpisodeShareData } from "hooks/useShare";
import { useTypedRouter } from "hooks/useTypedRouter";
import styles from "./bottomMenu.module.scss";

export const BottomMenu = (): ReactElement | null => {
  const {
    episode,
    playbackRate,
    setPlaybackRate,
    autoPlayNextTrack,
    setAutoPlayNextTrack,
  } = usePlayer();
  const { isShareAvailable, share } = useShare();
  const { i18n } = useI18n();
  const [showPlaybackRateMenu, setShowPlaybackRateMenu] = useState(false);
  const [playbackRateMenuAnchorElm, setPlaybackRateMenuAnchorElm] =
    useState<HTMLElement | null>(null);
  const activeRef = useRef(null);
  const { locale } = useTypedRouter();

  const onClickShare = async () => {
    if (!episode) {
      return;
    }
    trackEpisodeShare(episode, episode.podcast);
    await share(createEpisodeShareData(episode, episode.podcast, i18n, locale));
  };

  if (!episode) {
    return null;
  }

  const rates = [0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.1, 1.2, 1.3, 1.4, 1.5, 2];

  return (
    <div className={styles.container}>
      <button
        type="button"
        className={styles.button}
        onClick={onClickShare}
        disabled={!isShareAvailable}
      >
        <ShareIcon size={16} />
        <span className={styles.text}>{i18n(I18nKey.PLAYER_BOTTOM_SHARE)}</span>
      </button>

      <button
        type="button"
        className={classNames(styles.button, {
          [styles.buttonActive]: playbackRate !== 1,
        })}
        onClick={() => setShowPlaybackRateMenu(true)}
        aria-label={i18n(I18nKey.PLAYER_BOTTOM_PLAYBACK_RATE)}
        ref={setPlaybackRateMenuAnchorElm}
      >
        <SpeedIcon size={16} />
        <span className={styles.text}>{`${playbackRate}x`}</span>
      </button>
      {showPlaybackRateMenu && (
        <ContextMenu
          contextName={i18n(I18nKey.COMMON_PLAYBACK_RATE)}
          onClose={() => setShowPlaybackRateMenu(false)}
          desktopAlign={DesktopAlign.VERTICAL}
          anchorElm={playbackRateMenuAnchorElm}
          hideImage={true}
          activeRef={activeRef}
        >
          {rates.map((rate) => (
            <MenuItemButton
              key={rate}
              onClick={() => {
                setShowPlaybackRateMenu(false), setPlaybackRate(rate);
              }}
              activeRef={
                playbackRate === rate && rate > 1.2 ? activeRef : undefined
              }
            >
              {rate}x
              {playbackRate === rate && (
                <CheckIcon size={24} className={styles.checkIcon} />
              )}
            </MenuItemButton>
          ))}
        </ContextMenu>
      )}

      <button
        type="button"
        className={classNames(styles.button, {
          [styles.buttonActive]: autoPlayNextTrack,
        })}
        onClick={() => setAutoPlayNextTrack(!autoPlayNextTrack)}
      >
        <AllInclusiveIcon size={16} />
        <span className={styles.text}>
          {i18n(I18nKey.PLAYER_BOTTOM_AUTOPLAY)}
        </span>
      </button>
    </div>
  );
};
