import { stringify } from "query-string";
import { z } from "zod";
import { Method } from "api/podplay/index";
import { podplayAuthFetch } from "helpers/authFetch";
import { validateResponse } from "helpers/validateResponse";
import { IEpisode, IEpisodeProgress, Language } from "interfaces/interfaces";

export const ZEpisodeProgress = z.object({
  completed: z.boolean(),
  duration: z.number(),
  episode_id: z.number(),
  last_listened: z.number(),
  modified: z.number(),
  progress: z.number(),
});
export type TEpisodeProgress = z.infer<typeof ZEpisodeProgress>;

// -----------------------------------------------------------------------------
// SAVE EPISODE PROGRESS
// -----------------------------------------------------------------------------

export const saveEpisodeProgress = async (
  language: Language,
  id: number,
  completed: boolean,
  progress: number,
  duration: number
) => {
  return podplayAuthFetch<never>(
    language,
    `/${language}/episode/${id}/progress`,
    {
      method: Method.POST,
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        completed: completed,
        progress: Math.floor(progress),
        duration: Math.floor(duration),
      }),
    }
  );
};

// -----------------------------------------------------------------------------
// GET EPISODE PROGRESS BY EPISODE IDS
// -----------------------------------------------------------------------------

export const getEpisodeProgressByEpisodeIdsUrl = (
  language: Language,
  episodes: IEpisode[]
): string => {
  const search = stringify({
    episode_id: episodes.map((episode) => episode.id),
  });

  return `/${language}/progress/episode?${search}`;
};

export const ZGetEpisodeProgressByEpisodeIdsResponseBody = z.object({
  results: ZEpisodeProgress.array(),
});
export type TGetEpisodeProgressByEpisodeIdsResponseBody = z.infer<
  typeof ZGetEpisodeProgressByEpisodeIdsResponseBody
>;

export const getEpisodeProgressByEpisodeIds = async (
  language: Language,
  episodes: IEpisode[],
  requestInit?: RequestInit
) => {
  const response =
    await podplayAuthFetch<TGetEpisodeProgressByEpisodeIdsResponseBody>(
      language,
      getEpisodeProgressByEpisodeIdsUrl(language, episodes),
      requestInit
    );

  return validateResponse(
    response,
    ZGetEpisodeProgressByEpisodeIdsResponseBody
  );
};

// -----------------------------------------------------------------------------
// SAVE EPISODE HISTORY IN BATCH
// -----------------------------------------------------------------------------

export interface ISaveEpisodeHistoryInBatchResponseBody {
  result: TEpisodeProgress[];
}
export const ZSaveEpisodeHistoryInBatchResponseBody = z.object({
  result: ZEpisodeProgress.array(),
});
export type TSaveEpisodeHistoryInBatchResponseBody = z.infer<
  typeof ZSaveEpisodeHistoryInBatchResponseBody
>;

export const saveEpisodeHistoryInBatch = (
  language: Language,
  episodeProgress: IEpisodeProgress[],
  requestInit?: RequestInit
) => {
  return podplayAuthFetch<TSaveEpisodeHistoryInBatchResponseBody>(
    language,
    `/${language}/progress/sync`,
    {
      method: Method.PUT,
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ history: episodeProgress }),
      ...requestInit,
    }
  );
};

// -----------------------------------------------------------------------------
// GET LATEST EPISODE PROGRESS
// -----------------------------------------------------------------------------

type IGetLatestEpisodeProgressOptions = {
  limit?: number | string;
};

export const ZGetLatestEpisodeProgressResponseBody = z.object({
  results: ZEpisodeProgress.array(),
});
export type TGetLatestEpisodeProgressResponseBody = z.infer<
  typeof ZGetLatestEpisodeProgressResponseBody
>;

export function getLatestEpisodeProgressUrl(
  language: Language,
  options?: IGetLatestEpisodeProgressOptions
): string {
  const search = options ? `?${stringify(options)}` : "";
  return `/${language}/progress/latest${search}`;
}
