export const convertTimeStampToBirthyear = (date: number): number => {
  const birthyear = Intl.DateTimeFormat("default", {
    year: "numeric",
  }).format(new Date(date * 1000));
  return parseInt(birthyear, 10);
};

export const convertBirthyearToTimeStamp = (birthyear: number): number => {
  return Date.UTC(birthyear, 11, 31) / 1000;
};
