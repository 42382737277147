import * as Sentry from "@sentry/nextjs";
import { Schema, ZodError } from "zod";
import { getBrowserStorageItem, StorageItem } from "helpers/browserStorage";

const onError = (err: unknown, storageItem: StorageItem): void => {
  if (process.env.NODE_ENV === "development") {
    // eslint-disable-next-line no-console
    console.log({ storageItem, err });
  }
  Sentry.withScope((scope) => {
    scope.setExtras({
      storageItem,
      // Add errors to extras - Sentry will truncate the error message leaving
      // out important details.
      zodErrors: err instanceof ZodError ? err.errors : undefined,
    });
    Sentry.captureException(err);
  });
};

export const getParsedBrowserStorageItem = <S>(
  storageItem: StorageItem,
  schema: Schema<S>
): S | null => {
  const value = getBrowserStorageItem(storageItem);
  if (!value) {
    return null;
  }

  try {
    return schema.parse(JSON.parse(value));
  } catch (err) {
    onError(err, storageItem);
  }
  return null;
};
