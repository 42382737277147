import { Method } from "api/podplay/index";
import { TPodcast } from "api/podplay/podcast";
import { podplayAuthFetch } from "helpers/authFetch";
import { Language } from "interfaces/interfaces";

// -----------------------------------------------------------------------------
// FOLLOW PODCAST
// -----------------------------------------------------------------------------

export const followPodcast = (
  language: Language,
  podcastId: number,
  requestInit?: RequestInit
) => {
  return podplayAuthFetch<never>(
    language,
    `/${language}/podcast/${podcastId}/follow`,
    requestInit
  );
};

// -----------------------------------------------------------------------------
// UNFOLLOW PODCAST
// -----------------------------------------------------------------------------

export const unfollowPodcast = (
  language: Language,
  podcastId: number,
  requestInit?: RequestInit
) => {
  return podplayAuthFetch<never>(
    language,
    `/${language}/podcast/${podcastId}/unfollow`,
    requestInit
  );
};

// -----------------------------------------------------------------------------
// BATCH FOLLOW PODCAST
// -----------------------------------------------------------------------------

export const batchFollowPodcast = (
  language: Language,
  podcastIds: number[],
  requestInit?: RequestInit
) => {
  return podplayAuthFetch<never>(language, `/${language}/user/followed/sync`, {
    method: Method.PUT,
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      podcasts: podcastIds.map((podcastId) => ({
        modified: null,
        podcast_id: podcastId,
      })),
    }),
    ...requestInit,
  });
};

// -----------------------------------------------------------------------------
// LIST FOLLOWED PODCASTS
// -----------------------------------------------------------------------------

export const getListFollowedPodcastsUrl = (language: Language): string => {
  return `/${language}/user/followed`;
};

export interface IListFollowedPodcastsResponseBody {
  results: TPodcast[];
}

export const getListFollowedPodcasts = (
  language: Language,
  requestInit?: RequestInit
) => {
  return podplayAuthFetch<IListFollowedPodcastsResponseBody>(
    language,
    getListFollowedPodcastsUrl(language),
    requestInit
  );
};
