import { useEffect, useMemo } from "react";
import { isSSR } from "helpers/isSSR";

export const useMutationObserver = (
  target: Element | Node | null,
  callback: MutationCallback,
  options?: MutationObserverInit
): void => {
  const observer = useMemo(
    () =>
      !isSSR
        ? new MutationObserver((mutationRecord, mutationObserver) => {
            callback?.(mutationRecord, mutationObserver);
          })
        : null,
    [callback]
  );

  useEffect(() => {
    if (observer && target) {
      observer.observe(target, options || { childList: true });
      return () => observer.disconnect();
    }
  }, [target, observer, options]);
};
