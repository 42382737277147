import { useEffect, useRef } from "react";

// React 18 introduced a huge breaking change, when in Strict Mode, all
// components mount and unmount, then mount again. The reason for this is for
// paving the way for a feature that isn't in React yet, so as far as React 18
// is concerned, there is no reason.
//
// For React Hooks in React 18, this means a useEffect() with zero dependencies
// will be executed twice.
//
// Here is a custom hook that can be used instead of useEffect(), with zero
// dependencies, that will give the old (pre React 18) behaviour back, i.e.
// it works around the breaking change.
// https://dev.to/ag-grid/react-18-avoiding-use-effect-getting-called-twice-4i9e
export const useEffectOnce = (effect: () => void | (() => void)) => {
  const destroyFunc = useRef<void | (() => void)>();
  const calledOnce = useRef(false);
  const renderAfterCalled = useRef(false);

  if (calledOnce.current) {
    renderAfterCalled.current = true;
  }

  useEffect(() => {
    if (calledOnce.current) {
      return;
    }

    calledOnce.current = true;
    destroyFunc.current = effect();

    return () => {
      if (!renderAfterCalled.current) {
        return;
      }

      if (destroyFunc.current) {
        destroyFunc.current();
      }
    };
  }, []);
};
