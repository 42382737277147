import { hexToRgb, hslToRgb, rgbToHex, rgbToHsl } from "helpers/color";
import { TImgixPalette } from "helpers/fetchImagePalette";

export const DEFAULT_COLOR = "#808080";

export const getDominantImgixColor = (
  palette: TImgixPalette | undefined
): string => {
  if (!palette) {
    return DEFAULT_COLOR;
  }

  const paletteColors = palette.dominant_colors;

  const paletteColor =
    paletteColors.vibrant?.hex ||
    paletteColors.vibrant_light?.hex ||
    paletteColors.vibrant_dark?.hex ||
    paletteColors.muted?.hex ||
    paletteColors.muted_light?.hex ||
    paletteColors.muted_dark?.hex;

  if (!paletteColor) {
    return DEFAULT_COLOR;
  }

  // Return modified color if necessary.
  const rgb = hexToRgb(paletteColor);
  const hsl = rgbToHsl(rgb);
  // Saturation value is between 0 and 100.
  const maximumSaturation = 75;
  // Convert to 0 / no saturation if very light or dark. Colors are barely
  // noticeable at those ranges but when adjusting lightness the color could
  // become very visible. This logic takes care of that case.
  const adjustedSaturation = hsl.l >= 97 || hsl.l <= 10 ? 0 : hsl.s;
  // Lightness value is between 0 and 100.
  // Saturated colors does not need that much minimum lightness correction.
  // This calculation will give minimum lightness value between
  // 50 (if 0 saturation) and 25 (if 100 saturation).
  const minimumLightness = 50 - 25 * (adjustedSaturation / 100);
  const maximumLightness = 87.5;
  if (
    hsl.l < minimumLightness ||
    hsl.l > maximumLightness ||
    adjustedSaturation > maximumSaturation
  ) {
    return rgbToHex(
      hslToRgb({
        h: hsl.h,
        s: Math.min(adjustedSaturation, maximumSaturation),
        l: Math.max(Math.min(hsl.l, maximumLightness), minimumLightness),
      })
    );
  }

  // Color ok - Return original.
  return paletteColor;
};
