import * as Sentry from "@sentry/nextjs";
import { z } from "zod";
import { customFetch } from "./customFetch";

export const buildImagePaletteUrl = (image: string): string => {
  return `${process.env["NEXT_PUBLIC_IMGIX_BASE_URL"]}/${image}?rect=right,top,100,.8&palette=json`;
};

export const ZImgixColor = z.object({ hex: z.string() }).optional();

export const ZImgixPalette = z.object({
  dominant_colors: z.object({
    vibrant: ZImgixColor,
    muted_light: ZImgixColor,
    muted: ZImgixColor,
    vibrant_dark: ZImgixColor,
    vibrant_light: ZImgixColor,
    muted_dark: ZImgixColor,
  }),
});

export type TImgixPalette = z.infer<typeof ZImgixPalette>;

export const fetchImagePalette = async (
  image: string
): Promise<TImgixPalette | undefined> => {
  let palette: TImgixPalette | undefined;
  const imagePaletteUrl = buildImagePaletteUrl(image);
  const response = await customFetch<TImgixPalette>(imagePaletteUrl);

  if (response.ok) {
    palette = response.body;
  } else {
    return undefined;
  }

  // Generate a sentry error if the api response does not match
  // the expected format, but try use it anyway. We don't want to
  // kill the app just because imgix does a non-breaking change.
  try {
    return ZImgixPalette.parse(palette);
  } catch (error) {
    Sentry.captureException(error);
    return palette;
  }
};
