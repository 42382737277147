import React from "react";
import { getSVGProps, IIconProps } from "./helpers/getSVGProps";

export const Forward30Icon = (props: IIconProps) => (
  <svg {...getSVGProps(props)}>
    <path d="m21.06 13.587 1.109-1.696a.75.75 0 1 1 1.255.82l-2.443 3.736-3.735-2.444a.75.75 0 0 1 .821-1.255l1.498.98a8.84 8.84 0 0 0-17.611 1.086.75.75 0 0 1-1.5 0c0-5.71 4.628-10.339 10.338-10.339 5.295 0 9.66 3.98 10.267 9.112Z" />
    <path d="m7.022 15.772.342.684c.837-.333 1.746.063 1.746.927 0 .657-.486 1.116-1.161 1.116-.63 0-1.152-.423-1.278-1.017l-.945.324c.162 1.008 1.089 1.72 2.223 1.72 1.296 0 2.24-.883 2.24-2.116 0-1.089-.818-1.88-1.844-1.827l1.674-1.746v-.702H5.915v1.008h2.583l-1.476 1.63Z" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.213 19.525c1.503 0 2.385-1.278 2.385-3.24 0-1.962-.882-3.24-2.385-3.24-1.494 0-2.376 1.278-2.376 3.24 0 1.962.882 3.24 2.376 3.24Zm0-1.026c-.81 0-1.296-.738-1.296-2.214s.486-2.214 1.296-2.214c.819 0 1.305.738 1.305 2.214s-.486 2.214-1.305 2.214Z"
    />
  </svg>
);
