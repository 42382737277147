/**
 * Close expand when user changes url - But only on mobile.
 */
import { useRouter } from "next/router";
import { useEffect } from "react";

export const useAutoCloseExpandOnMobile = (
  isExpanded: boolean,
  onCollapse: () => void
): void => {
  const router = useRouter();

  useEffect(() => {
    if (!isExpanded) {
      return;
    }
    const handleRouteChange = () => {
      const mediaQuery = window.matchMedia("(min-width: 768px)");
      if (mediaQuery.matches) {
        // Don't close if desktop player.
        return;
      }
      onCollapse();
    };

    router.events.on("routeChangeStart", handleRouteChange);

    return () => {
      router.events.off("routeChangeStart", handleRouteChange);
    };
  }, [isExpanded, router.events, onCollapse]);
};
