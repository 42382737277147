import { createPodplayUserFromUserInfo } from "contexts/UserContextProvider/helpers/createPodplayUserFromUserInfo";
import React, {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useMemo,
} from "react";
import {
  createNewUser,
  ICreateNewUserResponseBody,
  ILoginUserResponseBody,
  IResetPasswordResponseBody,
  IUpdateUserInfoResponseBody,
  loginUser as loginUserApi,
  resetPassword as resetPasswordApi,
  TGender,
  updateUserInfo as updateUserInfoApi,
} from "api/podplay/user";
import { setBrowserStorageItem, StorageItem } from "helpers/browserStorage";
import { IErrorResponse, ISuccessResponse } from "helpers/customFetch";
import { getUserInfoFromAccessToken } from "helpers/getUserInfoFromAccessToken";
import { parseLocale } from "helpers/parseLocale";
import { setIsLoggedInCookie } from "helpers/setLoggedInCookie";
import { useTypedRouter } from "hooks/useTypedRouter";
import { IPodplayApiErrorBody, Language } from "interfaces/interfaces";
import { useNonUserUid } from "./hooks/useNonUserUid";
import { useUpdateLoggedInCookie } from "./hooks/useUpdateLoggedInCookie";
import { useUserInfo } from "./hooks/useUserInfo";

export interface IPodplayUser {
  birthyear: number | null;
  email: string;
  gender: TGender | null;
  language_iso: Language;
  profile_name: string | null;
  user_id: string;
}

export interface IUserContext {
  uid: string | undefined;
  user: IPodplayUser | null | undefined;
  login: (
    values: {
      email: string;
      password: string;
    },
    requestInit: RequestInit
  ) => Promise<
    | ISuccessResponse<ILoginUserResponseBody>
    | IErrorResponse<IPodplayApiErrorBody>
  >;
  signup: (
    values: {
      email: string;
      password: string;
      gender: TGender;
      birthdate: number;
      required_consents_accepted: boolean;
      profile_name: string | undefined;
    },
    requestInit: RequestInit
  ) => Promise<
    | ISuccessResponse<ICreateNewUserResponseBody>
    | IErrorResponse<IPodplayApiErrorBody>
  >;
  resetPassword: (
    values: {
      new_password: string;
      signature: string;
    },
    requestInit: RequestInit
  ) => Promise<
    | ISuccessResponse<IResetPasswordResponseBody>
    | IErrorResponse<IPodplayApiErrorBody>
  >;
  update: (
    values:
      | {
          // Password is required when it's possible to update mail.
          password: string;
          email?: string | undefined;
          birthyear?: string | undefined;
          gender?: TGender | undefined;
          profile_name?: string | undefined;
        }
      | {
          // No need to send in password if no email update.
          password?: undefined;
          email?: undefined;
          birthyear?: string | undefined;
          gender?: TGender | undefined;
          profile_name?: string | undefined;
        },
    requestInit: RequestInit
  ) => Promise<
    | ISuccessResponse<IUpdateUserInfoResponseBody>
    | IErrorResponse<IPodplayApiErrorBody>
  >;
}

export const UserContext = createContext<IUserContext | undefined>(undefined);
UserContext.displayName = "UserContext";

const persistLoggedIn = (accessToken: string, refreshToken: string) => {
  setBrowserStorageItem(StorageItem.AUTH_ACCESS, accessToken);
  setBrowserStorageItem(StorageItem.AUTH_REFRESH, refreshToken);
  setIsLoggedInCookie(true);
};

export const UserContextProvider = (props: { children: ReactNode }) => {
  const nonUserUid = useNonUserUid();
  const { userInfo, updateUserInfo } = useUserInfo();
  const { locale } = useTypedRouter();
  const { language } = parseLocale(locale);

  useUpdateLoggedInCookie(userInfo === undefined ? userInfo : !!userInfo);

  /**
   * We are using the tokens that various endpoint returns. This helper
   * function makes sure we are handling them in the same way.
   */
  const onTokenResponse = useCallback(
    async (
      response:
        | ISuccessResponse<{ access_token: string; refresh_token: string }>
        | IErrorResponse
    ) => {
      if (!response.ok) {
        return;
      }
      persistLoggedIn(response.body.access_token, response.body.refresh_token);
      await updateUserInfo(
        getUserInfoFromAccessToken(response.body.access_token),
        // NOTICE: Revalidate here - We're parsing the access token to get user
        // info data immediately, but it's safer to fetch via the user info
        // endpoint.
        true
      );
    },
    [updateUserInfo]
  );

  // Login.
  const login: IUserContext["login"] = useCallback(
    async (values, requestInit) => {
      const response = await loginUserApi(language, values, requestInit);
      await onTokenResponse(response);
      return response;
    },
    [onTokenResponse, language]
  );

  // Signup.
  const signup: IUserContext["signup"] = useCallback(
    async (values, requestInit) => {
      const response = await createNewUser(language, values, requestInit);
      await onTokenResponse(response);
      return response;
    },
    [onTokenResponse, language]
  );

  // Reset password.
  const resetPassword: IUserContext["resetPassword"] = useCallback(
    async (values, requestInit) => {
      const response = await resetPasswordApi(language, values, requestInit);
      await onTokenResponse(response);
      return response;
    },
    [onTokenResponse, language]
  );

  const update: IUserContext["update"] = useCallback(
    async (values, requestInit) => {
      const response = await updateUserInfoApi(language, values, requestInit);
      if (response.ok) {
        // NOTICE: Don't revalidate here - Updated values are just fetched.
        await updateUserInfo(response.body, false);
      }
      return response;
    },
    [language, updateUserInfo]
  );

  // Context value.
  const contextValue = useMemo<IUserContext>(
    () => ({
      uid: userInfo?.user_id || nonUserUid,
      user: userInfo ? createPodplayUserFromUserInfo(userInfo) : userInfo,
      login,
      signup,
      resetPassword,
      update,
    }),
    [login, resetPassword, signup, nonUserUid, update, userInfo]
  );

  return (
    <UserContext.Provider value={contextValue}>
      {props.children}
    </UserContext.Provider>
  );
};

export const useUser = (): IUserContext => {
  const context = useContext(UserContext);
  if (!context) {
    throw new Error("Can't find User context provider.");
  }
  return context;
};
