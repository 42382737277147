/**
 * Add queries. Works on both absolute and relative url.
 */
export const addQueryParameters = (
  url: string,
  queries: Record<string, string>
): string => {
  const entries = Object.entries(queries);
  if (!entries.length) {
    return url;
  }
  const parts = url.split("?");
  const searchParams = new URLSearchParams(parts[1]);
  entries.forEach(([name, value]) => {
    searchParams.append(name, value);
  });
  return `${parts[0]}?${searchParams.toString()}`;
};
