import { useCallback, useEffect, useMemo, useState } from "react";
import useSWR from "swr";
import useSWRMutation from "swr/mutation";
import { getUserInfoUrl, TUserInfo, ZUserInfo } from "api/podplay/user";
import { getSuccessResponseBody } from "components/Player/helpers/getSuccessResponseBody";
import { podplayAuthFetch } from "helpers/authFetch";
import {
  getBrowserStorageItem,
  setBrowserStorageItem,
  StorageItem,
} from "helpers/browserStorage";
import { getParsedBrowserStorageItem } from "helpers/getParsedBrowserStorageItem";
import { getUserInfoFromAccessToken } from "helpers/getUserInfoFromAccessToken";
import { parseLocale } from "helpers/parseLocale";
import { validateResponse } from "helpers/validateResponse";
import { useIsHydrated } from "hooks/useIsHydrated";
import { useTypedRouter } from "hooks/useTypedRouter";

const getPersistedUserInfo = (): TUserInfo | undefined => {
  // Server side
  if (typeof window === "undefined") {
    return undefined;
  }
  // Get user info from persisted data.
  const accessToken = getBrowserStorageItem(StorageItem.AUTH_ACCESS);
  if (accessToken) {
    // NOTICE: Some users might not have persisted "user info" object set
    // (it was added beginning of 2023). Fallback to "user info" built in
    // "access token" to guarantee a value if logged in.
    return (
      getParsedBrowserStorageItem(StorageItem.USER_INFO, ZUserInfo) ||
      getUserInfoFromAccessToken(accessToken)
    );
  }
  // Default to undefined.
  return undefined;
};

interface IUseUserInfo {
  userInfo: TUserInfo | null | undefined;
  updateUserInfo: (user: TUserInfo, revalidate: boolean) => Promise<void>;
}

export const useUserInfo = (): IUseUserInfo => {
  const { locale } = useTypedRouter();
  const { language } = parseLocale(locale);
  const [shouldFetch, setShouldFetch] = useState(false);
  const isHydrated = useIsHydrated();

  // User info.
  const persistedUserInfo = useMemo(() => getPersistedUserInfo(), []);
  const userInfoUrl = getUserInfoUrl(language);
  const { data: userInfo } = useSWR(
    shouldFetch ? userInfoUrl : null,
    (url) =>
      podplayAuthFetch<TUserInfo>(language, url)
        .then((response) => validateResponse(response, ZUserInfo))
        .then(getSuccessResponseBody),
    { fallbackData: persistedUserInfo }
  );
  const { trigger } = useSWRMutation<
    unknown,
    unknown,
    string,
    { userInfo: TUserInfo }
  >(userInfoUrl, (_, extra) => () => extra.arg.userInfo);

  // Initial check if we are logged in and should start to fetch user info.
  useEffect(() => {
    setShouldFetch(!!getBrowserStorageItem(StorageItem.AUTH_REFRESH));
  }, []);

  // Persist user info.
  useEffect(() => {
    if (!userInfo) {
      return;
    }
    setBrowserStorageItem(StorageItem.USER_INFO, JSON.stringify(userInfo));
  }, [userInfo]);

  /**
   * This callback should be called if user goes from "not logged in" to
   * "logged in" or if the "user info" gets updated.
   */
  const updateUserInfo: IUseUserInfo["updateUserInfo"] = useCallback(
    async (userInfo, revalidate) => {
      setShouldFetch(true);
      await trigger({ userInfo }, { revalidate, populateCache: true });
    },
    [trigger]
  );

  if (!isHydrated) {
    return {
      // NOTICE: Return "undefined" on server side and first client render.
      userInfo: undefined,
      updateUserInfo,
    };
  }

  return {
    // NOTICE: We should return "userInfo" or "null" after client side
    // hydration.
    userInfo: userInfo || null,
    updateUserInfo,
  };
};
