import React, { ReactElement } from "react";
import { useAudioBuffered } from "components/Player/hooks/useAudioBuffered";
import { usePlayer } from "components/Player/hooks/usePlayer";
import styles from "./bufferProgressBar.module.scss";

export const BufferProgressBar = (): ReactElement | null => {
  const { duration } = usePlayer();
  const buffered = useAudioBuffered();

  // Return null if we don't have a buffer or a duration value
  if (!buffered?.length || isNaN(duration) || duration <= 0) {
    return null;
  }

  const segments: { left: string; width: string }[] = [];
  for (let i = 0; i < buffered.length; i++) {
    const start = buffered.start(i);
    const end = buffered.end(i);
    const secondsPerPercent = 100 / duration;

    const left = `${Math.round(Math.max(secondsPerPercent * start, 0))}%`;
    const width = `${Math.round(
      Math.max(secondsPerPercent * (end - start), 0)
    )}%`;

    segments.push({ left, width });
  }

  return (
    <>
      {segments.map((buffer, index) => (
        <div key={index} className={styles.buffer} style={buffer} />
      ))}
    </>
  );
};
