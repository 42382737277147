import classNames from "classnames";
import { I18nKey } from "locales/constants/I18nKey";
import React, { MouseEventHandler, ReactElement, useCallback } from "react";
import { useChat } from "components/Chat/context/chatContext";
import { ChatButton } from "components/ChatButton/ChatButton";
import { ChatIcon } from "components/Icons/ChatIcon";
import { LoadingIcon } from "components/Icons/LoadingIcon";
import { PauseIcon } from "components/Icons/PauseIcon";
import { PlayIcon } from "components/Icons/PlayIcon";
import { Skeleton } from "components/Player/components/Mini/components/Skeleton/Skeleton";
import { ErrorMessage } from "components/Player/components/common/ErrorMessage/ErrorMessage";
import {
  isPlayerLiveEpisode,
  isPlayerNormalEpisode,
} from "components/Player/context/PlayerContextProvider";
import { useAudioProgress } from "components/Player/hooks/useAudioProgress";
import { usePlayer } from "components/Player/hooks/usePlayer";
import { PodcastImage } from "components/PodcastImage/PodcastImage";
import { useI18n } from "hooks/useI18n";
import styles from "./mini.module.scss";

export interface IProps {
  className?: string;
}

export const Mini = ({ className }: IProps): ReactElement => {
  const { closeChat } = useChat();

  const {
    episode,
    error,
    pause,
    paused,
    play,
    isBuffering,
    setIsExpanded,
    color,
  } = usePlayer();

  const { i18n } = useI18n();
  const { currentTime, duration, percent } = useAudioProgress();

  const onPlayPauseButtonClick: MouseEventHandler<HTMLButtonElement> =
    useCallback(
      (e) => {
        e.stopPropagation();
        (paused ? play : pause)();
      },
      [paused, pause, play]
    );

  if (error) {
    return <ErrorMessage className={styles.errorMessage} showClose={true} />;
  }

  const progressFillWidth = `${percent}%`;

  const handleExpandPlayerClick = () => {
    closeChat();
    setIsExpanded(true);
  };

  return (
    <div
      data-testid="player-mini"
      className={classNames(className, styles.container)}
      style={{ background: color }}
    >
      <div className={styles.colorFade} />
      <div className={styles.border} />
      {!episode ? (
        <Skeleton />
      ) : (
        <div className={styles.itemsWrapper} data-testid="player-mini-episode">
          <button
            type="button"
            data-testid="expand-player"
            onClick={handleExpandPlayerClick}
            aria-label={i18n(I18nKey.PLAYER_EXPAND)}
            className={styles.expandButton}
          >
            <PodcastImage
              className={styles.image}
              src={episode.podcast.image}
              alt={episode.podcast.title}
              width={44}
              height={44}
              key={episode.podcast.image}
            />
            {isPlayerNormalEpisode(episode) ? (
              <div className={styles.title}>{episode.title}</div>
            ) : (
              <div className={styles.liveWrapper}>
                <div className={styles.onAir}>
                  {i18n(I18nKey.PLAYER_LIVE_BADGE)}
                </div>
                <div className={styles.liveTitle}>{episode.title}</div>
              </div>
            )}
          </button>
          {isPlayerLiveEpisode(episode) && episode.chatEnabled && (
            <ChatButton
              podcast={episode.podcast}
              className={styles.chatButton}
              chatProvider={episode.chatProvider}
            >
              <ChatIcon size={18} />
            </ChatButton>
          )}
          <button
            type="button"
            data-testid={
              paused ? "player-mini-playButton" : "player-mini-pauseButton"
            }
            className={styles.playPauseButton}
            onClick={onPlayPauseButtonClick}
            aria-label={
              paused ? i18n(I18nKey.COMMON_PLAY) : i18n(I18nKey.COMMON_PAUSE)
            }
          >
            {isBuffering ? (
              <LoadingIcon
                size={24}
                className={styles.loadingIcon}
                data-testid="player-mini-buffering"
              />
            ) : paused ? (
              <PlayIcon size={24} />
            ) : (
              <PauseIcon size={24} />
            )}
          </button>
        </div>
      )}
      <div className={styles.progressWrapper}>
        {episode && isPlayerLiveEpisode(episode) ? (
          <div className={styles.progressFill} style={{ width: "100%" }} />
        ) : (
          <>
            <div
              className={styles.progressFill}
              style={{ width: progressFillWidth }}
            />
            <progress
              className="sr-only"
              value={currentTime}
              max={duration}
              data-testid="player-mini-progress"
            />
          </>
        )}
      </div>
    </div>
  );
};
