export const replaceTemplate = (
  str: string | undefined,
  replace: Record<string, string | undefined>
): string => {
  return str
    ? str.replace(
        /{{(\w*)}}/g,
        (org, captured) => replace[captured] || org || ""
      )
    : "";
};
