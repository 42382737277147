import { useUser } from "contexts/UserContextProvider/UserContextProvider";
import { MutableRefObject, useCallback, useEffect, useRef } from "react";
import { TEpisodeProgress } from "api/podplay/history";
import { IPlayerContext } from "components/Player/context/PlayerContextProvider";
import { createPlayerNormalEpisode } from "components/Player/helpers/createPlayerNormalEpisode";
import { IUseAudioData } from "components/Player/hooks/useAudio";
import { getBrowserStorageItem, StorageItem } from "helpers/browserStorage";

/**
 * Returns a function to load the first episode in the queue. If no
 * episodes it returns undefined. This function should only be used from
 * "PlayerContextProvider" where we can't use the player context and instead
 * need to send in dependencies as props.
 */
export const useNextTrackRef = (
  loadRef: MutableRefObject<IUseAudioData["load"] | undefined>,
  queue: IPlayerContext["queue"],
  queueEpisodeProgress: Record<number, TEpisodeProgress | undefined>,
  removeFromQueue: IPlayerContext["removeFromQueue"],
  autoPlay: boolean
): MutableRefObject<() => Promise<void>> => {
  const { user, uid } = useUser();
  // Callback.
  const nextTack = useCallback(async () => {
    if (!uid || user === undefined) {
      return;
    }
    const firstItem = queue[0];

    if (!firstItem) {
      return;
    }

    const { episode, podcast } = firstItem;

    removeFromQueue(episode.id);

    // Load.
    await loadRef.current?.(
      createPlayerNormalEpisode({
        episode,
        podcast,
        episodeProgress: queueEpisodeProgress[episode.id],
        autoPlay,
        uid,
        user,
        sourcePointConsents: getBrowserStorageItem(
          StorageItem.CONSENTS_SOURCE_POINT
        ),
      })
    );
  }, [
    uid,
    user,
    queue,
    removeFromQueue,
    loadRef,
    queueEpisodeProgress,
    autoPlay,
  ]);

  // NOTICE: Use ref because in the end it's the MediaSession that going to use
  // this function, and it needs a ref.
  const nextTrackRef = useRef(nextTack);

  // Update ref.
  useEffect(() => {
    nextTrackRef.current = nextTack;
  });

  // Return.
  return nextTrackRef;
};
