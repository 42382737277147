import { MutableRefObject } from "react";
import { buildAbsoluteImgixUrl } from "helpers/url";

export interface IInitProps {
  title: string;
  artist: string;
  image: string;
  // NOTICE: Use ref object for functions. Once the function is "bound" to the
  // media session, the variables outside that function will not be updated.
  // I.e. variables used by the function will always be the same as the time
  // this function was called.
  play: MutableRefObject<() => Promise<void>>;
  pause: MutableRefObject<() => void>;
  skip?: MutableRefObject<(offset: number) => void>;
  seekTo?: MutableRefObject<(target: number) => void>;
  nextTrack?: MutableRefObject<() => Promise<void>>;
}
export const initiateMediaSession = ({
  title,
  artist,
  image,
  play,
  pause,
  skip,
  seekTo,
  nextTrack,
}: IInitProps): void => {
  if (!("mediaSession" in navigator)) {
    return;
  }
  navigator.mediaSession.metadata = new MediaMetadata({
    title,
    artist,
    album: "",
    artwork: image
      ? [
          // NOTICE: iOS 16.0 seems to take the first image specified. Put the
          // largest one first to avoid blurriness on home screen.
          // The MediaSession specification doesn't mention anything about the
          // order, all examples goes from small to large though.
          {
            src: buildAbsoluteImgixUrl(image, "?fm=jpg&w=1024&h=1024"),
            sizes: "1024x1024",
            type: "image/jpeg",
          },
          {
            src: buildAbsoluteImgixUrl(image, "?fm=jpg&w=128&h=128"),
            sizes: "128x128",
            type: "image/jpeg",
          },
          {
            src: buildAbsoluteImgixUrl(image, "?fm=jpg&w=256&h=256"),
            sizes: "256x256",
            type: "image/jpeg",
          },
          {
            src: buildAbsoluteImgixUrl(image, "?fm=jpg&w=512&h=512"),
            sizes: "512x512",
            type: "image/jpeg",
          },
        ]
      : undefined,
  });

  const actionHandlers: [MediaSessionAction, MediaSessionActionHandler][] = [
    ["play", async () => await play.current()],
    ["pause", () => pause.current()],
  ];

  if (skip) {
    actionHandlers.push([
      "seekbackward",
      (details) => {
        const offset = (details?.seekOffset || 15) * -1;
        skip.current(offset);
      },
    ]);

    actionHandlers.push([
      "seekforward",
      (details) => {
        const offset = details?.seekOffset || 30;
        skip.current(offset);
      },
    ]);
  }

  if (seekTo) {
    actionHandlers.push([
      "seekto",
      (details) => {
        if (details?.seekTime) {
          seekTo.current(details?.seekTime);
        }
      },
    ]);
  }

  if (nextTrack) {
    actionHandlers.push([
      "nexttrack",
      () => {
        nextTrack.current();
      },
    ]);
  }

  for (const [action, handler] of actionHandlers) {
    try {
      navigator.mediaSession.setActionHandler(action, handler);
    } catch (error) {
      // Will throw if media action is not supported yet.
      if (process.env.NODE_ENV === "development") {
        // eslint-disable-next-line no-console
        console.log(error);
      }
    }
  }
};

export const clearMediaSession = (): void => {
  if (!("mediaSession" in navigator)) {
    return;
  }
  navigator.mediaSession.metadata = null;
};

const isSetPositionStateSupported = (): boolean => {
  // Check mediaSession.
  if (!("mediaSession" in navigator)) {
    if (process.env.NODE_ENV !== "test") {
      // eslint-disable-next-line no-console
      console.log("mediaSession not supported");
    }
    return false;
  }
  // Check setPositionState.
  if (typeof navigator.mediaSession.setPositionState === "undefined") {
    // eslint-disable-next-line no-console
    console.log("mediaSession.setPositionState not supported");
    return false;
  }
  // Everything is ok.
  return true;
};

/**
 * Update media session position state.
 * @param position Position in seconds.
 * @param duration Duration in seconds or positive infinite.
 * @param playbackRate Playback rate.
 * @throws Throws if duration is NaN or less than 1 or if playbackRate is 0.
 */
export const updateMediaSessionPosition = (
  position: number,
  duration: number,
  playbackRate: number
): void => {
  // Some old browser doesn't support "setPositionState".
  if (!isSetPositionStateSupported()) {
    return;
  }

  try {
    navigator.mediaSession.setPositionState({
      duration,
      playbackRate,
      position,
    });
  } catch (err) {
    // NOTICE: Throws when setting invalid values. Log to have a chance to
    // figure out why it happened. Console log will be picked up by Cypress.
    // eslint-disable-next-line no-console
    console.log("Error when calling setPositionState", {
      position,
      duration,
      playbackRate,
    });
    // Rethrow original error.
    throw err;
  }
};

/**
 * Clears media session position.
 */
export const clearMediaSessionPosition = () => {
  // Some old browser doesn't support "setPositionState".
  if (!isSetPositionStateSupported()) {
    return;
  }
  navigator.mediaSession.setPositionState(undefined);
};
