import { trackGoal } from "fathom-client";
import { TPlayerEpisode } from "components/Player/context/PlayerContextProvider";
import { getUUIDv4 } from "helpers/getUUIDv4";
import { hasFacebookPixel } from "helpers/hasFacebookPixel";
import { hasGoogleAnalytics } from "helpers/hasGoogleAnalytics";
import { limitEventParameterLength } from "./analytics";

const FID_THIRDPARTY_EPISODE_STARTED = "GZDNQGIW";
const FID_PODPLAY_EPISODE_STARTED = "5ADSPLS1";

export const ItemList = {
  SEARCH_RESULT: {
    id: "search_result",
    name: "Search result",
  },
  SEARCH_HISTORY: {
    id: "search_history",
    name: "Search history",
  },
  // NOTICE: Prismic blocks get their name and id from the api response.
};

enum ItemCategory {
  PODPLAY = "Podplay",
  OTHER = "Other",
}

export interface IItemList {
  name: string | null;
  id: string;
}

const currency = "EUR";

// Store latest clicked item list to make it possible to add that data to
// "view_item" and "purchase" event. This is needed to be able to connect those
// events to "view_item_list" and "select_item" events. Hopefully GA4 will do
// it automatically in the future.
// NOTICE: This is also a naive solution - It doesn't take into account that the
// user can use the browser forward/back buttons.
let latestClickedItemList: IItemList | undefined;
const latestClickedItemListFallback = { name: "No previous list", id: null };

export const setLatestClickedItemList = (
  itemList: IItemList | undefined
): void => {
  latestClickedItemList = itemList ? { ...itemList } : undefined;
};

interface IPodcastItemProps {
  item_name: string;
  item_id: number;
  price: number;
  item_brand: string;
  item_category: ItemCategory;
}

export const getItemPropsFromPodcast = (podcast: {
  id: number;
  title: string;
  original: boolean;
  author: string;
}): IPodcastItemProps => ({
  item_name: limitEventParameterLength(podcast.title),
  item_id: podcast.id,
  price: podcast.original ? 1 : 0,
  item_brand: limitEventParameterLength(podcast.author),
  item_category: podcast.original ? ItemCategory.PODPLAY : ItemCategory.OTHER,
});

interface IEpisodeItemProps {
  item_variant: string;
}

export const getItemPropsFromEpisode = (episode: {
  title: string;
}): IEpisodeItemProps => ({
  item_variant: limitEventParameterLength(episode.title),
});

/**
 * Measure product/item list views/impressions, see https://developers.google.com/gtagjs/reference/ga4-events#view_item_list
 */
export const podcastListViewed = (itemList: IItemList): void => {
  if (hasGoogleAnalytics()) {
    global.gtag("event", "view_item_list", {
      item_list_name: itemList.name,
      item_list_id: itemList.id,
      // NOTICE: Large podcast list result in too large payload message from GA.
      // Most noticeable on collection pages and search results.
      // Don't send "items" as a solution until we know if we need that data in
      // the reports.
    });
  }
};

export const episodeListViewed = (itemList: IItemList): void => {
  if (hasGoogleAnalytics()) {
    global.gtag("event", "view_item_list", {
      item_list_name: itemList.name,
      item_list_id: itemList.id,
      // NOTICE: Large item list result in too large payload message from GA.
      // Most noticeable on collection pages and search results.
      // Don't send "items" as a solution until we know if we need that data in
      // the reports.
    });
  }
};

/**
 * Measure product item list click, see https://developers.google.com/gtagjs/reference/ga4-events#select_item
 */
export const podcastInPodcastListClicked = (
  itemList: IItemList,
  podcast: {
    id: number;
    title: string;
    original: boolean;
    author: string;
  },
  index: number
): void => {
  // Store clicked item list.
  setLatestClickedItemList(itemList);

  if (hasGoogleAnalytics()) {
    global.gtag("event", "select_item", {
      items: [
        {
          item_list_name: itemList.name,
          item_list_id: itemList.id,
          index,
          currency,
          quantity: 1,
          ...getItemPropsFromPodcast(podcast),
        },
      ],
    });
  }
};

export const episodeInEpisodeListClicked = (
  itemList: IItemList,
  item: {
    podcast: {
      id: number;
      title: string;
      author: string;
      original: boolean;
    };
    episode: {
      title: string;
    };
  },
  index: number
): void => {
  // Store clicked item list.
  setLatestClickedItemList(itemList);

  if (hasGoogleAnalytics()) {
    global.gtag("event", "select_item", {
      items: [
        {
          item_list_name: itemList.name,
          item_list_id: itemList.id,
          index,
          currency,
          quantity: 1,
          ...getItemPropsFromPodcast(item.podcast),
          ...getItemPropsFromEpisode(item.episode),
        },
      ],
    });
  }
};

/**
 * Measure item viewed, see https://developers.google.com/gtagjs/reference/ga4-events#view_item
 */
export const podcastPageViewed = (
  podcast: {
    id: number;
    title: string;
    original: boolean;
    author: string;
  },
  episodes: { title: string }[]
): void => {
  const itemList = latestClickedItemList || latestClickedItemListFallback;
  const podcastItemProps = getItemPropsFromPodcast(podcast);

  // Facebook.
  // https://developers.facebook.com/docs/meta-pixel/implementation/pixel-for-collaborative-ads
  if (hasFacebookPixel()) {
    const contentIds = episodes.map((episode) => {
      const episodeItemProps = getItemPropsFromEpisode(episode);
      return episodeItemProps.item_variant;
    });

    global.fbq("track", "ViewContent", {
      content_ids: contentIds,
      content_type: "product_group",
      currency,
      value: podcastItemProps.price,
    });
  }

  if (hasGoogleAnalytics()) {
    global.gtag("event", "view_item", {
      items: [
        {
          item_list_name: itemList.name,
          item_list_id: itemList.id,
          currency,
          quantity: 1,
          ...getItemPropsFromPodcast(podcast),
        },
      ],
    });
  }
};

/**
 * Measure item viewed, see https://developers.google.com/gtagjs/reference/ga4-events#view_item
 */
export const episodePageViewed = (
  episode: {
    title: string;
  },
  podcast: {
    id: number;
    title: string;
    author: string;
    original: boolean;
  }
): void => {
  const itemList = latestClickedItemList || latestClickedItemListFallback;

  const podcastItemProps = getItemPropsFromPodcast(podcast);
  const episodeItemProps = getItemPropsFromEpisode(episode);

  // Facebook.
  // https://developers.facebook.com/docs/meta-pixel/implementation/pixel-for-collaborative-ads
  if (hasFacebookPixel()) {
    global.fbq("track", "ViewContent", {
      content_ids: [episodeItemProps.item_variant],
      content_type: "product",
      currency,
      value: podcastItemProps.price,
    });
  }

  // Google Analytics 4.
  if (hasGoogleAnalytics()) {
    global.gtag("event", "view_item", {
      items: [
        {
          item_list_name: itemList.name,
          item_list_id: itemList.id,
          currency,
          quantity: 1,
          ...podcastItemProps,
          ...episodeItemProps,
        },
      ],
    });
  }
};

/**
 * Measure purchase, see https://developers.google.com/gtagjs/reference/ga4-events#purchase
 */
export const episodeStarted = (episode: TPlayerEpisode): void => {
  const itemList = latestClickedItemList || latestClickedItemListFallback;

  // Fathom.
  if (episode.podcast.original) {
    trackGoal(FID_PODPLAY_EPISODE_STARTED, 1);
  } else {
    trackGoal(FID_THIRDPARTY_EPISODE_STARTED, 1);
  }

  const podcastItemProps = getItemPropsFromPodcast(episode.podcast);
  const episodeItemProps = getItemPropsFromEpisode(episode);

  // Facebook.
  // https://developers.facebook.com/docs/meta-pixel/implementation/pixel-for-collaborative-ads
  if (hasFacebookPixel()) {
    global.fbq("track", "Purchase", {
      content_category: podcastItemProps.item_category,
      content_name: podcastItemProps.item_name,
      content_type: "product",
      content_ids: [episodeItemProps.item_variant],
      currency,
      value: podcastItemProps.price,
    });
  }

  // Google Analytics 4.
  if (hasGoogleAnalytics()) {
    global.gtag("event", "purchase", {
      transaction_id: getUUIDv4(),
      currency,
      value: podcastItemProps.price,
      items: [
        {
          item_list_name: itemList.name,
          item_list_id: itemList.id,
          currency,
          quantity: 1,
          ...podcastItemProps,
          ...episodeItemProps,
        },
      ],
    });
  }
};
