import React, {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { z } from "zod";
import { TEpisodeProgress, ZEpisodeProgress } from "api/podplay/history";
import { setBrowserStorageItem, StorageItem } from "helpers/browserStorage";
import { getParsedBrowserStorageItem } from "helpers/getParsedBrowserStorageItem";
import { isNotUndefined } from "helpers/typeGuards";

const ZPersistedEpisodeProgress = z.array(ZEpisodeProgress);

export const MAXIMUM_STORED_EPISODES = 1000;
export const convertToPersistValue = (
  episodeProgress: Record<number, TEpisodeProgress | undefined>
): TEpisodeProgress[] => {
  return Object.values(episodeProgress)
    .filter(isNotUndefined)
    .sort((a, b) => b.modified - a.modified)
    .slice(0, MAXIMUM_STORED_EPISODES);
};

export interface IEpisodeProgressContext {
  episodeProgress: Record<number, TEpisodeProgress | undefined>;
  updateEpisodeProgress: (values: TEpisodeProgress[]) => void;
}

export const EpisodeProgressContext = createContext<
  IEpisodeProgressContext | undefined
>(undefined);
EpisodeProgressContext.displayName = "EpisodeProgressContext";

export const mergeValues = (
  oldValues: Record<number, TEpisodeProgress | undefined>,
  newValues: TEpisodeProgress[]
): Record<number, TEpisodeProgress | undefined> => {
  const updatedValues = { ...oldValues };

  let isUpdated = false;

  newValues.forEach((newValue) => {
    const oldValue = oldValues[newValue.episode_id];
    // Only update if new value modified is newer than old.
    if (!oldValue || newValue.modified > oldValue.modified) {
      isUpdated = true;
      updatedValues[newValue.episode_id] = newValue;
    }
  });

  return isUpdated ? updatedValues : oldValues;
};

export const EpisodeProgressContextProvider = (props: {
  children: ReactNode;
}) => {
  const [episodeProgress, setEpisodeProgress] = useState<
    Record<number, TEpisodeProgress | undefined>
  >({});
  const updateEpisodeProgress = useCallback((newValues: TEpisodeProgress[]) => {
    // Update state.
    setEpisodeProgress((oldValues) => {
      return mergeValues(oldValues, newValues);
    });
  }, []);

  // Populate state with values from localStorage.
  useEffect(() => {
    const persistedEpisodeProgress = getParsedBrowserStorageItem(
      StorageItem.EPISODE_PROGRESS,
      ZPersistedEpisodeProgress
    );
    if (!persistedEpisodeProgress) {
      return;
    }
    setEpisodeProgress((oldValues) => {
      return mergeValues(oldValues, persistedEpisodeProgress);
    });
  }, []);

  // Write to local storage.
  useEffect(() => {
    setBrowserStorageItem(
      StorageItem.EPISODE_PROGRESS,
      JSON.stringify(convertToPersistValue(episodeProgress))
    );
  }, [episodeProgress]);

  // Context value.
  const contextValue = useMemo<IEpisodeProgressContext>(
    () => ({
      episodeProgress,
      updateEpisodeProgress,
    }),
    [episodeProgress, updateEpisodeProgress]
  );

  return (
    <EpisodeProgressContext.Provider value={contextValue}>
      {props.children}
    </EpisodeProgressContext.Provider>
  );
};

export const useEpisodeProgressContext = (): IEpisodeProgressContext => {
  const context = useContext(EpisodeProgressContext);
  if (!context) {
    throw new Error("Can't find Episode progress context provider.");
  }
  return context;
};
