import { useEpisodeProgressContext } from "contexts/EpisodeProgressContextProvider";
import { useUser } from "contexts/UserContextProvider/UserContextProvider";
import { getUnixTime } from "date-fns";
import { useCallback } from "react";
import { saveEpisodeProgress } from "api/podplay/history";
import {
  TPlayerEpisode,
  isPlayerNormalEpisode,
} from "components/Player/context/PlayerContextProvider";
import { calculateEpisodeProgressCompleted } from "helpers/calculateEpisodeProgressCompleted";
import { isPositiveRealNumber } from "helpers/isPositiveRealNumber";
import { parseLocale } from "helpers/parseLocale";
import { useTypedRouter } from "hooks/useTypedRouter";

/**
 * Stores episodes progress in the context state and, if user is logged in, also
 * to the api.
 */
export const useStoreEpisodeProgress = (): ((
  episode: TPlayerEpisode,
  currentTime: number,
  duration: number
) => Promise<void>) => {
  const { user } = useUser();
  const { locale } = useTypedRouter();
  const { language } = parseLocale(locale);
  const { updateEpisodeProgress } = useEpisodeProgressContext();

  return useCallback(
    async (
      episode: TPlayerEpisode,
      currentTime: number,
      duration: number
    ): Promise<void> => {
      // Only store for normal episodes.
      if (!isPlayerNormalEpisode(episode)) {
        return;
      }

      // Duration can be NaN (until audio metadata has been loaded) or Infinity
      // if the media server has chosen to report that in the audio element.
      // Ignore saving if that's the case.
      if (!isPositiveRealNumber(duration)) {
        return;
      }

      const completed = calculateEpisodeProgressCompleted(
        currentTime,
        duration
      );

      // Store to context.
      const nowUnix = Math.floor(getUnixTime(Date.now()));
      updateEpisodeProgress([
        {
          completed,
          duration,
          episode_id: episode.id,
          last_listened: nowUnix,
          modified: nowUnix,
          progress: currentTime,
        },
      ]);

      // Store to api if logged in.
      if (user) {
        await saveEpisodeProgress(
          language,
          episode.id,
          completed,
          currentTime,
          duration
        );
      }
    },
    [user, language, updateEpisodeProgress]
  );
};
