import { RootElementId } from "interfaces/interfaces";

interface IGetElements {
  documentElement: HTMLElement;
  appRoot: HTMLElement;
}

function getElements(): IGetElements {
  const documentElement = document.documentElement || document.body;
  const appRoot = document.getElementById(RootElementId.APP);

  if (!appRoot) {
    throw new Error("App root element not defined");
  }

  return {
    documentElement,
    appRoot,
  };
}

/**
 * Lock scroll.
 * @returns {Number} Scroll position.
 */
export function lockScroll(): number {
  const { documentElement, appRoot } = getElements();

  // Store scroll position and offset fixed app root element with value to keep content at same place.
  const scrollTop = Math.max(window.pageYOffset, documentElement.scrollTop);

  appRoot.style.position = `fixed`;
  appRoot.style.top = `-${scrollTop}px`;
  appRoot.style.left = "0px";
  appRoot.style.right = "0px";

  return scrollTop;
}

export function unlockScroll(scrollPosition?: number): void {
  const { documentElement, appRoot } = getElements();

  // Remove offset value and scroll back to previous position.
  appRoot.style.removeProperty("position");
  appRoot.style.removeProperty("top");
  appRoot.style.removeProperty("left");
  appRoot.style.removeProperty("right");

  if (scrollPosition) {
    // NOTICE: Scroll top with "el" doesn't work on iOS - Use "scrollingElement" first.
    const scrollEl = document.scrollingElement || documentElement;

    scrollEl.scrollTop = scrollPosition;
  }
}
