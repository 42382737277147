/*
  -18
  18-24
  25-34
  35-44
  45-54
  55-64
  65+
 */

export const getAgeGroup = (age: number): string => {
  if (age < 18) {
    return "-18";
  }
  if (age >= 18 && age <= 24) {
    return "18-24";
  }
  if (age >= 25 && age <= 34) {
    return "25-34";
  }
  if (age >= 35 && age <= 44) {
    return "35-44";
  }
  if (age >= 45 && age <= 54) {
    return "45-54";
  }
  if (age >= 55 && age <= 64) {
    return "55-64";
  }
  return "65+";
};
