import { useEffect, useState } from "react";

/**
 * Returns true if audio is in buffering state.
 */
export const useIsBuffering = (
  audio: HTMLAudioElement | undefined
): boolean => {
  const [isBuffering, setIsBuffering] = useState(false);

  // Reset buffering if audio.src changes.
  useEffect(() => {
    setIsBuffering(false);
  }, [audio?.src]);

  // Listen to audio events.
  useEffect(() => {
    if (!audio) {
      return;
    }

    const waitingHandler = () => {
      setIsBuffering(true);
    };
    const playingHandler = () => {
      setIsBuffering(false);
    };
    const pauseHandler = () => {
      setIsBuffering(false);
    };

    audio.addEventListener("waiting", waitingHandler);
    audio.addEventListener("playing", playingHandler);
    audio.addEventListener("pause", pauseHandler);

    return () => {
      audio.removeEventListener("waiting", waitingHandler);
      audio.removeEventListener("playing", playingHandler);
      audio.removeEventListener("pause", pauseHandler);
    };
  }, [audio]);

  return isBuffering;
};
