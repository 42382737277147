import jwtDecode from "jwt-decode";
import { IAccessToken } from "api/podplay/user";
import { getBrowserStorageItem, StorageItem } from "helpers/browserStorage";

export const getDecodedAccessToken = (): IAccessToken | null => {
  const accessToken = getBrowserStorageItem(StorageItem.AUTH_ACCESS);

  if (!accessToken) {
    return null;
  }

  try {
    return jwtDecode(accessToken) as IAccessToken;
  } catch (err) {
    // Do nothing.
  }

  return null;
};
