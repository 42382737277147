export enum AdsWizGender {
  MALE = "male",
  FEMALE = "female",
  OTHER = "other",
}
export const serializeGender = (gender: string): AdsWizGender => {
  switch (gender) {
    case "male":
      return AdsWizGender.MALE;
    case "female":
      return AdsWizGender.FEMALE;
    default:
      return AdsWizGender.OTHER;
  }
};
