import { useEffect, useState } from "react";
import { UptimeStatusReport } from "pages/api/uptimeStatus";

export const useUptimeStatus = () => {
  const [state, setState] = useState<UptimeStatusReport | undefined>(undefined);

  useEffect(() => {
    (async function () {
      const resourceId = process.env.NEXT_PUBLIC_BETTERUPTIME_RESOURCE_ID;

      if (!resourceId) {
        return false;
      }

      const response = await fetch(
        `/api/uptimeStatus?resourceId=${process.env.NEXT_PUBLIC_BETTERUPTIME_RESOURCE_ID}`
      );

      if (response.ok) {
        try {
          const json = await response.json();
          setState(json);
        } catch (e) {}
      }
    })();
  }, []);

  return state;
};
