import { useContext } from "react";
import {
  IPlayerContext,
  PlayerContext,
} from "components/Player/context/PlayerContextProvider";

/**
 * Hook to get the player context.
 */
export const usePlayer = (): IPlayerContext => {
  const ctx = useContext(PlayerContext);

  if (!ctx) {
    throw new Error("Can't find Player context provider");
  }

  return ctx;
};
