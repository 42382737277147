import { useCallback, useEffect, useState } from "react";
import { lockScroll, unlockScroll } from "helpers/preventScrollOnAppRoot";
import { useMutationObserver } from "hooks/useMutationObserver";

export const useScrollLock = (elementId: string): void => {
  const [scrollTop, setScrollTop] = useState(0);
  const [target, setTarget] = useState<HTMLElement | null>(null);

  const callback = useCallback(() => {
    const hasModals = target && target.childNodes.length > 0;
    if (hasModals) {
      const scrollTop = lockScroll();
      setScrollTop(scrollTop);
    } else {
      unlockScroll(scrollTop);
    }
  }, [target, scrollTop]);

  useEffect(() => {
    const element = document.getElementById(elementId);
    setTarget(element);
  }, [elementId]);

  useMutationObserver(target, callback);
};
