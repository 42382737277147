import {
  isPlayerLiveEpisode,
  TPlayerEpisode,
} from "components/Player/context/PlayerContextProvider";

/**
 * Returns different durations depending on episode type. Fallbacks to NaN.
 */
export const getDuration = (
  episode: TPlayerEpisode | undefined,
  audio: HTMLAudioElement | undefined
): number => {
  if (!episode) {
    return NaN;
  }

  if (isPlayerLiveEpisode(episode)) {
    // Always return "Infinity" if live episode. It's used in controller logic,
    // and it's not sure that server returns it even though it's a live stream.
    return Infinity;
  }

  // NOTICE: Some media servers could return "Infinity" as duration - Not sure
  // what to do then, fallback to "NaN".
  return !audio || audio.duration === Infinity ? NaN : audio.duration;
};
