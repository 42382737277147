import * as Sentry from "@sentry/nextjs";

export const getCleanEnvironmentName = (branch: string): string => {
  // The environment name can't contain newlines, spaces or forward slashes, can't be the string "None",
  // or exceed 64 characters.
  return branch.replace("/", ":").substring(0, Math.min(branch.length, 64));
};

export const initSentry = (): void => {
  const branch =
    process.env.VERCEL_GIT_COMMIT_REF ||
    process.env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_REF;

  Sentry.init({
    dsn:
      process.env.SENTRY_DSN ||
      process.env.NEXT_PUBLIC_SENTRY_DSN ||
      "https://5b066979b2a34fefb27141c2851f8641@o455066.ingest.sentry.io/5446025",
    environment: branch
      ? getCleanEnvironmentName(branch)
      : process.env.NODE_ENV,
    beforeSend: (event) => {
      if (
        // Server side.
        event.request?.cookies?.Cypress === "true" ||
        // Client side.
        (typeof document !== "undefined" &&
          /Cypress=true/.test(document.cookie))
      ) {
        // Filter out events created by Cypress.
        return null;
      }

      // Report error.
      return event;
    },
    ignoreErrors: [
      // NOTICE: These errors can occur when user reloads page or leaves
      // the Studio page during loading phase.
      // See: https://forum.sentry.io/t/typeerror-failed-to-fetch-reported-over-and-overe/8447/2
      "TypeError: Failed to fetch",
      "TypeError: NetworkError when attempting to fetch resource.",
      // NOTICE: "TypeError: Load failed" error occurs mostly on ios with very
      // limited details about why. Could be related to CORS error. Not much we
      // can do. Ignore to remove noise in Sentry.
      // See: https://stackoverflow.com/questions/71280168/javascript-typeerror-load-failed-error-when-calling-fetch-on-ios
      "TypeError: Load failed",
      // Filter out annoying error caused by FB/Instagram browser.
      "Can't find variable: _AutofillCallbackHandler",
    ],
    // Adjust this value in production, or use tracesSampler for greater control
    tracesSampleRate: 0.0,
    // ...
    // Note: if you want to override the automatic release value, do not set a
    // `release` value here - use the environment variable `SENTRY_RELEASE`, so
    // that it will also get attached to your source maps
  });
};
