import { stringify } from "query-string";
import { TPodcast } from "api/podplay/podcast";
import { podplayFetch } from "helpers/podplayFetch";
import type { Language } from "interfaces/interfaces";

// -----------------------------------------------------------------------------
// PODCAST TOPLIST
// -----------------------------------------------------------------------------

export interface IPodcastToplistResponseBody {
  results: (TPodcast | null)[];
}

type IPodcastToplistOptions = {
  language_iso?: Language;
  category_id?: number | string;
  original?: boolean;
  limit?: number;
};

export const getPodcastToplistUrl = (
  language: Language,
  options?: IPodcastToplistOptions
): string => {
  const encodedSearchParams = options && stringify(options);
  const search = encodedSearchParams ? `?${encodedSearchParams}` : "";
  return `/${language}/toplist${search}`;
};

export async function podcastToplist(
  language: Language,
  options?: IPodcastToplistOptions
) {
  return podplayFetch<IPodcastToplistResponseBody>(
    getPodcastToplistUrl(language, options)
  );
}
