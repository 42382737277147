import { z } from "zod";

export enum ChatProvider {
  SENDBIRD = "sendbird",
}

export const ZSendbird = z.object({
  type: z.literal(ChatProvider.SENDBIRD),
  payload: z.object({
    channel_url: z.string(),
  }),
});

export type TSendbird = z.infer<typeof ZSendbird>;

export const isSendbird = (chatProvider: {
  type: string;
}): chatProvider is TSendbird => {
  return chatProvider.type === ChatProvider.SENDBIRD;
};

export enum Provider {
  MIXLR = "mixlr",
}

export const ZMixlr = z.object({
  type: z.literal(Provider.MIXLR),
  payload: z.object({
    user_id: z.string(),
    stream_url: z.string(),
  }),
});

export type TMixlr = z.infer<typeof ZMixlr>;

const ZUnknownChatProvider = z.object({
  type: z.string(),
  payload: z.unknown(),
});

export const isMixlr = (provider: { type: string }): provider is TMixlr => {
  return provider.type === Provider.MIXLR;
};

const ZUnknownProvider = z.object({ type: z.string(), payload: z.unknown() });

export const ZLive = z.object({
  chat_service_provider: z.union([
    ZSendbird,
    ZUnknownChatProvider,
    z.undefined(),
  ]),
  title: z.string(),
  description: z.string(),
  start: z.number(),
  end: z.number(),
  provider: z.union([ZMixlr, ZUnknownProvider]),
});

export type TLive = z.infer<typeof ZLive>;

export const ZLivePodcast = z.object({
  podplay_podcast_id: z.number(),
  live: z.union([ZLive, z.null()]),
});

export type TLivePodcast = z.infer<typeof ZLivePodcast>;

export const fetchLivePodcastUrl = (id: string | number): string => {
  return `meta/${id}.json`;
};
