import { MutableRefObject, useEffect } from "react";
import { TPlayerEpisode } from "components/Player/context/PlayerContextProvider";
import { IProps } from "components/Player/hooks/useAudio";

/**
 * Triggers the "onUnload" callback before the user leaves the page.
 */
export const useBeforeBrowserUnload = (
  audio: HTMLAudioElement | undefined,
  episodeRef: MutableRefObject<TPlayerEpisode | undefined>,
  onUnload: IProps["onUnload"]
): void => {
  useEffect(() => {
    const beforeBrowserUnloadHandler = () => {
      const episode = episodeRef.current;
      if (!audio || !episode || !onUnload) {
        return;
      }
      onUnload(episode, audio);
    };

    window.addEventListener("beforeunload", beforeBrowserUnloadHandler);

    return () => {
      window.removeEventListener("beforeunload", beforeBrowserUnloadHandler);
    };
  }, [audio, episodeRef, onUnload]);
};
