export const removeNullishProperties = (
  obj: Record<string, unknown>
): Record<string, unknown> =>
  Object.fromEntries(
    Object.entries(obj)
      // Uses abstract qquality comparison to check for nullish values.
      // Ie currently both undefined and null.
      .filter(([_, v]) => v != null)
      .map(([k, v]) =>
        typeof v === "object"
          ? [k, removeNullishProperties(v as Record<string, unknown>)]
          : [k, v]
      )
  );
