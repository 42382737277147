/**
 * Limit text length.
 * @param {String} text Text.
 * @param {Number} maxLength Max length.
 * @returns {String} Clipped text with dots.
 */
export const limitTextLength = (text: string, maxLength: number): string => {
  const dotNum = 3;
  const textMaxLength = maxLength - dotNum;

  return text.length > maxLength
    ? text.substring(0, textMaxLength).padEnd(maxLength, ".")
    : text;
};
