import * as Sentry from "@sentry/nextjs";
import { HTTPStatusCode } from "interfaces/interfaces";

export interface ISuccessResponse<TBodySuccess> {
  readonly url: string;
  readonly body: TBodySuccess;
  readonly ok: true;
  readonly headers: Headers;
  readonly status: number;
}

export interface IErrorResponse<TBodyError = Record<string, unknown>> {
  readonly url: string;
  readonly status?: number;
  readonly body?: TBodyError;
  readonly ok: false;
  readonly error?: unknown;
}

const reportErrorToSentry = async (
  response: Response,
  body: unknown,
  absoluteUrl: string
): Promise<void> => {
  const { status } = response;

  // NOTICE: Avoid reporting if "Not Found / 404" - The site is getting too
  // much 404 traffic (removed episodes and podcasts?).
  if (response.status === HTTPStatusCode.NOT_FOUND) {
    return;
  }

  // Get response as text (possible if response wasn't json and the json
  // function hasn't been run). The text body could be useful when debugging.
  const textBody =
    body instanceof Error && !response.bodyUsed
      ? await response.text().catch((err) => String(err))
      : undefined;

  // Unsuccessful response.
  if (!response.ok) {
    Sentry.withScope((scope) => {
      // NOTICE: Url could be long if many query parameters or when GraphGL.
      // Leave out search params in error message and only use the endpoint.
      const url = new URL(absoluteUrl);
      const endpoint = `${url.protocol}//${url.hostname}${url.pathname}`
        // Replace id parts in the url like "123" ("/foo/123/bar") / to "[id]"
        // ("/foo/[id]/bar") to get better grouping in Sentry.
        .replace(/(\/)\d+(\/|$)/g, "$1[id]$2");
      // NOTICE: Override Sentry default grouping - We can't rely on error
      // messages for good grouping.
      scope.setFingerprint([endpoint, String(status)]);
      scope.setExtras({
        url: absoluteUrl,
        status,
        body,
        textBody,
      });
      Sentry.captureException(
        new Error(`Unsuccessful fetch status (${status}) on ${endpoint}`)
      );
    });
  } else if (body instanceof Error) {
    // Body error could be various errors. Json parse error, failed to fetch,
    // fetch aborted etc...
    Sentry.withScope((scope) => {
      scope.setExtras({
        url: absoluteUrl,
        status,
        textBody,
      });
      Sentry.captureException(body);
    });
  }
};

export async function customFetch<
  TBodySuccess,
  TBodyError = Record<string, unknown>
>(
  absoluteUrl: string,
  requestInit?: RequestInit
): Promise<ISuccessResponse<TBodySuccess> | IErrorResponse<TBodyError>> {
  let response: Response;

  try {
    response = await fetch(absoluteUrl, requestInit);
  } catch (err) {
    // TODO: Don't report failed to fetch errors to Sentry. Check what we get
    //  in Sentry - Could this be removed if we only get errors that we
    //  don't care about?
    Sentry.withScope((scope) => {
      scope.setExtras({ url: absoluteUrl });
      Sentry.captureException(err);
    });

    return {
      ok: false,
      url: absoluteUrl,
      error: err,
    };
  }

  const { ok, status, headers } = response;

  // Body - Only try to parse if Json - That makes it possible to get body as
  // text in Sentry report if not Json (body can only be used once and
  // "response.clone" hangs on large responses).
  const contentType = response.headers.get("content-type");
  const body = /application\/json/i.test(contentType || "")
    ? await response.json().catch((err) => err)
    : new Error(
        `Expected "content-type" to be "application/json" but got "${contentType}"`
      );

  // Body error - It could happen if we don't get JSON back or if
  // it's malformed. Response.json() can also throw similar errors as fetch.
  if (body instanceof Error) {
    await reportErrorToSentry(response, body, absoluteUrl);
    return {
      ok: false,
      status,
      url: absoluteUrl,
      error: body,
    };
  }

  // Response NOT successful.
  if (!ok) {
    await reportErrorToSentry(response, body, absoluteUrl);
    return { ok: false, status, url: absoluteUrl, body };
  }

  // Response successful.
  return {
    ok: true,
    status,
    url: absoluteUrl,
    body,
    headers,
  };
}
