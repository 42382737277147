import { IPodplayUser } from "contexts/UserContextProvider/UserContextProvider";
import { parse, stringify } from "query-string";
import { TEpisode } from "api/podplay/episode";
import { TEpisodeProgress } from "api/podplay/history";
import { TPodcast } from "api/podplay/podcast";
import {
  TPlayerNormalEpisode,
  ZPlayerEpisodeType,
} from "components/Player/context/PlayerContextProvider";
import { decorateAudioUrl } from "components/Player/helpers/adswiz/decorateAudioUrl";

interface IProps {
  episode: TEpisode;
  podcast: TPodcast;
  episodeProgress: TEpisodeProgress | undefined;
  autoPlay: boolean;
  uid: string;
  user: IPodplayUser | null;
  sourcePointConsents: string | null;
}

export const addStartTimeToUrl = (url: string, startTime: number): string => {
  if (!startTime) {
    return url;
  }

  const split = url.split("#");
  const strippedUrl = split[0];
  const hash = split[1] || "";
  const parsedHash = parse(hash);

  parsedHash.t = startTime.toString(10);

  const hashString = stringify(parsedHash, {
    arrayFormat: "none",
  });

  return `${strippedUrl}#${hashString}`;
};

export const createPlayerNormalEpisode = (
  props: IProps
): TPlayerNormalEpisode => {
  const startTime =
    !props.episodeProgress || props.episodeProgress.completed
      ? 0
      : props.episodeProgress.progress;
  const url = props.podcast.original
    ? decorateAudioUrl(
        props.episode.url,
        props.uid,
        Date.now(),
        props.user,
        props.sourcePointConsents
      )
    : props.episode.url;
  return {
    approximatedDuration:
      props.episodeProgress?.duration || props.episode.duration,
    autoPlay: props.autoPlay,
    description: props.episode.description,
    id: props.episode.id,
    podcast: props.podcast,
    published: props.episode.published,
    startTime,
    title: props.episode.title,
    type: ZPlayerEpisodeType.enum.NORMAL,
    url: addStartTimeToUrl(url, startTime),
  };
};
