import { useEffect } from "react";
import { setIsLoggedInCookie } from "helpers/setLoggedInCookie";

/**
 * The logged in cookie is set when logging in / out. However, the user could
 * already be logged in (via auth token stored in local storage) when the
 * cookie was introduced. This hook makes sure that the cookie is in sync with
 * the logged in state.
 */
export const useUpdateLoggedInCookie = (isLoggedIn: boolean | undefined) => {
  useEffect(() => {
    if (isLoggedIn === undefined) {
      return;
    }
    // This will make sure the cookie value is updated after we know if the
    // user is logged in or not.
    setIsLoggedInCookie(isLoggedIn);
  }, [isLoggedIn]);
};
