import React, {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { z } from "zod";
import { setBrowserStorageItem, StorageItem } from "helpers/browserStorage";
import { getParsedBrowserStorageItem } from "helpers/getParsedBrowserStorageItem";

const ZPersistedSearch = z.object({
  clickedPodcasts: z.number().array(),
});

export interface ISearchContext {
  clickedPodcasts: number[];
  addClickedPodcast: (podcastId: number) => void;
  removeClickedPodcast: (podcastId: number) => void;
  clearClickedPodcasts: () => void;
}

export const SearchContext = createContext<ISearchContext | undefined>(
  undefined
);
SearchContext.displayName = "SearchContext";

export const SearchContextProvider = (props: { children: ReactNode }) => {
  const [clickedPodcasts, setClickedPodcasts] = useState<number[]>([]);

  const addClickedPodcast = useCallback((podcastId: number) => {
    setClickedPodcasts((oldValues) => [
      podcastId,
      ...oldValues.filter((id) => id !== podcastId),
    ]);
  }, []);

  const removeClickedPodcast = useCallback((podcastId) => {
    setClickedPodcasts((oldValues) => [
      ...oldValues.filter((id) => id !== podcastId),
    ]);
  }, []);

  const clearClickedPodcasts = useCallback(() => {
    setClickedPodcasts([]);
  }, []);

  // Populate state with values from localStorage.
  useEffect(() => {
    const persistedSearch = getParsedBrowserStorageItem(
      StorageItem.SEARCH,
      ZPersistedSearch
    );
    if (!persistedSearch?.clickedPodcasts.length) {
      return;
    }
    setClickedPodcasts((oldValues) => [
      ...oldValues,
      ...persistedSearch.clickedPodcasts.filter(
        (id) => !oldValues.includes(id)
      ),
    ]);
  }, []);

  // Write to local storage.
  useEffect(() => {
    setBrowserStorageItem(
      StorageItem.SEARCH,
      JSON.stringify({ clickedPodcasts })
    );
  }, [clickedPodcasts]);

  // Context value.
  const contextValue = useMemo<ISearchContext>(
    () => ({
      clickedPodcasts,
      addClickedPodcast,
      removeClickedPodcast,
      clearClickedPodcasts,
    }),
    [
      addClickedPodcast,
      clearClickedPodcasts,
      clickedPodcasts,
      removeClickedPodcast,
    ]
  );

  return (
    <SearchContext.Provider value={contextValue}>
      {props.children}
    </SearchContext.Provider>
  );
};

export const useSearchContext = (): ISearchContext => {
  const context = useContext(SearchContext);
  if (!context) {
    throw new Error("Can't find Search context provider.");
  }
  return context;
};
