import {
  createNewAnonymousListeningSession,
  createNewUserListeningSession,
  registerAnonymousListeningSession,
  registerUserListeningSession,
} from "api/lstat/lstat";
import { Language } from "interfaces/interfaces";

export const getSessionId = async (
  episodeId: number,
  isLoggedIn: boolean,
  language: Language
): Promise<string | undefined> => {
  const response = isLoggedIn
    ? await createNewUserListeningSession(language, episodeId)
    : await createNewAnonymousListeningSession(language, episodeId);

  if (!response.ok) {
    return;
  }

  return response.body.episode_listening_session_id;
};

export interface ISaveListeningStatisticsProps {
  durationSec: number;
  endPositionSec: number;
  isLoggedIn: boolean;
  language: Language;
  sessionId: string;
  startPositionSec: number;
  timestampUnix: number;
}

interface ISaveListeningStatistics {
  (props: ISaveListeningStatisticsProps): Promise<void | {
    sessionId: string;
    lastReportedPosition: number;
    episodeId: number;
  }>;
}

export const saveListeningStatistics: ISaveListeningStatistics = async ({
  durationSec,
  endPositionSec,
  isLoggedIn,
  language,
  sessionId,
  startPositionSec,
  timestampUnix,
}): Promise<void> => {
  const start = Math.floor(startPositionSec);
  const end = Math.floor(endPositionSec);

  // A segment is valid if we have a start time and
  // an end time that is larger than the start time.
  const isValidSegment = start >= 0 && end && end > start;

  if (!isValidSegment) {
    throw Error(`Invalid segment: start=${start}, end=${end}`);
  }

  if (isLoggedIn) {
    await registerUserListeningSession(
      language,
      sessionId,
      durationSec,
      start,
      end,
      timestampUnix
    );
  } else {
    await registerAnonymousListeningSession(
      sessionId,
      durationSec,
      start,
      end,
      timestampUnix
    );
  }
};
