/**
 * @author Viktor Linder
 * @date 2015-09-17
 */

/**
 * Pad.
 * @param {Number} n Number.
 * @return {String} Padded number as string.
 * @private
 */
export const pad = (n: number): string => `0${n}`.substr(-2);

/**
 * Format duration as HH:MM:SS.
 * @param {Number} duration Duration in seconds.
 * @return {String} HH:MM:SS
 */
export const formatDurationAsHHMMSS = (duration: number | null): string =>
  typeof duration === "number" && duration > 0
    ? [duration / 3600, (duration % 3600) / 60, (duration % 3600) % 60]
        .map(Math.floor)
        .filter((_value, index) => index || duration >= 3600)
        .map(pad)
        .join(":")
    : "00:00";

/**
 * Format duration as minutes rounded up.
 * @param {Number} duration Duration in seconds.
 * @return {String} M rounded up.
 */
export const formatDurationAsMinutesRoundedUp = (
  duration: number | null
): string =>
  typeof duration === "number" && duration > 0
    ? Math.ceil(duration / 60).toString()
    : "0";

/**
 * Format duration left as HH:MM:SS.
 * @param {Number} duration Duration in seconds.
 * @param {Number} currentTime Current time in seconds.
 * @return {String} HH:MM:SS with prefixed "-".
 */
export const formatDurationLeftAsHHMMSS = (
  duration: number,
  currentTime: number
): string => {
  const formattedDurationLeft = formatDurationAsHHMMSS(duration - currentTime);

  return formattedDurationLeft !== "00:00"
    ? `-${formattedDurationLeft}`
    : formattedDurationLeft;
};

const timeDatePeriodPrefixes: string[] = ["PT", "", ""];
const timeDatePeriodSuffixes: string[] = ["H", "M", "S"];

/**
 * Format duration as PT{0-9}H{0-9}M{0-9}S - This could be used in the "time"-element "datetime" attribute.
 * @param {Number} duration Duration in seconds.
 * @return {String} PT{0-9}H{0-9}M{0-9}S.
 */
export const formatDurationAsTimeDatePeriod = (
  duration: number | null
): string =>
  typeof duration === "number" && duration > 0
    ? [duration / 3600, (duration % 3600) / 60, (duration % 3600) % 60]
        .map(Math.floor)
        .map(
          (value, index) =>
            `${timeDatePeriodPrefixes[index]}${value}${timeDatePeriodSuffixes[index]}`
        )
        .join("")
    : "PT0H0M0S";
