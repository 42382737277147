import { useContext } from "react";
import {
  I18nContext,
  II18nContext,
} from "components/I18nContextProvider/I18nContextProvider";

export const useI18n = (): II18nContext => {
  const ctx = useContext(I18nContext);

  if (!ctx) {
    throw new Error("Can't find I18n context provider");
  }

  return ctx;
};
