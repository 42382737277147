export const deepCompare = (
  a: Record<string | number, unknown> | unknown[],
  b: Record<string | number, unknown> | unknown[]
) => {
  // Short circuit if A and B are the same object.
  if (a === b) return true;

  // Short circuit if A or B doesnt exist.
  if (!a || !b) return false;

  // Handle arrays.
  if (Array.isArray(a) && Array.isArray(b)) {
    return (
      a.length === b.length &&
      a.every((aItem, index) => deepCompare(aItem, b[index]))
    );
  }

  // Handle objects other than arrays.
  if (typeof a === "object" && typeof b === "object") {
    const aKeys = Object.keys(a);
    const bKeys = Object.keys(b);
    return (
      aKeys.length === bKeys.length &&
      aKeys.every((key) => deepCompare(a[key], b[key]))
    );
  }

  return false;
};
