import { I18nKey } from "locales/constants/I18nKey";
import React, { ReactElement } from "react";
import { TEpisode } from "api/podplay/episode";
import { TPodcast } from "api/podplay/podcast";
import { ContextMenu, DesktopAlign } from "components/ContextMenu/ContextMenu";
import {
  MenuItemButton,
  MenuItemLink,
} from "components/ContextMenu/components/MenuItem/MenuItem";
import { PlaylistAddFirstIcon } from "components/Icons/PlaylistAddFirstIcon";
import { PlaylistAddLastIcon } from "components/Icons/PlaylistAddLastIcon";
import { PlaylistRemoveIcon } from "components/Icons/PlaylistRemoveIcon";
import { PodcastIcon } from "components/Icons/PodcastIcon";
import { WaveformIcon } from "components/Icons/WaveformIcon";
import { usePlayer } from "components/Player/hooks/usePlayer";
import { parseLocale } from "helpers/parseLocale";
import { buildEpisodeUrl, buildPodcastUrl } from "helpers/url";
import { useI18n } from "hooks/useI18n";
import { useTypedRouter } from "hooks/useTypedRouter";

interface IProps {
  anchorElm: HTMLElement | null;
  onClose: () => void;
  desktopAlign: DesktopAlign;
  podcast: TPodcast;
  episode: TEpisode;
}

export const QueueItemMoreMenu = ({
  anchorElm,
  onClose,
  desktopAlign,
  episode,
  podcast,
}: IProps): ReactElement => {
  const router = useTypedRouter();
  const { locale } = parseLocale(router.locale);
  const { i18n } = useI18n();
  const { queue, removeFromQueue, moveToFrontOfQueue, moveToBackOfQueue } =
    usePlayer();
  const isFirstInQueue = episode.id === queue[0]?.episode.id;
  const isLastInQueue = episode.id === queue[queue.length - 1]?.episode.id;
  return (
    <ContextMenu
      podcastImage={podcast.image}
      contextName={episode.title}
      onClose={onClose}
      desktopAlign={desktopAlign}
      anchorElm={anchorElm}
    >
      <MenuItemButton
        onClick={() => {
          moveToFrontOfQueue(episode.id);
          onClose();
        }}
        disabled={isFirstInQueue}
      >
        <PlaylistAddFirstIcon size={24} />
        {i18n(I18nKey.PLAYER_QUEUE_MORE_MENU_MOVE_FIRST)}
      </MenuItemButton>
      <MenuItemButton
        onClick={() => {
          moveToBackOfQueue(episode.id);
          onClose();
        }}
        disabled={isLastInQueue}
      >
        <PlaylistAddLastIcon size={24} />
        {i18n(I18nKey.PLAYER_QUEUE_MORE_MENU_MOVE_LAST)}
      </MenuItemButton>
      <MenuItemButton
        onClick={() => {
          removeFromQueue(episode.id);
          onClose();
        }}
      >
        <PlaylistRemoveIcon size={24} />
        {i18n(I18nKey.PLAYER_QUEUE_MORE_MENU_REMOVE)}
      </MenuItemButton>
      <hr />
      <MenuItemLink href={buildEpisodeUrl(podcast, episode, locale)}>
        <WaveformIcon size={24} />
        <span onClick={onClose}>
          {i18n(I18nKey.PLAYER_QUEUE_MORE_MENU_EPISODE)}
        </span>
      </MenuItemLink>
      <MenuItemLink href={buildPodcastUrl(podcast.id, podcast.title, locale)}>
        <PodcastIcon size={24} />
        <span onClick={onClose}>
          {i18n(I18nKey.PLAYER_QUEUE_MORE_MENU_PODCAST)}
        </span>
      </MenuItemLink>
    </ContextMenu>
  );
};
