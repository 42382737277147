import classNames from "classnames";
import { I18nKey } from "locales/constants/I18nKey";
import React, { ReactElement } from "react";
import { CloseIcon } from "components/Icons/CloseIcon";
import { usePlayer } from "components/Player/hooks/usePlayer";
import { useI18n } from "hooks/useI18n";
import styles from "./errorMessage.module.scss";

export interface IProps {
  className?: string;
  showClose: boolean;
}

export const ErrorMessage = ({
  className,
  showClose,
}: IProps): ReactElement => {
  const { restartFromCurrentTime, setIsExpanded, unload } = usePlayer();
  const { i18n } = useI18n();
  return (
    <section
      className={classNames(className, styles.container)}
      data-testid="player-error"
      role="alert"
    >
      {showClose && (
        <button
          type="button"
          className={styles.closeButton}
          onClick={() => {
            setIsExpanded(false);
            unload();
          }}
          data-testid="player-error-closeButton"
          aria-label={i18n(I18nKey.COMMON_CLOSE)}
        >
          <CloseIcon size={24} />
        </button>
      )}
      <button
        type="button"
        className={styles.message}
        onClick={restartFromCurrentTime}
        data-testid="player-error-restartButton"
      >
        {i18n(I18nKey.PLAYER_ERROR_MESSAGE)}
      </button>
    </section>
  );
};
