import getConfig from "next/config";
import { NextRouter } from "next/router";
import { slugify } from "helpers/slugify";
import { Locale } from "interfaces/interfaces";

// Get default locale.
const config = getConfig();
export const defaultLocale = config.publicRuntimeConfig.defaultLocale;

export const prefixUrlWithLocale = (url: string, locale?: Locale): string => {
  return !locale || locale === defaultLocale ? url : `/${locale}${url}`;
};

export const isPodcastPageRoute = (route: NextRouter["route"]) => {
  return route === "/podcasts/[podcastSlugId]";
};

export const isEpisodePageRoute = (route: NextRouter["route"]) => {
  return route === "/podcasts/[podcastSlugId]/episodes/[episodeSlugId]";
};

export const getBaseUrl = (): string => {
  if (!process.env.NEXT_PUBLIC_BASE_URL) {
    throw new Error(
      `Invalid "NEXT_PUBLIC_BASE_URL" value ("${process.env.NEXT_PUBLIC_BASE_URL}")`
    );
  }
  return process.env.NEXT_PUBLIC_BASE_URL;
};

export const buildSlugId = (id: string | number, title?: string): string => {
  const slug = slugify(title);
  return slug.length ? `${slug}-${id}` : `${id}`;
};

export const buildPodcastUrl = (
  id: number | string,
  title?: string,
  locale?: Locale
): string => {
  const podcastSlugId = buildSlugId(id, title);
  const url = `/podcasts/${podcastSlugId}`;
  return prefixUrlWithLocale(url, locale);
};

export const buildCollectionsUrl = (locale?: Locale): string => {
  const url = "/collections";
  return prefixUrlWithLocale(url, locale);
};

export const buildCollectionUrl = (
  collectionSlugId: string,
  locale?: Locale
): string => {
  const url = `/collections/${collectionSlugId}`;
  return prefixUrlWithLocale(url, locale);
};

export const buildEpisodeUrl = (
  podcast: { id: number; title: string },
  episode: { id: number | string; title: string },
  locale?: Locale
): string => {
  const podcastSlugId = buildSlugId(podcast.id, podcast.title);
  const episodeSlugId = buildSlugId(episode.id, episode.title);
  const url = `/podcasts/${podcastSlugId}/episodes/${episodeSlugId}`;
  return prefixUrlWithLocale(url, locale);
};

export const buildEpisodeShareUrl = (
  podcast: { id: number; title: string },
  episode: { id: number | string; title: string },
  utm_campaign: string,
  utm_source: string,
  locale?: Locale
): string => {
  const relativeEpisodeUrl = buildEpisodeUrl(podcast, episode, locale);
  const absoluteEpisodeUrl = `${getBaseUrl()}${relativeEpisodeUrl}`;
  const url = new URL(absoluteEpisodeUrl);
  url.searchParams.append("utm_campaign", utm_campaign);
  url.searchParams.append("utm_source", utm_source);
  return url.toString();
};

export const buildPodcastShareUrl = (
  podcast: { id: number; title: string },
  utm_campaign: string,
  utm_source: string,
  locale?: Locale
): string => {
  const relativeEpisodeUrl = buildPodcastUrl(podcast.id, podcast.title, locale);
  const absoluteEpisodeUrl = `${getBaseUrl()}${relativeEpisodeUrl}`;
  const url = new URL(absoluteEpisodeUrl);
  url.searchParams.append("utm_campaign", utm_campaign);
  url.searchParams.append("utm_source", utm_source);
  return url.toString();
};

export const buildStartpageUrl = (locale?: Locale): string => {
  return prefixUrlWithLocale("", locale) || "/";
};

export const buildHomepageUrl = (locale?: Locale): string => {
  return prefixUrlWithLocale("/home", locale);
};

export const buildHistoryUrl = (locale?: Locale): string => {
  return prefixUrlWithLocale("/home/history", locale);
};

export const buildSettingsUrl = (locale?: Locale): string => {
  return prefixUrlWithLocale("/settings", locale);
};

export const buildSitemapUrl = (locale: Locale): string => {
  return `/api/${locale}/sitemap`;
};

export const buildLoginUrl = (locale?: Locale): string => {
  return prefixUrlWithLocale("/#login", locale);
};

export const buildResetPasswordUrl = (
  signature: string,
  locale?: Locale
): string => {
  return prefixUrlWithLocale(`/?signature=${signature}#reset-password`, locale);
};

export const buildForgotPasswordUrl = (locale?: Locale): string => {
  return prefixUrlWithLocale("/#forgot-password", locale);
};

export const buildSignupUrl = (locale?: Locale): string => {
  return prefixUrlWithLocale("/#signup", locale);
};

export const buildUserUrl = (locale?: Locale): string => {
  const url = "/user";
  return prefixUrlWithLocale(url, locale);
};

export const buildUserInfoUrl = (locale?: Locale): string => {
  const url = "/user/info";
  return prefixUrlWithLocale(url, locale);
};

export const buildUserPasswordUrl = (locale?: Locale): string => {
  const url = "/user/password";
  return prefixUrlWithLocale(url, locale);
};

export const buildUserDataUrl = (locale?: Locale): string => {
  const url = "/user/data";
  return prefixUrlWithLocale(url, locale);
};

export const buildUserDeleteAccountUrl = (locale?: Locale): string => {
  const url = "/user/delete-account";
  return prefixUrlWithLocale(url, locale);
};

export const buildContentUrl = (slug: string, locale?: Locale): string => {
  const url = `/articles/${slug}`;
  return prefixUrlWithLocale(url, locale);
};

/**
 * Build absolute Imgix url. This is useful when the next/legacy/image can't be used.
 * @param image Image hash.
 * @param search Full search string including "?".
 * @throws Throw if env for imgix is not defined.
 */
export const buildAbsoluteImgixUrl = (
  image: string,
  search: string
): string => {
  if (!process.env.NEXT_PUBLIC_IMGIX_BASE_URL) {
    throw new Error("process.env.NEXT_PUBLIC_IMGIX_BASE_URL not defined");
  }
  return `${process.env.NEXT_PUBLIC_IMGIX_BASE_URL}/${image}${search}`;
};
