import { useEffect, useState } from "react";
import { usePlayer } from "components/Player/hooks/usePlayer";

export const useAudioBuffered = (): TimeRanges | undefined => {
  const { _audio } = usePlayer();
  const [buffered, setBuffered] = useState(() => _audio?.buffered);

  useEffect(() => {
    if (!_audio) {
      setBuffered(undefined);
      return;
    }
    const handler = () => {
      setBuffered(_audio.buffered);
    };

    _audio.addEventListener("progress", handler);

    return () => {
      _audio.removeEventListener("progress", handler);
    };
  }, [_audio]);

  return buffered;
};
