import {
  customFetch,
  IErrorResponse,
  ISuccessResponse,
} from "helpers/customFetch";
import { getIsAbsoluteUrl } from "helpers/getIsAbsoluteUrl";

export async function lstatFetch<T>(
  relativeUrl: string,
  requestInit?: RequestInit
): Promise<ISuccessResponse<T> | IErrorResponse> {
  if (getIsAbsoluteUrl(relativeUrl)) {
    throw new Error(`Url (${relativeUrl}) is not relative`);
  }

  const baseUrl = process.env.NEXT_PUBLIC_LSTAT_API_BASE_URL;
  const absoluteUrl = `${baseUrl}${relativeUrl}`;
  return customFetch<T>(absoluteUrl, requestInit);
}
