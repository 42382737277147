import { Order } from "api/podplay";
import { TEpisode } from "api/podplay/episode";
import { TEpisodeProgress } from "api/podplay/history";
import { TPodcast } from "api/podplay/podcast";
import {
  TPlayerQueue,
  TPlayerQueueItem,
} from "components/Player/context/PlayerContextProvider";

export interface ICreateQueueListItem {
  episode: TEpisode;
  podcast: TPodcast;
}

/**
 * Removes the active and older episodes from the list.
 */
export const removeActiveAndOlder = (
  sortedList: ICreateQueueListItem[],
  listOrder: IConfig["listOrder"],
  activeEpisodeId: number
): ICreateQueueListItem[] => {
  // NOTICE: Argument "listOrder" is needed. We can't determine how the list is
  // sorted based on "published" in episode. Episodes can have the same
  // "published" value. Some extended logic also occurs on the api when podcast
  // is "seasonal".

  // Create list with the oldest episodes first. We want the queue to always go
  // from oldest to newest.
  const ascList =
    listOrder === Order.ASC ? [...sortedList] : [...sortedList].reverse();

  const activeEpisodeIndex = ascList.findIndex(
    ({ episode }) => episode.id === activeEpisodeId
  );

  // Keep episodes newer than active. I.e. episodes after the active episode.
  return activeEpisodeIndex !== -1
    ? ascList.slice(activeEpisodeIndex + 1)
    : ascList;
};

/**
 * Filter function to sort out episodes marked as completed.
 */
export const filterCompleted =
  (
    episodesProgress: Record<number, TEpisodeProgress | undefined> | undefined
  ) =>
  (item: ICreateQueueListItem): boolean => {
    if (!episodesProgress) {
      return true;
    }
    const episodeProgress = episodesProgress[item.episode.id];
    return !episodeProgress?.completed;
  };

export interface IConfig {
  activeEpisodeId: number;
  listOrder: Order;
  isLoggedIn: boolean;
}

/**
 * Filter function to sort out exclusive episodes if not logged in.
 */
export const filterNoAccess =
  (isLoggedIn: boolean) =>
  (item: ICreateQueueListItem): boolean =>
    !(item.episode.exclusive && !isLoggedIn);

/**
 * Creates queue item.
 */
export const createQueueItem = (
  createQueueListItem: ICreateQueueListItem
): TPlayerQueueItem => {
  return {
    episode: createQueueListItem.episode,
    podcast: createQueueListItem.podcast,
    moveCount: 0,
  };
};

/**
 * Create queue from list of episodes. Filters out and creates queue based on
 * active episode id.
 */
export const createQueue = (
  sortedList: ICreateQueueListItem[],
  config: IConfig
): TPlayerQueue => {
  return removeActiveAndOlder(
    sortedList,
    config.listOrder,
    config.activeEpisodeId
  )
    .filter(filterNoAccess(config.isLoggedIn))
    .map(createQueueItem);
};
