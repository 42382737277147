import { isPropertyValue } from "helpers/typeGuards";
import { Country, Language, Locale } from "interfaces/interfaces";

export const parseLocale = (
  locale: string | undefined
): { locale: Locale; language: Language; country?: Country } => {
  // Locale.
  if (locale === undefined) {
    throw new Error("Undefined locale");
  }

  // TODO: Remove "extraLocales" when we can remove locales in
  //  "next.config.ts". They are there to solve Next redirect bug.
  //  Also remember to remove "TODO" in return.
  //  https://github.com/vercel/next.js/issues/18676#issuecomment-753295967
  const extraLocales = ["sv", "fi", "no"];
  if (
    !isPropertyValue(Locale, locale) &&
    !extraLocales.includes(locale as Locale)
  ) {
    throw new Error(`Unsupported locale ("${locale}")`);
  }

  // Language and country.
  const [language, country]: (string | undefined)[] = locale.split("-");

  if (!isPropertyValue(Language, language)) {
    throw new Error(`Unsupported language ("${language}")`);
  }

  // Country doesn't need to be defined - Just check if present.
  if (typeof country === "string" && !isPropertyValue(Country, country)) {
    throw new Error(`Unsupported country ("${country}")`);
  }

  // TODO: `as` can be removed when "extraLocales" gets removed. Se "TODO" above.
  return { locale, language, country } as {
    locale: Locale;
    language: Language;
    country: Country;
  };
};
