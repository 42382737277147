import { IErrorResponse, ISuccessResponse } from "helpers/customFetch";
import { BlockType, ICollection, IHeader } from "interfaces/interfaces";

export const isNotUndefined = <T>(value: T | undefined): value is T => {
  return value !== undefined;
};

export const isNotNull = <T>(value: T | null): value is T => {
  return value !== null;
};

export const isTruthy = <T>(value: T | null | undefined): value is T => {
  return !!value;
};

export const isPropertyValue = <T extends Record<string, unknown>>(
  object: T,
  possibleValue: unknown
): possibleValue is T[keyof T] => {
  return Object.values(object).includes(possibleValue);
};

export const isCollection = (
  node: ICollection | IHeader
): node is ICollection => {
  return (node as ICollection)._meta.type === BlockType.COLLECTION_PAGE;
};

export const isSuccessResponse = <T>(
  response: ISuccessResponse<T> | IErrorResponse<unknown>
): response is ISuccessResponse<T> => {
  return response.ok;
};

export const isString = (value): value is string => {
  return typeof value === "string";
};

export const isNumber = (value): value is number => {
  return typeof value === "number";
};
